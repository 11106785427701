import { CircularProgress } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import useStyles from "../Portal/styles"

const ConfirmationApplication = ({ openPopup, closeOpportunity, hasErrors }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleClose = () => {
    setOpen(false)
    closeOpportunity(false)
  }

  const handleCloseOpportunity = () => {
    setIsSubmitting(true)
    closeOpportunity(true)
  }

  useEffect(() => {
    if (openPopup !== undefined) {
      setOpen(openPopup)
    }
  }, [openPopup])

  useEffect(() => {
    if (hasErrors) {
      setIsSubmitting(false)
    }
  }, [hasErrors])

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t('popinApplication.title')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('popinApplication.text')} <strong>{t('popinApplication.button_confirm')}</strong>, {t('popinApplication.text2')}.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className={classes.button} onClick={handleClose}>{t('popinApplication.button_cancel')}</Button>
          <div className={classes.buttons}>
            <div className={classes.wrapper}>
              <Button className={classes.button} disabled={isSubmitting} onClick={handleCloseOpportunity} variant="contained">
                {t('popinApplication.button_confirm')}
              </Button>
              {isSubmitting && (
                <CircularProgress className={classes.buttonProgress} size={24} />
              )}
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ConfirmationApplication
