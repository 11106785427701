import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  complementAdresse1: '',
  complementAdresse2: '',
  inactive: false,
  iType: '',
  lieuDit: '',
  numeroVoie: '',
  postalCode: '',
  sCp: '',
  sHcNum: '',
  sInsee: '',
  sLieuDit: '',
  sLoc: '',
  sMat: '',
  sNum: '',
  voie: ''
}

export const capAdresseSlice = createSlice({
  name: 'capAdresse',
  initialState,
  reducers: {
    update: (state, action) => {
      const { complementAdresse1, complementAdresse2, iType, lieuDit, numeroVoie, postalCode, sCp, sHcNum, sInsee, sLieuDit, sLoc, sMat, sNum, voie } = action.payload
      Object.assign(state, { complementAdresse1, complementAdresse2, iType, lieuDit, numeroVoie, postalCode, sCp, sHcNum, sInsee, sLieuDit, sLoc, sMat, sNum,  voie })
    },
    reset: (state, action) => {
      return initialState
    }
  }
})

export const { update, reset } = capAdresseSlice.actions

export default capAdresseSlice.reducer
