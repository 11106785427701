import { Button } from "@mui/material"
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import { useTranslation } from "react-i18next"

const ButtonBack = ({ activeStep, handleBack, isSubmitting, isSubmittingBack }) => {
  return (
  <Button size="small" onClick={handleBack} disabled={(activeStep === 0) || isSubmitting || isSubmittingBack}>
    <KeyboardArrowLeft />
    Précédent
  </Button>
  )
}

const ButtonNext = ({ activeStep, isLastStep, isSubmitting, maxSteps }) => {

  const { t } = useTranslation()

  return (
  <Button
    disabled={isSubmitting}
    size="small"
    type="submit"
  >
    {isLastStep ? t('validate'): t('next')}
    <KeyboardArrowRight />
  </Button>
  )
}

export {
  ButtonBack,
  ButtonNext
}
