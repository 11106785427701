import { Alert, Button, Typography } from "@mui/material";
import { useHistory } from "react-router";

const NotFound = () => {
  const history = useHistory();

  const goToForm = () => {
    history.push("/");
  };

  return (
    <>
      <Alert elevation={3} severity="error" sx={{ mb: 2, textAlign: "center" }}>
        404 - Page introuvable
      </Alert>

      <Typography paragraph variant="body2" sx={{ textAlign: "center" }}>
        Cette page n'existe pas
      </Typography>

      <Button onClick={goToForm} sx={{ mt: 2 }} variant="contained">
        Revenir à l'accueil
      </Button>
    </>
  );
};

export default NotFound;
