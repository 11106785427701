import i18next from "i18next"

const currentYear = new Date().getFullYear()

const portalFormModel = {
  formId: 'portalForm',
  formField: {
    account: {
      AcademicLevel__pc: {
        name: 'account.AcademicLevel__pc',
        requiredErrorMsg: i18next.t('fields.required', { field: i18next.t('account.AcademicLevel__pc') })
      },
      BACYear__pc: {
        name: 'account.BACYear__pc',
        requiredErrorMsg: i18next.t('fields.required', { field: i18next.t('account.BACYear__pc') }),
        minMaxErrorMsg: i18next.t('fields.minMax', { field: i18next.t('account.BACYear__pc'), min: '1970', max: currentYear })
        // minMaxErrorMsg: 'L\'année du BAC doit être comprise entre 1970 et ' + currentYear
      },
      CGV__c: {
        name: 'account.CGV__c'
      },
      Country_of_birth__pc: {
        name: 'account.Country_of_birth__pc',
        // requiredErrorMsg: 'Le pays de naissance est requis'
        requiredErrorMsg: i18next.t('fields.required', { field: i18next.t('account.Country_of_birth__pc') })
      },
      Description: {
        name: 'account.Description'
      },
      FirstName: {
        name: 'account.FirstName',
        // requiredErrorMsg: 'Le prénom est requis',
        // maxLengthErrorMsg: 'Le prénom doit contenir au maximum ${max} caractères' // eslint-disable-line no-template-curly-in-string
        requiredErrorMsg: i18next.t('fields.required', { field: i18next.t('account.FirstName') }),
        maxLengthErrorMsg: i18next.t('fields.characters', { field: i18next.t('account.FirstName'), max: '${max}' }) // eslint-disable-line no-template-curly-in-string
      },
      Grant_Student__c: {
        name: 'account.Grant_Student__c'
      },
      Langue_1__c: {
        name: 'account.Langue_1__c',
        requiredErrorMsg: i18next.t('fields.required', { field: i18next.t('account.Langue_1__c') }),
        notSameValue: i18next.t('fields.notSameValue')
      },
      Langue_2__c: {
        name: 'account.Langue_2__c',
        requiredErrorMsg: i18next.t('fields.required', { field: i18next.t('account.Langue_2__c') }),
        notSameValue: i18next.t('fields.notSameValue')
      },
      Langue_3__c: {
        name: 'account.Langue_3__c',
        requiredErrorMsg: i18next.t('fields.required', { field: i18next.t('account.Langue_3__c') }),
        notSameValue: i18next.t('fields.notSameValue')
      },
      LastName: {
        name: 'account.LastName',
        // requiredErrorMsg: 'Le nom est requis',
        // maxLengthErrorMsg: 'Le nom doit contenir au maximum ${max} caractères' // eslint-disable-line no-template-curly-in-string
        requiredErrorMsg: i18next.t('fields.required', { field: i18next.t('account.LastName') }),
        maxLengthErrorMsg: i18next.t('fields.characters', { field: i18next.t('account.LastName'), max: '${max}' }) // eslint-disable-line no-template-curly-in-string
      },
      Nationalite__c: {
        name: 'account.Nationalite__c',
        requiredErrorMsg: i18next.t('fields.required', { field: i18next.t('account.Nationalite__c') })
      },
      Nationalite_2__c: {
        name: 'account.Nationalite_2__c'
      },
      Native_Language__pc: {
        name: 'account.Native_Language__pc',
        requiredErrorMsg: i18next.t('fields.required', { field: i18next.t('account.Native_Language__pc') })
      },
      Niveau_langue_1__c: {
        name: 'account.Niveau_langue_1__c',
        requiredErrorMsg: i18next.t('fields.required', { field: i18next.t('account.Niveau_langue_1__c') })
      },
      Niveau_langue_2__c: {
        name: 'account.Niveau_langue_2__c',
        requiredErrorMsg: i18next.t('fields.required', { field: i18next.t('account.Niveau_langue_2__c') })
      },
      Niveau_Langue_3__c: {
        name: 'account.Niveau_Langue_3__c',
        requiredErrorMsg: i18next.t('fields.required', { field: i18next.t('account.Niveau_Langue_3__c') })
      },
      PersonBirthdate: {
        name: 'account.PersonBirthdate',
        minimumYear: i18next.t('fields.minmum'),
        requiredErrorMsg: i18next.t('fields.required', { field: i18next.t('account.PersonBirthdate') })
        // minimumYear: 'L\'année de naissance doit être au minimum de 1900', // eslint-disable-line no-template-curly-in-string
        // requiredErrorMsg: 'La date de naissance est requise'
      },
      PersonMailingCity: {
        name: 'account.PersonMailingCity',
        requiredErrorMsg: i18next.t('fields.required', { field: i18next.t('account.PersonMailingCity') }),
        maxLengthErrorMsg: i18next.t('fields.characters', { field: i18next.t('account.PersonMailingCity'), max: '${max}' }) // eslint-disable-line no-template-curly-in-string
      },
      PersonMailingCountry: {
        name: 'account.PersonMailingCountry',
        requiredErrorMsg: i18next.t('fields.required', { field: i18next.t('account.PersonMailingCountry') })
      },
      PersonMailingPostalCode: {
        name: 'account.PersonMailingPostalCode',
        requiredErrorMsg: i18next.t('fields.required', { field: i18next.t('account.PersonMailingPostalCode') }),
        maxLengthErrorMsg: i18next.t('fields.characters', { field: i18next.t('account.PersonMailingPostalCode'), max: '${max}' }) // eslint-disable-line no-template-curly-in-string
      },
      PersonMailingStreet: {
        name: 'account.PersonMailingStreet',
        requiredErrorMsg: i18next.t('fields.required', { field: i18next.t('account.PersonMailingStreet') }),
        maxLengthErrorMsg: i18next.t('fields.characters', { field: i18next.t('account.PersonMailingStreet'), max: '${max}' }) // eslint-disable-line no-template-curly-in-string
      },
      PersonMobilePhone: {
        name: 'account.PersonMobilePhone',
        requiredErrorMsg: i18next.t('fields.mobileOrPhone'),
        // formatInvalid: 'Le format du mobile est incorrect',
        maxLengthErrorMsg: i18next.t('fields.characters', { field: i18next.t('account.PersonMobilePhone'), max: '${max}' }) // eslint-disable-line no-template-curly-in-string
      },
      Phone: {
        name: 'account.Phone',
        requiredErrorMsg: i18next.t('fields.mobileOrPhone'),
        // formatInvalid: 'Le format du téléphone fixe est incorrect',
        maxLengthErrorMsg: i18next.t('fields.characters', { field: i18next.t('account.Phone'), max: '${max}' }) // eslint-disable-line no-template-curly-in-string
      },
      Salutation: {
        name: 'account.Salutation'
      },
      Section__pc: {
        name: 'account.Section__pc',
        requiredErrorMsg: i18next.t('fields.required', { field: i18next.t('account.Section__pc') })
      },
      SectionOfBAC__pc: {
        name: 'account.SectionOfBAC__pc',
        requiredErrorMsg: i18next.t('fields.required', { field: i18next.t('account.SectionOfBAC__pc') })
      },
      Reports_Cards__c: {
        label: 'Bulletins de notes',
        name: 'account.Reports_Cards__c',
        requiredErrorMsg: 'Une pièce jointe des bulletins de note est obligatoire'
      },
      Copy_of_Diploma__c: {
        label: 'Copie du diplôme',
        name: 'account.Copy_of_Diploma__c',
        requiredErrorMsg: 'Une pièce jointe des diplômes obtenus est obligatoire'
      },
      ID_Proof__c: {
        label: "Justificatif d'identité",
        name: 'account.ID_Proof__c',
        requiredErrorMsg: "Une pièce jointe des d'un justificatif d'identité est obligatoire"
      },
      Grant_Proof__c: {
        label: "Avis d'attribution de bourse",
        name: 'account.Grant_Proof__c',
        requiredErrorMsg: "L'avis d'attribution de bourse est obligatoire"
      }
    },
    opportunity: {
      First_information_source__c: {
        name: 'opportunity.First_information_source__c'
      },
      Session__c: {
        name: 'opportunity.Session__c',
        requiredErrorMsg: i18next.t('fields.session')
      },
      Track__c: {
        name: 'opportunity.Track__c'
      },
      TrackMajor__c: {
        name: 'opportunity.TrackMajor__c'
      },
      TrackMinor__c: {
        name: 'opportunity.TrackMinor__c'
      }
    },
    SpecialisedEducation: {
      name: 'SpecialisedEducation',
      minErrorMsg: 'Vous devez choisir au minimun 2 spécialités',
      maxErrorMsg: 'Vous devez choisir au maximum 2 spécialités'
    }
  }
}

export default portalFormModel
