// COUCOU


import errorCode from "./ErrorCode";
import { STAGE_PROSPECT } from "./StageNameAccount";
import axios from "axios";
import { Chip } from "@mui/material"
import DoneIcon from '@mui/icons-material/Done'
import ErrorIcon from '@mui/icons-material/Error';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import { STAGE_INSCRIPTION, STAGE_INSCRIT, isApplicant, isRegistered } from "./StageNameOpportunity"
import { ca } from "date-fns/locale";
import { responsiveProperty } from "@mui/material/styles/cssUtils";

const axiosSfConnect = axios.create({
  baseURL: process.env.REACT_APP_SF_CONNECT_API,
  headers: {
    "Content-Type": "application/json",
  },
});

class Salesforce {
  /**
   * @param {*} files list of files to update in Salesforce
   * @param {*} accountId Salesforce Account Id
   * @param {*} opportunityId Salesforce Opportunity Id
   * @returns
   */
  static async addAttachmentToSobject(files, accountId, opportunityId) {
    return new Promise(async (resolve, reject) => {
      let formData = new FormData();
      formData.append("accountId", accountId);
      formData.append("opportunityId", opportunityId);
      for (let i = 0; i < files.length; i++) {
        formData.append(files[i].fileValue, files[i].name);
        formData.append(files[i].fileValue, files[i]);
      }
      try {
        const query = await axiosSfConnect({
          method: "post",
          url: "/api/addAttachment",
          data: formData,
        });
        if (query.data.success) {
          resolve(query.data);
        } else {
          resolve(query.data);
        }
      } catch (error) {
        reject(errorCode.ERROR_SERVER);
      }
    });
  }

  /**
   *
   * @param {*} campaignMemberId campaignMember id in Salesforce link to Account
   * @returns {Boolean} removed campaignMember succcessed or failed
   */
  static async cancelJPC(campaignMemberId) {
    return new Promise(async (resolve, reject) => {
      const data = {
        campaignMemberId: campaignMemberId,
      };
      console.log("campaignMemberId", campaignMemberId);
      try {
        const query = await axiosSfConnect({
          method: "delete",
          url: "/api/cancelJPC",
          data: data,
        });
        if (query.data.success) {
          resolve(query.data.success);
        } else {
          resolve(query.data.success);
        }
      } catch (error) {
        reject(errorCode.ERROR_SERVER);
      }
    });
  }

  /**
   * @param {String} batchId created batch id on Salesforce
   * @param {String} jobId created job id on Salesforce
   * @returns {Object} batched finished success : true, false
   */
  static async checkAttachment(batchId, jobId) {
    return new Promise(async (resolve, reject) => {
      const data = {
        batchId: batchId,
        jobId: jobId,
      };
      try {
        const query = await axiosSfConnect({
          method: "get",
          url: "/api/checkAttachment",
          params: data,
        });
        if (query.data.success) {
          resolve(query.data);
        } else {
          resolve(query.data);
        }
      } catch (error) {
        reject(errorCode.ERROR_SERVER);
      }
    });
  }

  /**
   * Verify if the IonisConnect exist
   * @param {string} email student email
   * @param {string} school school name
   * @returns {Promise} Promise who return if the IonisConnect exist and the IonisConnect object
   */
  static async checkUser(email, school) {
    return new Promise(async (resolve, reject) => {
      try {
        const query = await axiosSfConnect({
          method: "get",
          url: "/ionis-connect/checkUser",
          params: { email: email.replace('+', '%2B'), school: school },
        });
        if (query.data.exist) {
          resolve({
            exist: true,
            ionisConnect: query.data.ionisConnect,
          });
        } else {
          resolve({
            exist: false,
          });
        }
      } catch (error) {
        reject(errorCode.ERROR_SERVER);
      }
    });
  }

  /**
   * create campaign Member related to Salesforce Account via contact Id
   * @param {string} contactId Salesforce contact Id
   * @param {string} campaignId Salesforce campaign Id
   * @returns {Object} Salesforce CampaignMember
   */
  static async createCampaignMember(contactId, campaignId) {
    return new Promise(async (resolve, reject) => {
      const data = {
        campaignId: campaignId,
        contactId: contactId,
      };
      console.log("createCampaignMember :", data);
      try {
        const query = await axiosSfConnect({
          method: "post",
          url: "/api/createCampaignMember",
          data: data,
        });
        console.log("created query : ", query);
        if (query.data.success) {
          resolve(query.data.campaignMember.data);
        }
      } catch (error) {
        reject(errorCode.ERROR_SERVER);
      }
    });
  }

  /**
   * create specialities on opportunity for isg msc program
   * @param {object} data object
   * @returns {Object} Salesforce Speciality_Selected object
   */
  static async createSpecialities(data) {
    return new Promise(async (resolve, reject) => {
      console.log("createSpecialities :", data);
      try {
        const query = await axiosSfConnect({
          method: "post",
          url: "/api/createSpecialities",
          data: data,
        });
        console.log("created query : ", query);
        if (query.data.success) {
          resolve(query.data.data);
        }
      } catch (error) {
        reject(errorCode.ERROR_SERVER);
      }
    });
  }

  /**
   * @param {string} opportunityId opportunity related the task
   * @param {string} sujet subject for the Salesforce task
   * @param {string} message message for the Salesforce task
   * @returns {boolean} created task and success
   */
  static async createTask(opportunityId, sujet, message) {
    return new Promise(async (resolve, reject) => {
      const data = {
        message: message,
        opportunityId: opportunityId,
        sujet: sujet
      };
      try {
        const query = await axiosSfConnect({
          method: "post",
          url: "/api/createTask",
          data: data,
        });
        if (query.data.success) {
          resolve(query.data.success);
        }
      } catch (error) {
        reject(errorCode.ERROR_SERVER);
      }
    });
  }

  /**
   * Create the IonisConnect and the Account
   * @param {object} user user information
   * @param {string} recordTypeIdAccount Salesforce record type id on Account
   * @returns Account Id
   * @todo modifier la valeur de retour en dynamique
   */
  static async createUser(user, recordTypeIdAccount) {
    const body = {
      division: process.env.REACT_APP_DIVISION,
      link: document.location.href,
      recordTypeId: recordTypeIdAccount,
      school: process.env.REACT_APP_SCHOOL,
      status: STAGE_PROSPECT,
      user: user,
    };
    return new Promise(async (resolve, reject) => {
      try {
        const query = await axiosSfConnect({
          method: "post",
          url: "/ionis-connect/createUser",
          data: body,
        });
        // resolve(query.account)
        if (query.account !== undefined) {
          resolve(true);
        } else {
          // @todo on modifie eventuellement le compte ????
          resolve(true);
        }
      } catch (error) {
        reject(errorCode.ERROR_SERVER);
      }
    });
  }

  /**
   * @param {*} files array of files to delete on Salesforce
   * @param {*} account Salesforce Account
   * @param {*} opportunity Salesforce Opportunity
   * @returns {Object} deletion successed
   */
  static async deleteAttachmentToSobject(files, account, opportunity) {
    return new Promise(async (resolve, reject) => {
      const data = {
        accountId: account.Id,
        files: files,
        opportunityId: opportunity.Id,
      };
      try {
        const query = await axiosSfConnect({
          method: "post",
          url: "/api/deleteAttachments",
          data: data,
        });
        resolve(query.data);
      } catch (error) {
        reject(errorCode.ERROR_SERVER);
      }
    });
  }

  /**
   * Fonction (espèrons le temporaire) qui fixe le StageStop pour les anciennes candidatures
   * @param {*} account Salesforce Account
   * @returns {string} Account new stage stop
   */
  static async fixStageStop(account) {
    return new Promise(async (resolve, reject) => {
      const data = {
        accountId: account.Id,
        stageStop: account.StageStop__c,
      };
      try {
        const query = await axiosSfConnect({
          method: "post",
          url: "/ionis-connect/fixStageStop",
          data: data,
        });
        resolve(query.data);
      } catch (error) {
        reject(errorCode.ERROR_SERVER);
      }
    });
  }

  /**
   * @param {*} accountId Salesforce account Id
   * @returns {object} Salesforce AcademicInformation__c from one account
   */
  static async getAcademicInformation(accountId) {
    return new Promise(async (resolve, reject) => {
      try {
        const query = await axiosSfConnect({
          method: "get",
          url: "/ionis-connect/getAcademicInformation",
          params: { accountId: accountId },
        });
        resolve(query.data.data);
      } catch (error) {
        reject(errorCode.ERROR_SERVER);
      }
    });
  }

  /**
   * @param {*} ionisConnectId
   * @param {*} email
   * @param {*} division
   * @returns {object} Salesforce Account
   */
  static async getAccount(ionisConnectId, email, division) {
    return new Promise(async (resolve, reject) => {
      try {
        const query = await axiosSfConnect({
          method: "get",
          url: "/ionis-connect/getAccount",
          params: {
            email: email.replace('+', '%2B'),
            ionisConnectId: ionisConnectId,
            school: division,
          },
        });
        if (query.data.success) {
          console.log("query.data.account.Id", query.data.account.Id);
          // await this.updateLastConnection(query.data.account.Id);
          resolve(query.data);
        } else {
          resolve(query.data.error);
        }
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   * Get the recordId of Salesforce object
   * @param {string} name salesforce label name
   * @returns {string} Application price
   */
  static async getApplicationPrice(name) {
    return new Promise(async (resolve, reject) => {
      try {
        const query = await axiosSfConnect({
          method: "get",
          url: "/api/getApplicationPrice",
          params: { name: name },
        });
        if (query.data !== "") {
          resolve(query.data);
        }
        reject("no price");
      } catch (error) {
        reject(errorCode.ERROR_SERVER);
      }
    });
  }

  /**
   * @param {*} account Salesforce Account
   * @param {*} opportunity Salesforce Opportunity
   * @returns files object for Fielddarray,
   * exemple : [{ supportingDocument: "Pièce d'identité", files: [ [Object] ] }]
   */
  static async getAttachmentFromSobject(account, opportunity) {
    return new Promise(async (resolve, reject) => {
      let data;
      if (opportunity !== undefined) {
        data = {
          accountId: account.Id,
          opportunityId: opportunity.Id,
        };
      } else {
        data = {
          accountId: account.Id,
          opportunityId: undefined,
        };
      }
      try {
        const query = await axiosSfConnect({
          method: "get",
          url: "/ionis-connect/getAttachments",
          params: data,
        });
        if (query.data.success) {
          resolve(query.data);
        } else {
          resolve(query.data);
        }
      } catch (error) {
        reject(errorCode.ERROR_SERVER);
      }
    });
  }

  /**
   * Get the recordId of Salesforce object
   * @param {string} campaignName salesforce campaign name
   * @param {string} school salesforce campaign school__c
   * @param {string} type salesforce campaign detail__c
   * @returns {string} Salesforce campaign Id
   */
  static async getCampaign(campaignName, school, type) {
    return new Promise(async (resolve, reject) => {
      try {
        const query = await axiosSfConnect({
          method: "get",
          url: "/api/getCampaignId",
          params: { name: campaignName, school: school, type: type },
        });
        if (query.data !== "") {
          resolve(query.data.data.Id);
        }
      } catch (error) {
        reject(errorCode.ERROR_SERVER);
      }
    });
  }

  /**
   * Get the recordId of Salesforce object
   * @param {string} division salesforce campaign division__c
   * @param {string} school salesforce campaign school__c
   * @param {string} type salesforce campaign detail__c
   * @returns {string} Salesforce campaign mother and childs
   */
   static async getCampaigns(division, school, type) {
    return new Promise(async (resolve, reject) => {
      try {
        const query = await axiosSfConnect({
          method: "get",
          url: "/api/getCampaigns",
          params: { division: division, school: school, type: type },
        });
        if (query.data !== "") {
          resolve(query.data.data);
        }
      } catch (error) {
        reject(errorCode.ERROR_SERVER);
      }
    });
  }

  /**
   *
   * @param {*} accountId account Id on Salesforce
   * @param {*} campaignId campaign Id on Salesforce
   * @returns {Object} Salesforce campaignMembers
   */
  static async getCampaignMember(accountId, campaigns) {
    return new Promise(async (resolve, reject) => {
      try {
        const query = await axiosSfConnect({
          method: "get",
          url: "/ionis-connect/getCampaignMember",
          params: { campaigns: campaigns, contactId: accountId },
        });
        if (query.data.data.length > 0) {
          resolve(query.data.data[0]);
        } else {
          resolve({});
        }
      } catch (error) {
        reject(errorCode.ERROR_SERVER);
      }
    });
  }

  static getChip(value) {
    let color = 'default'
    switch(value) {
      case 'Qualification':
        color = 'primary'
        break
      case 'En cours':
        color = 'info'
        break
      case 'Candidature Complète':
      case 'Admis':
        color = 'success'
        break
      case 'En étude':
        color = 'warning'
        break
      case 'En cours d\'inscription':
        color = 'warning'
        break
      default:
        color = 'default'
    }

    return <Chip color={color} label={value} variant="filled"></Chip>
  }

  /**
   * Get all CSP
   * @returns {array} all the CSP
   */
     static async getCSP(field, object, recordTypeId) {
      return new Promise(async (resolve, reject) => {
        try {
          const query = await axiosSfConnect({
            method: "get",
            url: "/api/getCSP",
            params: { field, object, recordTypeId }
          });
          resolve(query.data);
        } catch (error) {
          reject(errorCode.ERROR_SERVER);
        }
      });
    }

  /**
   * Get documents
   * @returns {string} division
   */
   static async getDocuments(division) {
    return new Promise(async (resolve, reject) => {
      try {
        const query = await axiosSfConnect({
          method: "get",
          url: "/api/getDocuments",
          params: { division }
        });
        resolve(query.data.data);
      } catch (error) {
        reject(errorCode.ERROR_SERVER);
      }
    });
  }

  /**
   * Get all the lycees (Other Account)
   * @returns {array} all the lycees
   */
  static async getLycees() {
    return new Promise(async (resolve, reject) => {
      try {
        const query = await axiosSfConnect({
          method: "get",
          url: "/api/getLycees",
        });
        resolve(query.data.data);
      } catch (error) {
        reject(errorCode.ERROR_SERVER);
      }
    });
  }

  /**
   * @param {string} accountId salesforce account Id
   * @returns {object} opportunities related to one account Salesforce
   */
  static async getOpportunities(accountId) {
    return new Promise(async (resolve, reject) => {
      try {
        const query = await axiosSfConnect({
          method: "get",
          url: "/ionis-connect/getOpportunities",
          params: { accountId: accountId },
        });
        resolve(query.data.data);
      } catch (error) {
        reject(errorCode.ERROR_SERVER);
      }
    });
  }

  /**
   *
   * @param {object} account compte du candidat
   * @param {object} urls urls des pages retour et traitement pour la banque
   */
  static async getPayboxData(account, session, type, urls) {
    return new Promise(async (resolve, reject) => {
      try {
        const query = await axiosSfConnect({
          method: "post",
          url: "/paybox/getPayboxData",
          data: {
            account,
            school: process.env.REACT_APP_SCHOOL,
            session,
            type,
            urls
          }
        })

        if (query.status === 400) {
          reject(query.error);
        }
        if (query.status === 200) {
          resolve(query.data);
        }
      } catch (ex) {
        reject(ex)
      }
    })
  }

  static async getPaymentInfos(opportunityId, type) {
    return new Promise(async (resolve, reject) => {
      try {
        const query = await axiosSfConnect({
          method: "get",
          url: "/api/getPaymentInfos",
          params: {
            opportunityId,
            type
          }
        })

        if (query.status === 400) {
          reject(query.error);
        }
        if (query.status === 200) {
          resolve(query.data);
        }
      } catch (ex) {
        reject(ex)
      }
    })
  }

  /**
   * Get the recordId of Salesforce object
   * @param {string} field salesforce field
   * @param {object} object salesforce object
   * @param {string} recordTypeId salesforce recordtypeId
   * @returns {string} picklistvalues
   */
  static async getPicklistValues(field, object, recordTypeId) {
    return new Promise(async (resolve, reject) => {
      try {
        const query = await axiosSfConnect({
          method: "get",
          url: "/api/getPicklistValues",
          params: { field: field, object: object, recordTypeId: recordTypeId },
        });
        if (query.status === 400) {
          reject(query.error);
        }
        if (query.status === 200) {
          resolve(query.data);
        }
      } catch (ex) {
        reject(ex);
      }
    });
  }

  /**
   * Get the recordId of Salesforce object
   * @param {string} name name of recordId
   * @param {object} object salesforce object
   * @returns {string} record Id
   */
  static async getRecordTypeId(name, object) {
    return new Promise(async (resolve, reject) => {
      try {
        const query = await axiosSfConnect({
          method: "get",
          url: "/api/getRecordTypeId",
          params: { recordTypeName: name, objectName: object },
        });
        if (query !== undefined) {
          resolve(query.data.Id);
        } else {
          reject(false);
        }
      } catch (e) {
        reject(e);
      }
    });
  }

  /**
   * Get all the data from Salesforce : Countries, AcademicLevels, Sections, Nationalities...
   * @returns {object} All the data with academic levels, countries, nationalities, sections and sessions
   */
  static async getSalesforceData() {
    return new Promise(async (resolve, reject) => {
      const recordTypeAccount = await this.getRecordTypeId(
        process.env.REACT_APP_RECORDTYPE_ACCOUNT,
        "Account"
      ).catch((val) => reject(val));
      const recordTypeOpportunity = await this.getRecordTypeId(
        process.env.REACT_APP_RECORDTYPE_OPPORTUNITY,
        "Opportunity"
      ).catch((val) => reject(val));
      const academicLevels = await this.getPicklistValues(
        "AcademicLevel__pc",
        "Account",
        recordTypeAccount
      ).catch((val) => reject(val));
      const bacHonour = await this.getPicklistValues(
        "BACHonour__pc",
        "Account",
        recordTypeAccount
      ).catch((val) => reject(val));
      const countries = await this.getPicklistValues(
        "Country_of_birth__pc",
        "Account",
        recordTypeAccount
      ).catch((val) => reject(val));
      const csp = await this.getCSP('CSPSise__c', 'Account', recordTypeAccount)
      .catch((val) => reject(val))
      const degreeOfRelationship = await this.getPicklistValues('Parent1DegreeOfRelationship__pc', 'Account', recordTypeAccount)
      .catch((val) => reject(val))
      const documents = await this.getDocuments(process.env.REACT_APP_DIVISION)
      .catch((val) => reject(val))
      const firstInformationSource = await this.getPicklistValues(
        "First_information_source__c",
        "Opportunity",
        recordTypeOpportunity
      ).catch((val) => reject(val));
      const ionisSchools = await this.getPicklistValues(
        "RelativeInIonisSchool__c",
        "Account",
        recordTypeAccount
      ).catch((val) => reject(val));
      const languages = await this.getPicklistValues(
        "Native_Language__pc",
        "Account",
        recordTypeAccount
      ).catch((val) => reject(val));
      const languageLevels = await this.getPicklistValues(
        "Niveau_langue_1__c",
        "Account",
        recordTypeAccount
      ).catch((val) => reject(val));
      const lycees = await this.getLycees()
      .catch((val) => reject(val))
      const nationalities = await this.getPicklistValues(
        "Nationalite__c",
        "Account",
        recordTypeAccount
      ).catch((val) => reject(val));
      const price = await this.getApplicationPrice("ISGPriceJPC").catch((val) =>
        reject(val)
      );
      const sectionOfBac = await this.getPicklistValues(
        "SectionOfBAC__pc",
        "Account",
        recordTypeAccount
      ).catch((val) => reject(val));
      const sections = await this.getSections(
        academicLevels,
        recordTypeAccount
      ).catch((val) => reject(val)); 
      const sessions = await this.getSessions(
        process.env.REACT_APP_SCHOOL
      ).catch((val) => reject(val));
      const campaigns = await this.getCampaigns(
        process.env.REACT_APP_DIVISION,
        process.env.REACT_APP_SCHOOL,
        "JPC"
      ).catch((val) => reject(val))
      const specialities = await this.getSpecialities(
        "ISG"
      ).catch((val) => reject(val))
      resolve({
        academicLevels,
        bacHonour,
        campaigns,
        countries,
        csp,
        degreeOfRelationship,
        documents,
        firstInformationSource,
        ionisSchools,
        languages,
        languageLevels,
        lycees,
        nationalities,
        price,
        recordTypeAccount,
        recordTypeOpportunity,
        sectionOfBac,
        sections,
        sessions,
        specialities
      });
    });
  }


  /**
   * Get all the data associated with user account:
   * - Campaign member
   * - Oppy
   * - Academic information
   * - Work Experience,
   * - Documents
   * 
   * @param {*} account The loaded account object returned by SF API.
   * @param {array} campaigns The loaded campaigns objects returned by SF API.
   * @param {array} opportunities The loaded account object returned by SF API.
   * @returns {*} An object with all account associated data.
   * 
   */
  static async getAccountData(account, campaigns) {
    return new Promise(async (resolve, reject) => {

      if (!account) {
        return reject(errorCode.ERROR_SERVER)
      }

      const opportunities = await this.getOpportunities(account.Id).catch(err => reject(err))
      let response = {}

      const calls = [
        this.getCampaignMember(account.PersonContactId, campaigns.map(campaign => campaign.Id)),
        this.getOpportunities(account.Id),
        this.getAcademicInformation(account.Id),
        this.getWorkExperience(account.Id),
        this.getAttachmentFromSobject(account, opportunities[0])
      ]

      Promise.all(calls)
      .then(async (responses) => {

        if (responses.length !== calls.length) {
          return reject(errorCode.ERROR_SERVER)
        }

        const opportunities = responses[1]
        let connectedAccount = await this.updateLastConnection(account.Id);

        if (opportunities.length > 0) {

          const confirmedApplication = opportunities.find(opp => opp.StageName === STAGE_INSCRIPTION);

          if (confirmedApplication) {
            response.opportunity = confirmedApplication

            const applicationPayment = await this.getPaymentInfos(confirmedApplication.Id, 'inscription');

            if (applicationPayment.success && applicationPayment.data && applicationPayment.data.Payment__c) {
              response.paymentRegistrationStripe.payment = true;
            }

            response.docusignStatus = await this.getStatusDocusign(applicationPayment.Id);
          } else {
            response.opportunity = opportunities[0]
          }

          response.paymentType = ((opportunities[0].ProgramYear__c === "4" &&
              opportunities[0].Division__c === "ISG BS France") ||
              opportunities[0].Division__c === "ISG MSC et MBA")
              ? 'Candidature' : 'JPC'
          
        } else if (account.StageStop__c > '1') {

          connectedAccount = await this.updateStageStop(connectedAccount.data.Id, '0')

        }

        return resolve({
          ...response,
          account: connectedAccount.success ? connectedAccount.data : account,
          campaigns: campaigns,
          campaignMember: responses[0],
          opportunities: opportunities,
          academicInformation: responses[2],
          workExperience: responses[3],
          supportingDocument: responses[4].supportingDocument,
          lastConnection: account.WebLastCnx__c
        })
      })
      .catch(err => {
        reject(err)
      })
    })
  }

  /**
   * Get all sections provided by the Account object
   * @param {*} academicLevels acamdelic levels
   * @param {*} school name of the school
   * @returns {array} "label" "value" sections exemple : ['{"label":"Bac +1","value":"Bac +1"}']
   */
  static async getSections(academicLevels, recordTypeId) {
    return new Promise(async (resolve, reject) => {
      if (academicLevels !== undefined) {
        const academicLevelsLabels = academicLevels.map((x) => x.label);
        try {
          const query = await axiosSfConnect({
            method: "get",
            url: "/api/getSections",
            params: {
              academicLevels: academicLevelsLabels,
              recordTypeId: recordTypeId,
            },
          });
          resolve(query.data.data);
        } catch (error) {
          reject(errorCode.ERROR_SERVER);
        }
      } else {
        reject(errorCode.ERROR_SERVER)
      }
    });
  }

  /**
   * Get all sessions of a school
   * @param {string} school name of the school
   * @returns {object[]} all sessions related to the school
   */
  static async getSessions(school) {
    return new Promise(async (resolve, reject) => {
      try {
        const query = await axiosSfConnect({
          method: "get",
          url: "/api/getSessions",
          params: { school: school },
        });
        resolve(query.data.data);
      } catch (error) {
        reject(errorCode.ERROR_SERVER);
      }
    });
  }

  /**
   * @param {Obejct} account Salesforce account
   * @returns {object[]} get terminal specialities from account
   *
   */
  static async getSpecialisedEducation(account) {
    return new Promise(async (resolve, reject) => {
      try {
        const query = await axiosSfConnect({
          method: "get",
          url: "/api/getSpecialisedEducation",
          params: { account: account },
        });
        if (query.data.length === 0) {
          resolve([]);
        } else {
          resolve(query.data);
        }
      } catch (error) {
        reject(errorCode.ERROR_SERVER);
      }
    });
  }

  /**
   * @param {string} division division of school
   * @returns {object[]} get all specialities from specific division
   *
   */
   static async getSpecialities(school) {
    return new Promise(async (resolve, reject) => {
      try {
        const query = await axiosSfConnect({
          method: "get",
          url: "/api/getSpecialities",
          params: { school: school },
        });
        resolve(query.data.data)
      } catch (error) {
        reject(errorCode.ERROR_SERVER);
      }
    });
  }

  /**
   * @returns {Object[]} all specialised education values for terminal
   */
  static async getSpecialisedEducationValues() {
    return new Promise(async (resolve, reject) => {
      let query;
      try {
        query = await axiosSfConnect({
          method: "get",
          url: "/api/getSpecialisedEducationValues",
        });
        if (query !== undefined) {
          resolve(query.data);
        }
      } catch (error) {
        reject(errorCode.ERROR_SERVER);
      }
    });
  }

  /**
   * Get all status from Docusign
   * @param {string} opportunityId Id of the opportunity
   * @returns {object[]} all status from Docusign
   */
   static async getStatusDocusign(opportunityId) {
    return new Promise(async (resolve, reject) => {
      try {
        const query = await axiosSfConnect({
          method: "get",
          url: "/api/getStatusDocusign",
          params: { opportunityId },
        });
        resolve(query.data);
      } catch (error) {
        reject(errorCode.ERROR_SERVER);
      }
    });
  }

  /**
   * @param {string} accountId Salesforce accountID
   * @returns {Object} Salesforce WorkExperience__c for one account
   */
  static async getWorkExperience(accountId) {
    return new Promise(async (resolve, reject) => {
      try {
        const query = await axiosSfConnect({
          method: "get",
          url: "/ionis-connect/getWorkExperience",
          params: { accountId: accountId },
        });
        resolve(query.data.data);
      } catch (error) {
        reject(errorCode.ERROR_SERVER);
      }
    });
  }

  /**
   * @param {*} opportunityId Salesforce Opportunity Id
   * @returns {object} payment status
   */
  static async goToPayment(opportunityId, type) {
    return new Promise(async (resolve, reject) => {
      try {
        const query = await axiosSfConnect({
          method: "get",
          url: "/api/goToPayment",
          params: { id: opportunityId, type },
        });
        resolve(query.data);
      } catch (error) {
        reject(errorCode.ERROR_SERVER);
      }
    });
  }

  /**
   * @param {String} email
   * @param {String} division
   * @returns {boolean} password reset true or false
   */
  static async resetPassword(email, division) {
    return new Promise(async (resolve, reject) => {
      const data = {
        email: email.replace('+', '%2B'),
        school: division,
      };
      try {
        const query = await axiosSfConnect({
          method: "post",
          url: "/ionis-connect/resetPassword",
          data: data,
        });
        if (!query.data.success) {
          resolve({ success: false });
        } else {
          resolve({ success: true });
        }
      } catch (error) {
        reject(errorCode.ERROR_SERVER);
      }
    });
  }

  /**
   * @param {*} account Salesforce Account
   * @param {*} batchId Created batch Id on Salesforce
   * @param {*} jobId Created job Id on Salesforce
   * @returns {Object} Result batch
   */
  static async resultAttachment(batchId, jobId) {
    return new Promise(async (resolve, reject) => {
      const data = {
        batchId: batchId,
        jobId: jobId,
      };
      try {
        const query = await axiosSfConnect({
          method: "get",
          url: "/api/resultAttachment",
          params: data,
        });
        if (query.data.success) {
          resolve(query.data);
        } else {
          resolve(query.data);
        }
      } catch (error) {
        reject(errorCode.ERROR_SERVER);
      }
    });
  }

  /**
   * @param {Object} academicInformation academic Information to update
   * @param {String} accountId Salesforce account Id
   * @returns {Object} Salesforce updated AcademicInformation__c
   */
  static async updateAcademicInformation(academicInformation, accountId) {
    return new Promise(async (resolve, reject) => {
      if (academicInformation === undefined) {
        academicInformation = [];
      }
      const data = {
        accountId: accountId,
        data: academicInformation,
        object: "AcademicInformation__c",
      };
      try {
        const query = await axiosSfConnect({
          method: "post",
          url: "/api/updateCustomObject",
          data: data,
        });
        if (query.data.success) {
          resolve(query.data);
        } else {
          reject(query.data);
        }
      } catch (error) {
        reject(errorCode.ERROR_SERVER);
      }
    });
  }

  /**
   * @param {Object} account Salesforce account To update
   * @param {int} activeStep actual step in form breadcrumb
   * @param {boolean} JPCWished user wish to do JPC
   * @param {String} paymentType Payment type 'JPC' or 'Candidature'
   * @param {boolean} lastStep last step in form breadcrumb
   * @returns {Object} Salesforce updated Account
   */
  static async updateAccount(
    account,
    activeStep,
    JPCWished = false,
    paymentType = "JPC",
    lastStep = false
  ) {
    return new Promise(async (resolve, reject) => {
      const data = {
        account: account,
        activeStep: activeStep,
        JPCWished: JPCWished,
        lastStep: lastStep,
        paymentType: paymentType,
      };
      try {
        const query = await axiosSfConnect({
          method: "post",
          url: "/api/updateAccount",
          data: data,
        });
        if (query.data.success) {
          resolve(query.data);
        }
      } catch (error) {
        reject(errorCode.ERROR_SERVER);
      }
    });
  }

  static async updateLastConnection(accountId) {
    return new Promise(async (resolve, reject) => {
      let data = {
        accountId: accountId
      };
      try {
        const query = await axiosSfConnect({
          method: "post",
          url: "/ionis-connect/updateLastConnection",
          data: data,
        });
        if (query.success) {
          resolve(query.data);
        } else {
          resolve(query.data);
        }
      } catch (error) {
        reject(errorCode.ERROR_SERVER);
      }
    });
  }

  /**
   * @param {Object} opportunity opportunity to update
   * @param {String} JPCWished user wish to do JPC
   * @param {String} paymentType Payment type 'JPC' or 'Candidature'
   * @returns {Object} Salesforce updated stageName
   */
  static async updateOpportunity(
    opportunity,
    JPCWished = false,
    paymentType = "JPC"
  ) {
    return new Promise(async (resolve, reject) => {
      const data = {
        JPCWished: JPCWished,
        opportunity: opportunity,
        paymentType: paymentType,
      };
      try {
        const query = await axiosSfConnect({
          method: "post",
          url: "/api/updateOpportunity",
          data: data,
        });
        if (query.data.success) {
          resolve(query.data);
        }
      } catch (error) {
        reject(errorCode.ERROR_SERVER);
      }
    });
  }

  /**
   * @param {String} password new password
   * @param {String} email email of the user
   * @param {String} currentPassword current password
   * @param {String} division division of the school
   * @returns {object} change paswword message and success
   */
  static async updatePassword(
    password,
    email,
    currentPassword,
    division,
    token
  ) {
    return new Promise(async (resolve, reject) => {
      const data = {
        currentPassword: currentPassword,
        email: email?.replace('+', '%2B'),
        password: password,
        school: division,
        token: token,
      };
      try {
        const query = await axiosSfConnect({
          method: "post",
          url: "/ionis-connect/updatePassword",
          data: data,
        });
        resolve(query.data);
      } catch (error) {
        reject(errorCode.ERROR_SERVER_UPDATE_PASSWORD);
      }
    });
  }

  /**
   * @param {String} opportunityId Salesforce opportunity Id
   * @param {Object} stageName stageName to update
   * @returns {Object} Salesforce updated stageName
   */
  static async updateStageName(opportunityId, stageName) {
    return new Promise(async (resolve, reject) => {
      try {
        const data = {
          opportunityId: opportunityId,
          stageName: stageName,
        };
        const query = await axiosSfConnect({
          method: "post",
          url: "/api/updateStageName",
          data: data,
        });
        if (query.data.success) {
          resolve(query.data.success);
        }
      } catch (ex) {
        reject(ex);
      }
    });
  }

  /**
   * @param {string} accountId Salesforce Account Id
   * @param {string} stage stage in portal breadcrumb
   * @returns {Object} updated stage in Salesforce Account
   */
  static async updateStageStop(accountId, stage) {
    console.log('Updating stage stop', accountId, stage)
    return new Promise(async (resolve, reject) => {
      const data = {
        accountId: accountId,
        stage: stage,
      };
      try {
        const query = await axiosSfConnect({
          method: "post",
          url: "/api/updateStageStop",
          data: data,
        });
        resolve(query.data.data);
      } catch (error) {
        reject(errorCode.ERROR_SERVER);
      }
    });
  }

  /**
   * @param {Object} workExperience workExperience to update
   * @param {String} accountId Salesforce account Id
   * @returns {Object} Salesforce updated WorkExperience__c
   */
  static async updateWorkExperience(workExperience, accountId) {
    return new Promise(async (resolve, reject) => {
      if (workExperience === undefined) {
        workExperience = [];
      }
      const data = {
        accountId: accountId,
        data: workExperience,
        object: "WorkExperience__c",
      };
      try {
        const query = await axiosSfConnect({
          method: "post",
          url: "/api/updateCustomObject",
          data: data,
        });
        if (query.data.success) {
          resolve(query.data);
        } else {
          reject(query.data);
        }
      } catch (error) {
        reject(errorCode.ERROR_SERVER);
      }
    });
  }

  /**
   * Verify the confirmation token on IonisConnect to verify the email address
   * @param {string} token token provided in GET url params
   * @returns
   */
  static async verifyConfirmationToken(token) {
    return new Promise(async (resolve, reject) => {
      try {
        const query = await axiosSfConnect({
          method: "get",
          url: "/ionis-connect/verifyConfirmationToken",
          params: { division: process.env.REACT_APP_DIVISION, token: token },
        });
        if (query.data.success === true) {
          resolve(query.data.data);
        } else {
          reject(query.error);
        }
      } catch (ex) {
        reject(errorCode.ERROR_1);
      }
    });
  }

  /**
   * Check if the email and password match on his IonisConnect
   * @param {string} email email of the user
   * @param {string} password password of the user
   * @param {string} division division of the school
   * @returns {object} with a boolean if the login and password is a success and the IonisConnect Id
   */
  static async verifyUser(email, password, division) {
    return new Promise(async (resolve, reject) => {
      try {
        const query = await axiosSfConnect({
          method: "get",
          url: "/ionis-connect/verifyUser",
          params: { email: email.replace('+', '%2B'), password: password, school: division },
        });
        if (query.data.passwordMatches !== false) {
          resolve(query.data);
        }
        if (query.data.passwordMatches === false) {
          resolve(errorCode.ERROR_VERIFY_USER);
        }
      } catch (error) {
        reject(errorCode.ERROR_SERVER);
      }
    });
  }

  // static async simulatePayment(payload, opportunities, accountId, campaignMemberId, hasOneOppyCompleted) {
  //   const connection = this.connection(payload)
  //   return new Promise( async (resolve, reject) => {
  //     let oppys = []
  //     for (let i = 0; i < opportunities.length; i++) {
  //       let updateOppy = {
  //         Id: opportunities[i].Id,
  //         AccountId: accountId,
  //         ApplicationPayment__c: true,
  //         Payment_mode__c: 'CB',
  //         PaymentReferences__c: 'ref paiement',
  //         ApplicationPaymentDate__c: new Date(),
  //         payment_status_candidature__c: 'OK'
  //       }
  //       if (opportunities[i].StageName === 'Qualification') {
  //         updateOppy.StageName = 'En cours'
  //       }
  //       oppys.push(updateOppy)
  //     }
  //     // if (hasOneOppyCompleted) {
  //     //   updateOppy.StageName = 'Candidature complète'
  //     // }
  //     const updateOppys = await connection.sobject('Opportunity').upsert(oppys, 'Id')

  //     if (campaignMemberId !== undefined) {
  //       const statusCampaign = {
  //         Id: campaignMemberId,
  //         Status: 'Paid'
  //       }
  //       const updateCampaignMember = await connection.sobject('CampaignMember').upsert(statusCampaign, 'Id')
  //     }

  //     const oppy = await this.updateOpportunity(payload, oppys[0])
  //     // connection.sobject('Opportunity').upsert(updateOppy, 'Id')
  //     resolve({ success: true, opportunity: oppy.opportunity, opportunities: oppy.opportunities })
  //   })
  // }
}

export default Salesforce;
