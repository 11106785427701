import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  root: {
    // backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    width: 'auto',
    minHeight: "100vh", // @todo pb scroll page 6
  },
  root_login: {
    color: theme.palette.text.primary,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: 'auto',
    display: 'flex',
    flexDirection: "column",
    justifyContent: "center",
    alignItems: 'center',
    minHeight: "100vh"
  },
  login: {
    backgroundImage: `url(${"logo-isg.jpg"})`,
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    [theme.breakpoints.down(1810)]: {
      // background: 'none'
      backgroundPosition: 'center top',
      paddingTop: '125px'
    }
  },
  login_box: {
    // alignContent: 'center',
    alignItems: 'center',
    display: 'flex',
    // flexDirection: "column",
    // justify: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    width: '100%'
  },
  login_h2: {
    color: theme.palette.mode === 'light' ? '#004d7c' : '#0068a8',
    fontSize: '30px',
    fontWeight: 'bold',
    textAlign: 'center'
  },
  login_h3: {
    color: theme.palette.mode === 'light' ? '#004d7c' : '#0068a8',
    fontSize: '20px',
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    textAlign: 'center'
  },
  login_h4: {
    color: theme.palette.mode === 'light' ? '#3c415b' : '#535a7e',
    // color: theme.palette.text.secondary,
    fontSize: '15px',
    fontWeight: 'bold',
    marginTop: theme.spacing(1),
    textAlign: 'center'
  },
  login_language: {
    position: 'absolute',
    right: 0,
    top: 0
  },
  paper: {
    // marginTop: theme.spacing(3),
    // marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    width: '100%'
    // display: 'flex',
    // alignItems: 'center'
    // [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
    //   marginTop: theme.spacing(36),
    //   marginBottom: theme.spacing(6),
    //   padding: theme.spacing(3)
    // }
  },
  paper_login: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(1810)]: {
      width: '50%'
    }
    // display: 'flex',
    // alignItems: 'center'
    // [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
    //   marginTop: theme.spacing(36),
    //   marginBottom: theme.spacing(6),
    //   padding: theme.spacing(3)
    // }
  },
  registration_h2: {
    color: theme.palette.mode === 'light' ? '#004d7c' : '#0068a8',
    fontSize: '30px',
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
    textAlign: 'center',
    [theme.breakpoints.down(400)]: {
      paddingTop: theme.spacing(4)
    }
  },
  website: {
    backgroundColor: '#013f64',
    backgroundImage: `url(${"fond-website.jpg"})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center top',
    backgroundSize: 'cover',
    // height: '100vh'
  },
}))

export { useStyles }
