import { Alert, Button, CircularProgress, Grid, Snackbar } from "@mui/material";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import queryString from "query-string";
import Salesforce from "../../../services/Salesforce";
import { InputField } from "../../FormFields";
import useStyles from "../../Portal/styles";
import { updateIonisConnect } from "../../../Store/salesforceSlice";
import formInitialValues from "../FormModel/formInitialValues";
import ionisConnectFormModel from "../FormModel/ionisConnectFormModel";
import validationSchemaModify from "../FormModel/validationSchemaModify";
import validationSchemaModifyConnected from "../FormModel/validationSchemaModifyConnected";
import { useTranslation } from "react-i18next"

const ModifyPassword = ({ token }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isLogged = useSelector((state) => state.salesforce.isLogged);
  const email = useSelector((state) => state.salesforce.account.PersonEmail);
  const division = useSelector((state) => state.salesforce.division);
  const token_validation = queryString.parse(window.location.search).token;
  const validation = isLogged
    ? validationSchemaModifyConnected
    : validationSchemaModify;
  const { formField } = ionisConnectFormModel;
  const { currentPassword, password, passwordConfirm } = formField;
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("error");
  const [error, setError] = useState("");
  const { t } = useTranslation()

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    dispatch(updateIonisConnect(false));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function _handleSubmit(values, actions) {
    const currentPassword = values.currentPassword;
    const password = values.password;
    console.log(
      "modifyPassword",
      password,
      email,
      currentPassword,
      division,
      token_validation
    );
    const modifyPassword = await Salesforce.updatePassword(
      password,
      email,
      currentPassword,
      division,
      token_validation
    ).catch((e) => {
      setError(e);
      actions.isSubmitting(false);
    });
    setMessage(modifyPassword.message);
    setOpen(true);
    if (modifyPassword.success) {
      setSeverity("success");
    } else {
      setSeverity("error");
      setMessage(modifyPassword.error);
    }
  }

  return (
    <>
      {error !== "" && !isLogged && (
        <div>
          <Alert elevation={6} severity="error" sx={{ mb: 2 }} variant="filled">
            {error}
          </Alert>

          <Button
            className={classes.button}
            component={Link}
            to="/connexion"
            variant="contained"
          >
            {t('login')}
          </Button>
          <Button
            className={classes.button}
            component={Link}
            to="/mot-passe-oublie"
            variant="outlined"
          >
            {t('reset_password')}
          </Button>
        </div>
      )}
      {((error === "" && !isLogged) || isLogged) && (
        <Formik
          initialValues={formInitialValues}
          onSubmit={_handleSubmit}
          validationSchema={validation}
        >
          {({ isSubmitting }) => (
            <Form id="modify-password">
              <Grid container spacing={3}>
                {isLogged && (
                  <Grid item xs={12}>
                    <InputField
                      fullWidth
                      label={t(currentPassword.name)}
                      name={currentPassword.name}
                      type="password"
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <InputField
                    fullWidth
                    label={t(password.name)}
                    name={password.name}
                    type="password"
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputField
                    fullWidth
                    label={t(passwordConfirm.name)}
                    name={passwordConfirm.name}
                    type="password"
                  />
                </Grid>
              </Grid>
              <div className={classes.buttons}>
                <div className={classes.wrapper}>
                  {(severity === "success" || isLogged) && (
                    <Button
                      className={classes.button}
                      component={Link}
                      to={isLogged ? "/" : "/connexion"}
                      variant={isLogged ? "text" : "contained"}
                    >
                      {isLogged ? t('back_form') : t('login')}
                    </Button>
                  )}
                  {severity !== "success" && (
                    <Button
                      color="primary"
                      className={classes.button}
                      disabled={isSubmitting}
                      type="submit"
                      variant="contained"
                    >
                      {t('reset_password')}
                    </Button>
                  )}
                  {isSubmitting && (
                    <CircularProgress
                      className={classes.buttonProgress}
                      size={24}
                    />
                  )}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        autoHideDuration={5000}
        onClose={handleClose}
        open={open}
      >
        <Alert
          elevation={6}
          onClose={handleClose}
          severity={severity}
          sx={{ width: "100%" }}
          variant="filled"
        >
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ModifyPassword;
