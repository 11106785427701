import { Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import Diplomas from "../../../applicant-components/components/Diplomas"
import Languages from "../../../applicant-components/components/Languages"
import WorkExperience from "../../../applicant-components/components/WorkExperience"

const Course = (props) => {

  const { t } = useTranslation()

  return (
    <>
      <Typography gutterBottom variant="h5">{t('academic.title')}</Typography>
      <Typography gutterBottom variant="h6">{t('academic.dilomas')}</Typography>
      <Diplomas />
      <Typography gutterBottom variant="h6" sx={{ mt: 2 }}>{t('academic.experience')}</Typography>
      <WorkExperience />
      <Languages formField={props.formField} />
    </>
  )
}

export default Course
