import React from 'react';
import { Field, useField } from 'formik';
import { FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup } from '@mui/material'
import { at } from 'lodash'

const renderOptions = (options, disableValue) => {
  return options.map((option) => (
    <FormControlLabel
      // checked={field.value === option.value}
      disabled={disableValue !== undefined ? disableValue.includes(option.value) : false}
      key={option.key}
      value={option.value}
      control={<Radio />}
      label={option.key}
    />
  ));
};

const RadioButtonExample = (props) => {
  const [field, meta] = useField(props)
  const { label, name, options, disableValue, ...rest } = props;
  const [touched, error] = at(meta, 'touched', 'error')

  return (
    <div>
      {/* <label htmlFor={name}>{label}</label> */}
      <Field name={name} {...rest} >
        {
          ({ field }) => {
            const { value: selectedValue } = field;
            return(
              <FormControl error={error && touched && true} aria-label={field.name}>
                <FormLabel>{label}</FormLabel>
                <RadioGroup {...field} {...rest} name={name} value={selectedValue ? selectedValue : ''}>
                  {options ? renderOptions(options, disableValue) : ''}
                </RadioGroup>
                <FormHelperText>{touched && error ? error : ''}</FormHelperText>
              </FormControl>
            )
          }
        }
      </Field>
    </div>
  );
};

export default RadioButtonExample
