import * as Yup from "yup"
import ionisConnectFormModel from "./ionisConnectFormModel"

const {
  formField: {
    email,
    password,
  }
} = ionisConnectFormModel

const loginValidationSchema =
  Yup.object().shape({
    [email.name]: Yup.string().email(`${email.invalidEmail}`).required(`${email.requiredErrorMsg}`),
    // .test('testing-quote', `${email.invalidQuote}`, (value, context) => {
    //   return value.indexOf(`'`) === -1
    // }),
    [password.name]: Yup.string().required(`${password.requiredErrorMsg}`),
  })

export default loginValidationSchema
