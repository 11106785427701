import { Alert, Link, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { useStyles } from "./styles"
import { Language } from "applicant-components/components/Buttons"
import { useTranslation } from "react-i18next"

const LoginLayout = ({children}) => {

  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.login}>
      {process.env.REACT_APP_ENVIRONMENT === 'development' && (
        <Box className={classes.login_language}>
          <Language />
        </Box>
      )}
      <Typography className={classes.login_h2} variant="h2">{t('ionisConnect.title')}<br />{t('ionisConnect.title1')}.</Typography>
      <Typography className={classes.login_h3} variant="h3">{t('ionisConnect.title2')}</Typography>
      <Typography className={classes.login_h4} variant="h4">{t('ionisConnect.title3')}</Typography>
      <Alert severity="warning" sx={{ mt: 2 }}>A noter : Les formations en 2<sup>nd</sup> cycle (entrée en 4<sup>e</sup> année du Programme Grande École ou en 1<sup>e</sup> année d’un Master of Science ou d’un MBA) <strong>ne sont accessibles qu’aux candidats âgés de moins de 28 ans au jour de la rentrée (au 30 septembre)</strong>. Pour tout complément d’information, vous pouvez prendre <Link href="https://www.isg.fr/contacts-ecole/" target="_blank">contact avec nos équipes</Link>.</Alert>
      <Typography className={classes.login_h3} variant="h3">{t('ionisConnect.isg_application')}</Typography>
      <div style={{ display: 'flex' }}>
        <div className={classes.login_box}>
          <Box sx={{ mx: "auto", width: 330 }}>
            {children}
          </Box>
        </div>
      </div>
    </div>
  )
}

export default LoginLayout
