import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from "@mui/material"
import ExpandMoreIcon  from "@mui/icons-material/ExpandMore"
import { useSelector } from "react-redux"
import moment from "moment"
import portalFormModel from "../Portal/FormModel/portalFormModel"
import { useTranslation } from "react-i18next"

const PersonnalInformations = () => {

  const {
    formField: {
      account: {
        AcademicLevel__pc,
        BACYear__pc,
        Country_of_birth__pc,
        FirstName,
        Grant_Student__c,
        LastName,
        Nationalite__c,
        Nationalite_2__c,
        Native_Language__pc,
        PersonBirthdate,
        PersonMailingCity,
        PersonMailingCountry,
        PersonMailingPostalCode,
        PersonMailingStreet,
        PersonMobilePhone,
        Phone,
        Salutation,
        Section__pc,
        SectionOfBAC__pc,
      }
    }
  } = portalFormModel

  const account = useSelector(state => state.salesforce.account)

  const { t } = useTranslation()

  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          // aria-controls="panel1a-content"
          aria-controls={`panel1a-content`}
          // id="panel1a-header"
          id={`panel1a-header`}
        >
          {t('personalInformations.title')}
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="subtitle2">{t(Salutation.name)}</Typography>
              {account.Salutation}
            </Grid>
            <Grid item sm={6} xs={12}>
              <Typography variant="subtitle2">{t(FirstName.name)}</Typography>
              {account.FirstName}
            </Grid>
            <Grid item sm={6} xs={12}>
              <Typography variant="subtitle2">{t(LastName.name)}</Typography>
              {account.LastName}
            </Grid>
            <Grid item sm={6} xs={12}>
              <Typography variant="subtitle2">{t(PersonBirthdate.name)}</Typography>
              {moment(account.PersonBirthdate).format('DD/MM/YYYY')}
            </Grid>
            <Grid item sm={6} xs={12}>
              <Typography variant="subtitle2">{t(Country_of_birth__pc.name)}</Typography>
              {account.Country_of_birth__pc}
            </Grid>
            <Grid item sm={6} xs={12}>
              <Typography variant="subtitle2">{t(Nationalite__c.name)}</Typography>
              {account.Nationalite__c}
            </Grid>
            <Grid item sm={6} xs={12}>
              <Typography variant="subtitle2">{t(Nationalite_2__c.name)}</Typography>
              {account.Nationalite_2__c}
            </Grid>
            <Grid item sm={6} xs={12}>
              <Typography variant="subtitle2">{t(Phone.name)}</Typography>
              {account.Phone}
            </Grid>
            <Grid item sm={6} xs={12}>
              <Typography variant="subtitle2">{t(PersonMobilePhone.name)}</Typography>
              {account.PersonMobilePhone}
            </Grid>
            <Grid item sm={6} xs={12}>
              <Typography variant="subtitle2">{t(AcademicLevel__pc.name)}</Typography>
              {account.AcademicLevel__pc.split('_')[0]}
            </Grid>
            <Grid item sm={6} xs={12}>
              <Typography variant="subtitle2">{t(Section__pc.name)}</Typography>
              {account.Section__pc}
            </Grid>
            {account.Section__pc === 'Générale' && <Grid item sm={12} xs={12}>
              <Typography variant="subtitle2">{t('specialised_education')}</Typography>
            </Grid>}
            {account.AcademicLevel__pc !== 'Terminale (BAC)_ISG BS France' && <Grid item sm={6} xs={12}>
              <Typography variant="subtitle2">{t(SectionOfBAC__pc.name)}</Typography>
              {account.SectionOfBAC__pc}
            </Grid>}
            {account.AcademicLevel__pc !== 'Terminale (BAC)_ISG BS France' && <Grid item sm={6} xs={12}>
              <Typography variant="subtitle2">{t(BACYear__pc.name)}</Typography>
              {account.BACYear__pc}
            </Grid>}
            <Grid item xs={12}>
              <Typography variant="subtitle2">{t(Native_Language__pc.name)}</Typography>
              {account.Native_Language__pc}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">{t('personalInformations.address')}</Typography>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Typography variant="subtitle2">{t(PersonMailingCountry.name)}</Typography>
              {account.PersonMailingCountry}
            </Grid>
            <Grid item sm={6} xs={12}>
              <Typography variant="subtitle2">{t(PersonMailingStreet.name)}</Typography>
              {account.PersonMailingStreet}
            </Grid>
            <Grid item sm={6} xs={12}>
              <Typography variant="subtitle2">{t(PersonMailingPostalCode.name)}</Typography>
              {account.PersonMailingPostalCode}
            </Grid>
            <Grid item sm={6} xs={12}>
              <Typography variant="subtitle2">{t(PersonMailingCity.name)}</Typography>
              {account.PersonMailingCity}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2">{t(Grant_Student__c.name)}</Typography>
              {account.Grant_Student__c === true ? 'Oui' : 'Non'}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  )
}

export default PersonnalInformations
