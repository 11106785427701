import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  stepper: {
    padding: theme.spacing(3, 0, 5)
  },
  alreayRegistered: {
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 'bold',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3)
  },
  buttons_login: {
    marginTop: theme.spacing(3),
    width: '100%'
  },
  button: {
    // marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  button_login: {
    // marginTop: theme.spacing(3),
    width: '100%'
  },
  registration: {
    position: 'relative'
  },
  registration_h2: {
    color: theme.palette.mode === 'light' ? '#004d7c' : '#0068a8',
    fontSize: '30px',
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
    textAlign: 'center',
    [theme.breakpoints.down(400)]: {
      paddingTop: theme.spacing(4)
    }
  },
  registration_language: {
    position: 'absolute',
    right: 0,
    top: 0
  },
  wrapper: {
    // margin: theme.spacing(1),
    position: 'relative'
  },
  buttonProgress: {
    left: '50%',
    position: 'absolute',
    // top: '50%',
    top: '6px'
    // transform: 'translateY(-50%)'
  },
  buttonForgotPassword: {
    marginTop: theme.spacing(3)
  },
  link: {
    color: theme.palette.text.primary,
    '&:hover': {
      textDecoration: 'none'
    }
  }
}))
