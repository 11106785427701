import { Alert, Box, Grid, Stepper, Step, StepLabel, StepContent, Typography } from "@mui/material"
import { ErrorMessage, useFormikContext } from "formik"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import 'moment/locale/fr'
import RadioButtonExample from "applicant-components/components/FormFields/RadioButtonExample"
import SelectField from "applicant-components/components/FormFields/SelectField"
import { TrackMajor, TrackMinor, TrackProgram } from "components/TrackProgram"
import LogicApplication from "services/LogicApplication"
import LogicYear from "services/LogicYear"
import { CheckboxField } from "applicant-components/components/FormFields"
import { updateCampaignId, updatePaymentType } from "applicant-components/Store/salesforceSlice"
import { useTranslation } from "react-i18next"

const Application = (props) => {
  const {
    formField: {
      opportunity: {
        Session__c,
        TrackMajor__c
      }
    }
  } = props

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const academicLevel = useSelector(state => state.salesforce.account.AcademicLevel__pc)
  const currentLanguage = useSelector(state => state.salesforce.language)
  const nationality = useSelector(state => state.salesforce.account.Nationalite__c)
  const sessions = useSelector(state => state.salesforce.sessions)
  const opportunities = useSelector(state => state.salesforce.opportunities)
  const paymentType = useSelector(state => state.salesforce.paymentType)
  const hasOneOppyCompleted = useSelector(state => state.salesforce.hasOneOppyCompleted)
  const specialities = useSelector(state => state.salesforce.specialities)
  // const [activeStep, setActiveStep] = useState(0)
  const [optionsPrograms, setOptionsPrograms] = useState([])
  const [disableValueProgramme, setDisableValueProgramme] = useState([])
  const [disableValueCampus, setDisableValueCampus] = useState([])
  const [parcoursup, setParcoursup] = useState(false)
  const [track, setTrack] = useState('')
  const [optionsSpecialities, setOptionsSpecialities] = useState([])
  const [hasTrackProgram, setHasTrackProgram] = useState(false)
  const {
    values : {
      application: {
        campusDisabled,
        optionsCampus,
        optionsSessions,
        optionsYears,
        sessionsDisabled,
        trackMajorDisabled,
        yearDisabled,
      },
      opportunity,
      campus,
      program,
      year
    }
  } = useFormikContext()

  const formikProps = useFormikContext()

  useEffect(() => {
    if (opportunity !== null) {
      const { Track__c } = opportunity
      setTrack(Track__c)
    }

  }, [opportunity])

  // Au démarrage
  useEffect(() => {
    const programs = LogicApplication.getPrograms(academicLevel, nationality)
    setOptionsPrograms(programs)
    checkExistingProgram()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const yearFilled = (optionsCampus, program, year) => {
    let hasTrackProgram = false
    setHasTrackProgram(false)
    formikProps.setFieldValue('application.trackMajor', '')
    formikProps.setFieldValue('application.campusDisabled', false)
    if (program === 'ISG MSC et MBA' || (program === 'ISG BS France' && year === '4')) {
      hasTrackProgram = true
      setHasTrackProgram(true)
    }
    // On ne peut pas choisir de JPC si on est année 4 et + ou si le program et MSC et MBA
    if ((year === '4' && program === 'ISG BS France') || program === 'ISG MSC et MBA') {
      dispatch(updatePaymentType('Candidature'))
    } else {
      dispatch(updatePaymentType('JPC'))
    }
    if (optionsCampus.length === 1) {
      formikProps.setFieldValue('campus', optionsCampus[0].value)
      campusFilled(program, year, optionsCampus[0].value, hasTrackProgram)
    }
    // On vérifie si un campus a déjà été sélectionné dans une autre candidature
    checkExistingCampus(program, year)
    // On vérifie si le programme / année est soumis à Parcoursup
    const formattedAcademicLevel = academicLevel.split('_')[0]
    const isParcoursup = LogicApplication.isParcoursup(program, formattedAcademicLevel, year)
    setParcoursup(isParcoursup)
    formikProps.setFieldValue('opportunity.Track__c', '')
    if (hasTrackProgram) {
      formikProps.setFieldValue('application.sessionsDisabled', true)
    }
  }

  const campusFilled = (program, year, campus, hasTrackProgram) => {
    formikProps.setFieldValue('account.Campus__c', campus)
    if (!hasTrackProgram) {
      formikProps.setFieldValue('application.sessionsDisabled', false)
      const _sessions = LogicApplication.getSessions(program, year, campus, sessions)
      formikProps.setFieldValue('application.optionsSessions', _sessions)
      if (_sessions.length === 1) {
        formikProps.setFieldValue('opportunity.Session__c', _sessions[0].value)
        sessionFilled(_sessions[0].value)
      }
    } else {
      formikProps.setFieldValue('opportunity.Track__c', '')
      formikProps.setFieldValue('application.sessionsDisabled', true)
    }
  }

  const sessionFilled = (sessionId) => {
    // console.log('COUCOU', program)
    // if (program === 'ISG MSC et MBA' || (program === 'ISG BS France' && year === '4')) {
    //   formikProps.setFieldValue('application.trackMajorDisabled', false)
    // }
    // formikProps.setFieldValue('application.campaignDisabled', false)
    // const commercialId = LogicApplication.getCommercialId(sessions, sessionId)
    // formikProps.setFieldValue('opportunity.OwnerId', commercialId)
    const filterSpecialities = specialities.filter(el => el.Session__c === sessionId).map(el => ({
      label: el.Speciality__r.Name,
      value: el.Speciality__c
    }))
    setOptionsSpecialities(filterSpecialities)
    if (filterSpecialities.length > 0) {
      formikProps.setFieldValue('application.trackMajorDisabled', false)
    }
    if (filterSpecialities.length === 1) {
      formikProps.setFieldValue('application.trackMajor', filterSpecialities[0].value)
    }
  }

  const checkExistingCampus = (program, year) => {
    const existingOpportunities = opportunities.filter(el => el.Division__c === program && el.ProgramYear__c === year)
    if (existingOpportunities !== undefined) {
      let campus = []
      for (let i = 0; i < existingOpportunities.length; i++) {
        campus.push(existingOpportunities[i].SessionCampus__c)
      }
      setDisableValueCampus(campus)
    }
  }

  const checkExistingProgram = () => {
    // On grise un programme si et seulement si le nombre de candidatures est supérieur au nombre de campus pour ce programme
    disableProgram('ISG BS France')
    disableProgram('ISG BS International')
    disableProgram('ISG PBM')
    disableProgram('ISG MSC et MBA')
  }

  const disableProgram = (division) => {
    const existingOpportunities = opportunities.filter(el => el.Division__c === division)
    const numberOfCampus = LogicYear.find(el => el.division === division).campus.length
    const years = LogicYear.find(el => el.division === division).years
    const academicLevelFilter = academicLevel.split('_')[0]
    const numberOfYears = years.filter(el => el.academicLevel.indexOf(academicLevelFilter) > -1).length
    if (existingOpportunities !== undefined && existingOpportunities.length > 0 && existingOpportunities.length === numberOfCampus * numberOfYears) {
      setDisableValueProgramme(oldArray => [...oldArray, existingOpportunities[0].Division__c])
    }
  }

  return (
    <>
      <Typography variant="h5" sx={{ mb: 2 }}>{t('application.title')}</Typography>
      {(opportunities.length === 0 || hasOneOppyCompleted) && <Grid container spacing={3}>
        <Grid item sm={12}>
          <Stepper orientation="vertical">
            <Step active={true}>
              <StepLabel>
                {t('application.program')} :
              </StepLabel>
              <StepContent>
              {optionsPrograms.length > 0 && <RadioButtonExample
                  disableValue={disableValueProgramme}
                  id="program"
                  name="program"
                  onChange={e => {
                    const { value } = e.target
                    // On reset les champs
                    formikProps.setFieldValue('year', '')
                    formikProps.setFieldValue('campus', '')
                    formikProps.setFieldValue('opportunity.Session__c', '')
                    // formikProps.setFieldValue('opportunity.OwnerId', '')
                    formikProps.setFieldValue('opportunity.Track__c', '')
                    formikProps.setFieldValue('application.trackMajorDisabled', true)
                    formikProps.setFieldValue('application.trackMinorDisabled', true)
                    formikProps.setFieldValue('application.trackMinor', [])
                    formikProps.setFieldValue('application.trackMinorId', [])
                    formikProps.setFieldValue('application.trackMajor', '')
                    formikProps.setFieldValue('application.optionsSessions', [])
                    formikProps.setFieldValue('application.campusDisabled', true)
                    formikProps.setFieldValue('application.sessionsDisabled', true)
                    setOptionsSpecialities([])
                    const formattedAcademicLevel = academicLevel.split('_')[0]
                    const _yearCampus = LogicApplication.getYearCampus(value, formattedAcademicLevel)
                    const optionsCampus = _yearCampus.campus
                    const optionsYears = _yearCampus.years
                    const yearDisabled = optionsYears.length === 0

                    formikProps.setFieldValue('program', value)
                    formikProps.setFieldValue('application.optionsYears', optionsYears)
                    formikProps.setFieldValue('application.yearDisabled', yearDisabled)
                    formikProps.setFieldValue('application.optionsCampus', optionsCampus)

                    if (optionsYears.length === 1) { // Inutile pour le moment car quand je met direct la valeur j'aimerai que ça déclenche une fonction
                      formikProps.setFieldValue('year', optionsYears[0].value)
                      yearFilled(optionsCampus, value, optionsYears[0].value)
                    }
                  }}
                  options={optionsPrograms}
                  row
                />}
              {optionsPrograms.length === 0 && <Alert elevation={6} severity="error">{t('application.program_error')}.</Alert>}
              </StepContent>
            </Step>
            {optionsPrograms.length > 0 && <Step active={true}>
              <StepLabel>
                {t('application.year')} :
              </StepLabel>
              <StepContent>
                <SelectField
                  data={optionsYears}
                  disabled={yearDisabled}
                  id="year"
                  fullWidth
                  label={t('year_entry_required')}
                  name="year"
                  onChange={e => {
                    const { value } = e.target
                    // On reset les champs
                    formikProps.setFieldValue('campus', '')
                    formikProps.setFieldValue('application.optionsSessions', [])
                    formikProps.setFieldValue('opportunity.Session__c', '')
                    // formikProps.setFieldValue('opportunity.OwnerId', '')
                    formikProps.setFieldValue('application.trackMajorDisabled', true)
                    formikProps.setFieldValue('application.trackMinorDisabled', true)
                    formikProps.setFieldValue('application.trackMinor', [])
                    formikProps.setFieldValue('application.trackMinorId', [])
                    formikProps.setFieldValue('application.trackMajor', '')
                    formikProps.setFieldValue('year', value)
                    yearFilled(optionsCampus, formikProps.values.program, value)
                  }}
                />
              </StepContent>
            </Step>}
            {optionsPrograms.length > 0 && <Step active={true}>
              <StepLabel>
                {t('application.campus')} :
              </StepLabel>
              <StepContent>
                <SelectField
                  data={optionsCampus}
                  disabled={campusDisabled}
                  disableValue={disableValueCampus}
                  fullWidth
                  label={t('campus')}
                  name="campus"
                  onChange={e => {
                    const { value } = e.target
                    // On reset les champs
                    formikProps.setFieldValue('application.optionsSessions', [])
                    formikProps.setFieldValue('opportunity.Session__c', '')
                    formikProps.setFieldValue('opportunity.Track__c', '')
                    formikProps.setFieldValue('application.trackMinorDisabled', true)
                    formikProps.setFieldValue('application.trackMinor', [])
                    formikProps.setFieldValue('application.trackMinorId', [])
                    formikProps.setFieldValue('application.trackMajor', '')
                    // formikProps.setFieldValue('opportunity.OwnerId', '')

                    formikProps.setFieldValue('campus', value)
                    campusFilled(formikProps.values.program, formikProps.values.year, value, hasTrackProgram)
                  }}
                />
              </StepContent>
            </Step>}
            {hasTrackProgram && <Step active={true}>
              <StepLabel>
                {t('application.section')} :
              </StepLabel>
              <StepContent>
                <TrackProgram campus={campus} formField={props.formField} program={program} sessions={sessions} setOptionsSpecialities={setOptionsSpecialities} specialities={specialities} year={year} />
              </StepContent>
            </Step>}
            {optionsPrograms.length > 0 && <Step active={true}>
              <StepLabel>
                {t('application.start_date')} :
              </StepLabel>
              <StepContent>
                {(sessionsDisabled || optionsSessions.length > 0) && <SelectField
                  data={optionsSessions}
                  disabled={sessionsDisabled}
                  fullWidth
                  label={t(Session__c.name)}
                  name={Session__c.name}
                  onChange={e => {
                    const { value } = e.target
                    formikProps.setFieldValue('opportunity.Session__c', value)
                    formikProps.setFieldValue('application.trackMinorDisabled', true)
                    formikProps.setFieldValue('application.trackMinor', [])
                    formikProps.setFieldValue('application.trackMinorId', [])
                    formikProps.setFieldValue('application.trackMajor', '')
                    sessionFilled(value)
                  }}
                />}
                {!sessionsDisabled && optionsSessions.length === 0 && <Alert elevation={6} severity="error">{t('application.start_date_error')}.</Alert>}
              </StepContent>
            </Step>}
            {optionsSpecialities.length > 0 && (
                <Step active={true}>
                  <StepLabel>
                    Choisissez une spécialité principale
                  </StepLabel>
                  <StepContent>
                    <SelectField
                      data={optionsSpecialities}
                      disabled={trackMajorDisabled}
                      id="TrackMajor"
                      fullWidth
                      label={t(TrackMajor__c.name)}
                      name="application.trackMajor"
                      onChange={e => {
                        const { value } = e.target
                        formikProps.setFieldValue('application.trackMinorDisabled', false)
                        formikProps.setFieldValue('application.trackMinor', [])
                        formikProps.setFieldValue('application.trackMinorId', [])
                        formikProps.setFieldValue('application.trackMajor', value)
                      }}
                    />
                  </StepContent>
                </Step>
            )}
            {optionsSpecialities.length > 1 && (
              <Step active={true}>
                <StepLabel>
                  {t('application.program_max')} :
                </StepLabel>
                <StepContent>
                  <TrackMinor formField={props.formField} />
                </StepContent>
              </Step>
            )}
          </Stepper>
        </Grid>
      </Grid>}
      <Box mt={2}>
        <ErrorMessage name={Session__c.name} render={msg => <Alert severity="error">{msg}</Alert>} />
      </Box>
      {opportunities.length > 0 && !hasOneOppyCompleted &&
      <Alert elevation={6} severity="info">
        {t('application.already_registered', { opportunityName: opportunities[0].ProgramFullName__c })}.
      </Alert>}
      {parcoursup && currentLanguage === 'fr' && (opportunities.length === 0 || hasOneOppyCompleted) && <Box sx={{ mt: 2}}>
        <Alert severity="info" variant="outlined">
          <p>Les modalités de candidature en 1<sup>ère</sup> année pour le Programme Grande Ecole ou le Bachelor en Management International de l’ISG sont les suivantes :<br /><br />

          <strong>Inscription en 1<sup>ère</sup> année via Parcoursup</strong><br />
          L’admission en 1ère année se fait par le concours ISG, ouvert aux élèves de terminale ou déjà titulaires du baccalauréat en France métropolitaine et DOM-TOM, aux lycéens préparant le baccalauréat français et résidant à l’étranger et aux candidats déjà titulaires du baccalauréat français et résidant à l’étranger.<br  />
          L’inscription au concours de l’ISG se fait sur la plate-forme Parcoursup entre le 17 janvier et le 14 mars 2024 lorsque vous sélectionnez votre vœu Programme Grande Ecole ET/OU votre vœu Bachelor en Management International.<br />
          NB : Dès la formulation de vos vœux ISG, vous pourrez sur la plate-forme demander à recevoir les annales et tutos afin de commencer dès à présent à préparer votre concours.<br />
          Les épreuves d’admission auront lieu du 13 avril au 4 mai 2024.<br />
          Infos concours : 07 78 04 05 98<br /><br />

          <strong>Inscription en 1<sup>ère</sup> année hors Parcoursup</strong><br />
          L’admission en 1ère année hors Parcoursup se fait par le concours ISG, ouvert aux lycéens préparant un baccalauréat international et résidant à l’étranger ainsi qu’aux candidats déjà titulaires d’un baccalauréat international et résidant à l’étranger.<br />
          Pour les inscriptions en 1ère année hors Parcoursup, merci de continuer la saisie du formulaire. Vous pourrez enregistrer votre candidature à tout moment et la compléter plus tard, ajouter des documents en pièce jointe.</p>
        </Alert>
      </Box>}
    </>
  )
}

export default Application
