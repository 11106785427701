const ionisConnectFormModel = {
  formId: 'ionisConnect',
  formField: {
    academicLevel: {
      label : 'Niveau académique*',
      name: 'academicLevel',
      requiredErrorMsg: 'Le niveau académique est requis'
    },
    birthdate: {
      name: 'birthdate',
      requiredErrorMsg: 'La date de naissance est requise'
    },
    cgu: {
      label: 'labelcgu',
      name: 'cgu',
      requiredErrorMsg: 'Vous devez cocher cette case'
    },
    currentPassword: {
      label: 'Mot de passe actuel',
      name: 'currentPassword',
      requiredErrorMsg: 'Le mot de passe actuel est requis'
    },
    email: {
      label: 'E-mail',
      name: 'email',
      requiredErrorMsg: 'L\'adresse e-mail est requise',
      invalidEmail: 'L\'adresse e-mail est invalide',
      maxLengthErrorMsg: 'L\'adresse e-mail doit contenir au maximum ${max} caractères', // eslint-disable-line no-template-curly-in-string
      invalidQuote: `L'adresse email ne doit pas contenir d'apostrophe`
    },
    firstName: {
      label: 'Prénom*',
      name: 'firstName',
      requiredErrorMsg: 'Le prénom est requis',
      maxLengthErrorMsg: 'Le prénom doit contenir au maximum ${max} caractères' // eslint-disable-line no-template-curly-in-string
    },
    lastName: {
      label: 'Nom*',
      name: 'lastName',
      requiredErrorMsg: 'Le nom est requis',
      maxLengthErrorMsg: 'Le nom doit contenir au maximum ${max} caractères' // eslint-disable-line no-template-curly-in-string
    },
    nationality: {
      label: 'Nationalité*',
      name: 'nationality',
      requiredErrorMsg: 'La nationalité est requise'
    },
    optIn: {
      label: 'labelmarketing',
      name: 'marketingOptIn',
    },
    password: {
      label: 'Mot de passe',
      name: 'password',
      requiredErrorMsg: 'Le mot de passe est requis',
      invalidErrorMsg: 'Le mot de passe doit contenir au minimum ${min} caractères' // eslint-disable-line no-template-curly-in-string
    },
    passwordConfirm: {
      label: 'Confirmation du mot de passe',
      name: 'passwordConfirm',
      requiredErrorMsg: 'La confirmation du mot de passe est requise',
      invalidErrorMsg: 'Le mot de passe doit contenir au minimum ${min} caractères', // eslint-disable-line no-template-curly-in-string,
      passwordDontMatch: 'Les mots de passe ne correspondent pas'
    }
  }
}

export default ionisConnectFormModel
