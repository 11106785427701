import { createSlice } from '@reduxjs/toolkit'

export const buttonsSlice = createSlice({
  name: 'buttons',
  initialState: {
    disabled: false,
    visible: true
  },
  reducers: {
    disabled: (state, action) => {
      state.disabled = true
    },
    enabled: (state, action) => {
      state.disabled = false
    },
    hide: (state, action) => {
      state.visible = false
    },
    show: state => {
      state.visible = true
    }
  }
})

export const { disabled, enabled, hide, show } = buttonsSlice.actions

export default buttonsSlice.reducer
