import {
  Alert,
  Button,
  CircularProgress,
  Snackbar,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import { useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import Salesforce from "applicant-components/services/Salesforce";
import { InputField } from "applicant-components/components/FormFields";
// import { InputExample } from "../FormFields/InputExample"
import useStyles from "components/Portal/styles";
import validationSchema from "./FormModel/validationSchema";
import { useTranslation } from "react-i18next"

const ContactCommercial = ({ opportunityId }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [criticalErrors, setCriticalErrors] = useState(false);
  const { t } = useTranslation()

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  async function _handleSubmit(values, actions) {
    const message = values.message;
    await Salesforce.createTask(opportunityId, '', message).catch((e) => {
      setCriticalErrors(true);
      actions.setSubmitting(false);
    });
    setOpen(true);
    actions.setSubmitting(false);
    actions.setFieldValue("message", "", false);
  }

  return (
    <>
      <Typography gutterBottom variant="h6">
        {t('contact_commercial.title')}.
      </Typography>
      <Formik
        initialValues={{ message: "" }}
        validationSchema={validationSchema}
        onSubmit={_handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form id="contactCommercial" style={{ marginTop: "30px" }}>
            <InputField
              fullWidth
              label={t('contact_commercial.message')}
              minRows={4}
              multiline
              name="message"
            />
            <div className={classes.buttons}>
              <div className={classes.wrapper}>
                <Button
                  color="primary"
                  className={classes.button}
                  disabled={isSubmitting}
                  startIcon={<SendIcon />}
                  type="submit"
                  variant="contained"
                >
                  {t('contact_commercial.send_message')}
                </Button>
                {isSubmitting && (
                  <CircularProgress
                    className={classes.buttonProgress}
                    size={24}
                  />
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
      {!criticalErrors && (
        <Snackbar
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
          autoHideDuration={5000}
          onClose={handleClose}
          open={open}
        >
          <Alert
            elevation={6}
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
            variant="filled"
          >
            {t('contact_commercial.message_sent')}.
          </Alert>
        </Snackbar>
      )}
      {criticalErrors && (
        <Snackbar
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
          autoHideDuration={5000}
          onClose={handleClose}
          open={open}
        >
          <Alert
            elevation={6}
            onClose={handleClose}
            severity="error"
            sx={{ width: "100%" }}
            variant="filled"
          >
            {t('error_form2')}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default ContactCommercial;
