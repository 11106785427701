import { Alert, AlertTitle, Backdrop, Button, CircularProgress, MobileStepper, Snackbar, Step, StepLabel, Stepper } from "@mui/material"
import { Box } from "@mui/material"
import { sendError } from "applicant-components/services/Errors"
import { hasOneOppyRegisteredFinished, isLogged, removeOpportunities, SalesforceUpdateStageStop, updateAccount, updateOpportunities, updateOpportunity, updateSupportingDocument } from "applicant-components/Store/salesforceSlice"
import { Form, Formik, useFormikContext } from "formik"
import { useRef } from "react"
import { useEffect } from "react"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ButtonBack, ButtonNext } from "../Buttons"
import registrationFormModel from "./FormModel/registrationFormModel"
import PersonnalInformations from "./Forms/PersonnalInformations"
import useStyles from "./styles"
import Salesforce from "applicant-components/services/Salesforce";
import Parents from "./Forms/Parents"
import Urgence from "./Forms/Urgence"
import Finance from "./Forms/Finance"
import Modalites from "./Forms/Modalites"
import validationSchema from "./FormModel/validationSchema"
import Recapitulatif from "./Forms/Recapitulatif"
import ConfirmationRegistration from "../Popin/ConfirmationRegistration"
import { Link } from "react-router-dom"
import persistStore from "redux-persist/es/persistStore"
import Store from "../../Store/Store"
import { useHistory } from "react-router"
import Payment from "./Forms/Payment"
import { updateEmergencyFinancial } from "applicant-components/services/Validation"

const { formId, formField } = registrationFormModel

function _renderStepContent(step) {
  switch(step) {
    case 0:
      return <PersonnalInformations formField={formField} />
    case 1:
      return <Parents formField={formField} />
    case 2:
      return <Urgence formField={formField} />
    case 3:
      return <Finance formField={formField} />
    case 4:
      return <Modalites formField={formField} />
    case 5:
      return <Recapitulatif formField={formField} />
    case 6:
      return <Payment formField={formField} />
    default:
      return <div>Not found</div>
  }
}

const RegistrationForm = () => {

  // const steps = ['Mes informations personnelles', 'Parents', `Contact d'urgence`, 'Responsable financier', 'Modalités de paiement des frais de scolarité', 'Récapitulatif']
  const dispatch = useDispatch()
  const history = useHistory()
  const classes = useStyles()
  const [activeStep, setActiveStep] = useState(0)
  const [steps, setSteps] = useState(['Mes informations personnelles', 'Parents', `Contact d'urgence`, 'Responsable financier', 'Modalités de paiement des frais de scolarité', 'Récapitulatif'])
  const isLastStep = activeStep === steps.length - 1
  const [criticalErrors, setCriticalErrors] = useState(false)
  const [isSubmittingBack, setIsSubmittingBack] = useState(false)
  const [pay, setPay] = useState(false)
  const [payLater, setPayLater] = useState(false)
  const [openPopup, setOpenPopup] = useState(false)
  const documents = useSelector(state => state.salesforce.documents)
  const step = useSelector(state => state.salesforce.account.StageStop__c)
  const buttonsDisabled = useSelector((state) => state.buttons.enabled)
  const buttonsVisible = useSelector((state) => state.buttons.visible)
  const modifyStep = useSelector(state => state.salesforce.modifyStep)
  const currentValidationSchema = (modifyStep === null && validationSchema[activeStep]) || validationSchema[modifyStep]
  const account = useSelector(state => state.salesforce.account)
  const opportunity = useSelector(state => state.salesforce.opportunity)
  const recap = useSelector(state => state.salesforce.recap)
  const returnPageConfirmationInscription = useSelector(state => state.salesforce.returnPages.confirmation_inscription)
  // const RegistrationPaymentMode = opportunity.RegistrationPaymentMode__c
  let persistor = persistStore(Store)
  const formRef = useRef()

  useEffect(() => {
    const currentStep = parseInt(step)
    setActiveStep(currentStep)
  }, [step])

  useEffect(() => {
    if (opportunity.RegistrationPaymentMode__c === 'CB') {
      // steps.push(`Paiement des premiers frais d'inscription`)
      setSteps(current => [...current, `Paiement des premiers frais d'inscription`])
    } else {
      if (steps.length === 7) {
        const stepsCopy = [...steps]
        stepsCopy.pop()
        setSteps(stepsCopy)
      }
    }
  }, [opportunity.RegistrationPaymentMode__c])

  async function _submitForm(values, actions) {
    if (opportunity.RegistrationPaymentMode__c === 'CB') {
      setPay(true)
    }
    actions.setSubmitting(false)
    setOpenPopup(true)
  }

  // const formikProps = useFormikContext()

  // const updateEmergencyFinancial = (account) => {
  //   console.log(account)
  //   if (account.WhoEmergencyContact__pc.includes('Parent 1')) {
  //     account.EmergencyLastName__pc = account.Parent1Name__pc
  //     // formikProps.setFieldValue('account.EmergencyDegreeOfRelationship__pc', account.Parent1DegreeOfRelationship__pc, false)
  //     // formikProps.setFieldValue('account.EmergencyFirstName__pc', account.Parent1Firstname__pc, false)
  //     // formikProps.setFieldValue('account.EmergencyLastName__pc', account.Parent1Name__pc, false)
  //     // formikProps.setFieldValue('account.EmergencyMail__pc', account.Parent1Email__pc, false)
  //     // formikProps.setFieldValue('account.EmergencyMobile__pc', account.Parent1MobilePhone__pc, false)
  //     // formikProps.setFieldValue('account.EmergencyPhone__pc', account.Parent1Phone__pc, false)
  //     // formikProps.setFieldValue('account.EmergencyTitle__pc', account.Parent1Civility__pc, false)
  //   }
  //   if (account.WhoEmergencyContact__pc.includes('Parent 2')) {
  //     // formikProps.setFieldValue('account.EmergencyCivility2__pc', account.Parent2Civility__pc, false)
  //     // formikProps.setFieldValue('account.EmergencyDegreeOfRelationship2__pc', account.Parent2DegreeOfRelationship__pc, false)
  //     // formikProps.setFieldValue('account.EmergencyFirstName2__pc', account.Parent2Firstname__pc, false)
  //     // formikProps.setFieldValue('account.EmergencyLastName2__pc', account.Parent2Name__pc, false)
  //     // formikProps.setFieldValue('account.EmergencyMail2__pc', account.Parent2Email__pc, false)
  //     // formikProps.setFieldValue('account.EmergencyMobile2__pc', account.Parent2MobilePhone__pc, false)
  //     // formikProps.setFieldValue('account.EmergencyPhone2__pc', account.Parent2Phone__pc, false)
  //   }

  //   return account
  // }

  async function _handleSubmit(values, actions) {
    setCriticalErrors(false)
    let account = values.account;
    const opportunity = values.opportunity
    const rib = values.rib
    // Upload du RIB
    if (rib !== undefined) {
      const updateAttachmentAccount = await Salesforce.addAttachmentToSobject(
        rib,
        account.Id,
        opportunity.Id
      ).catch((e) => {
        sendError(e)
        setCriticalErrors(true)
        actions.setSubmitting(false)
      })
      const startedBatchAttachments = updateAttachmentAccount.data;
      if (updateAttachmentAccount.success) {
        for (let k = 0; k < startedBatchAttachments.length; k++) {
          await Salesforce.checkAttachment(
            startedBatchAttachments[k].batchId,
            startedBatchAttachments[k].jobId
          ).catch((e) => {

          });
          await Salesforce.resultAttachment(
            startedBatchAttachments[k].batchId,
            startedBatchAttachments[k].jobId
          ).catch((e) => {

          });
        }
      }
    }
    account = updateEmergencyFinancial(account)
    const accountUpdated = await Salesforce.updateAccount(
      account,
      activeStep,
      false,
      'Candidature',
      isLastStep || (activeStep === 5 && steps.length === 7)
    ).catch((e) => {
      sendError(e)
      setCriticalErrors(true)
      actions.setSubmitting(false)
    })
    const opportunityUpdated = await Salesforce.updateOpportunity(
      opportunity,
      false,
      'Candidature'
    ).catch((e) => {
      sendError(e)
      setCriticalErrors(true)
      actions.setSubmitting(false)
    })
    if (accountUpdated !== undefined && accountUpdated.success !== undefined && opportunityUpdated !== undefined && opportunityUpdated.success !== undefined) {
      dispatch(updateAccount(accountUpdated.account))
      dispatch(updateOpportunity(opportunityUpdated.opportunity))
      if (isLastStep || (activeStep === 5 && steps.length === 7)) {
        _submitForm(values, actions)
      } else {
        setActiveStep(activeStep + 1) // sert ???
        actions.setTouched({})
        actions.setSubmitting(false)
      }
    }
  }

  async function _handleBack() {
    setCriticalErrors(false);
    setIsSubmittingBack(true);
    const newStep = activeStep - 1;
    const accountId = account.Id
    await dispatch(SalesforceUpdateStageStop({ accountId, newStep }));
    setActiveStep(parseInt(newStep));
    setIsSubmittingBack(false);
  }

  async function _payLater(values) {
    setIsSubmittingBack(true)
    setPayLater('true')
    setCriticalErrors(false)
    const account = values.account;
    const opportunity = values.opportunity
    const accountUpdated = await Salesforce.updateAccount(
      account,
      activeStep,
      false,
      'Candidature',
      isLastStep || (activeStep === 5 && steps.length === 7)
    ).catch((e) => {
      sendError(e)
      setCriticalErrors(true)
      // actions.setSubmitting(false)
    })
    const opportunityUpdated = await Salesforce.updateOpportunity(
      opportunity,
      false,
      'Candidature'
    ).catch((e) => {
      sendError(e)
      setCriticalErrors(true)
      // actions.setSubmitting(false)
    })
    if (accountUpdated !== undefined && accountUpdated.success !== undefined && opportunityUpdated !== undefined && opportunityUpdated.success !== undefined) {
      dispatch(updateAccount(accountUpdated.account))
      dispatch(updateOpportunity(opportunityUpdated.opportunity))
      setIsSubmittingBack(false)
      setOpenPopup(true)
    }
  }

  async function closeRegistration(close) {
    if (close) {
      // On met à jour certains champs sur l'oppy (validation du formulaire, et envoi du docusign)
      const opportunityToUpdate = {
        Id: opportunity.Id,
        RegistrationValidFormDate__c: new Date(),
        SendDocusign__c: true
      }
      const opportunityUpdated = await Salesforce.updateOpportunity(
        opportunityToUpdate,
        false,
        'Candidature'
      )
      if (opportunityUpdated.success) {
        if (pay) {
          // const newStep = activeStep + 1
          // const accountId = account.Id
          // await dispatch(SalesforceUpdateStageStop({ accountId, newStep }))
          dispatch(updateOpportunity(opportunityUpdated.opportunity))
          dispatch(updateOpportunities(opportunityUpdated.opportunities))
          dispatch(hasOneOppyRegisteredFinished(opportunityUpdated.opportunities))
          setOpenPopup(false)
        } else {
          if (payLater || isLastStep) {
            persistor.purge().then(() => {
              // document.location.href = '/'
              dispatch(isLogged());
              dispatch(updateAccount({}));
              dispatch(updateOpportunity({}));
              dispatch(removeOpportunities());
              dispatch(updateSupportingDocument([]));
              history.push(returnPageConfirmationInscription);
            });
          }
        }
      }
    } else {
      setOpenPopup(false);
    }
  }

  const getParentsAddresses = () => {
    let adressesParents = {}
    const adresseEtudiant = account.PersonMailingStreet + account.PersonMailingPostalCode + account.PersonMailingCity + account.PersonMailingCountry
    const adresseParent1 = account.Parent1Address__pc + account.Parent1CP__pc + account.Parent1City__pc +  account.Parent1Country__pc
    const adresseParent2 = account.Parent2Address__pc + account.Parent2CP__pc + account.Parent2City__pc +  account.Parent2Country__pc

    if (adresseEtudiant === adresseParent1) {
      adressesParents.parent1 = true
    } else {
      adressesParents.parent1 = false
    }
    if (adresseEtudiant === adresseParent2) {
      adressesParents.parent2 = true
    } else {
      adressesParents.parent2 = false
    }

    return adressesParents
  }

  const getParentsScolar = () => {
    let parents = []
    const { Parent1ReceiveScholarInformations__c, Parent2ReceiveScholarInformations__c } = opportunity
    if (Parent1ReceiveScholarInformations__c) {
      parents.push('Parent 1')
    }
    if (Parent2ReceiveScholarInformations__c) {
      parents.push('Parent 2')
    }
    return parents
  }

  return (
    <>
      {/* {activeStep === 0 && (
        <Alert elevation={6} severity="info" variant="filled">
          <AlertTitle>IMPORTANT : Lisez ces documents avant de démarrer votre inscription</AlertTitle>
          <ul>
            {documents.map((document, index) => (
              <li key={index}>
                <Link className={classes.link_documents} target="_blank" to={{ pathname: `${process.env.REACT_APP_SF_CONNECT_API}/api/getDocument?documentId=${document.Id}` }}>{document.Description}</Link>
              </li>
            ))}
          </ul>
        </Alert>
      )} */}
      <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
        <Stepper activeStep={activeStep} className={classes.stepper} nonLinear orientation="horizontal">
          {steps.map((label, index) =>(
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <>
        {activeStep === steps.length ? (
          <div>Bravo</div>
        ) : (
          <Formik
            enableReinitialize
            initialValues={{
              account,
              addresses: getParentsAddresses(),
              emergencyContact: account.WhoEmergencyContact__pc !== '' ? account.WhoEmergencyContact__pc.split(';') : [],
              hasOneBrotherOrSister: account.FirstnameBrotherSisterStudentIonis__pc !== '' && account.LastnameBrotherSisterStudentIonis__c !== '' && account.RelativeInIonisSchool__c !== '' ? 'Oui' : 'Non',
              opportunity,
              parentsScolar: getParentsScolar(),
              providedFrenchEstablishment: account.INENumber__c !== '' ? 'Oui' : 'Non',
              recap,
              rib: ''
            }}
            innerRef={formRef}
            onSubmit={_handleSubmit}
            validationSchema={currentValidationSchema}
          >
            {({ errors, isSubmitting, submitCount, values }) => (
              <Form id={formId} noValidate>
                {_renderStepContent(activeStep)}
                {Object.keys(errors).length !== 0 && submitCount > 0 && (
                  <Snackbar
                    anchorOrigin={{ horizontal: "center", vertical: "top" }}
                    open={true}
                  >
                    <Alert elevation={3} severity="error">
                      Certains champs requis n'ont pas été remplis.
                    </Alert>
                  </Snackbar>
                )}
                {criticalErrors && (
                  <Box mt={2}>
                    <Alert severity="error" variant="outlined">
                      <AlertTitle>Une erreur est survenue</AlertTitle>
                      Une erreur est survenue lors de la soumission du
                      formulaire. Veuillez réessayer ou retenter un peu plus
                      tard.
                    </Alert>
                  </Box>
                )}
                <Box sx={{ display: { xs: "none", sm: "block" } }}>
                  {buttonsVisible && (
                    <div className={classes.buttons}>
                      {activeStep !== 0 && activeStep !== 5 && (
                        <div className={classes.wrapper}>
                          <Button
                            className={classes.button}
                            disabled={isSubmittingBack || isSubmitting}
                            onClick={_handleBack}
                          >
                            Précédent
                          </Button>
                          {isSubmittingBack && (
                            <CircularProgress
                              className={classes.buttonProgress}
                              size={24}
                            />
                          )}
                        </div>
                      )}
                      {activeStep === 5 && steps.length === 7 && (
                        <div className={classes.wrapper}>
                          <Button
                            className={classes.button}
                            disabled={isSubmittingBack || isSubmitting}
                            onClick={() => _payLater(values)}
                          >
                            Valider et payer plus tard
                          </Button>
                          {isSubmittingBack && (
                            <CircularProgress
                              className={classes.buttonProgress}
                              size={24}
                            />
                          )}
                        </div>
                      )}
                      <div className={classes.wrapper}>
                        <Button
                          color="primary"
                          className={classes.button}
                          disabled={
                            isSubmitting || isSubmittingBack || buttonsDisabled
                          }
                          type="submit"
                          variant="contained"
                        >
                          {isLastStep ? "Valider mon dossier d'inscription" : !isLastStep && steps.length === 7 && activeStep === 5 ? "Valider et payer les premiers frais d'inscription" : "Suivant"}
                        </Button>
                        {isSubmitting && (
                          <CircularProgress
                            className={classes.buttonProgress}
                            size={24}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </Box>
                <Box sx={{ display: { xs: "block", sm: "none" } }}>
                  <Backdrop
                    sx={{
                      color: "#fff",
                      zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={isSubmitting || isSubmittingBack}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>
                </Box>
                {!isNaN(activeStep) && (
                  <MobileStepper
                    activeStep={activeStep}
                    backButton={
                      <ButtonBack
                        activeStep={activeStep}
                        handleBack={_handleBack}
                        isSubmitting={isSubmitting || !buttonsVisible}
                        isSubmittingBack={isSubmittingBack}
                      />
                    }
                    nextButton={
                      <ButtonNext
                        activeStep={activeStep}
                        handleNext={_handleSubmit}
                        isLastStep={isLastStep}
                        isSubmitting={isSubmitting || !buttonsVisible}
                        maxSteps={steps.length}
                      />
                    }
                    position="bottom"
                    steps={steps.length}
                    sx={{ display: { sm: "none" } }}
                    variant="dots"
                  ></MobileStepper>
                )}
              </Form>
            )}
          </Formik>
        )}
        <ConfirmationRegistration
          closeRegistration={closeRegistration}
          openPopup={openPopup}
          finish={isLastStep}
          pay={pay}
          payLater={payLater}
        />
      </>
    </>
  )
}

export default RegistrationForm
