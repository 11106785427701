import * as Sentry from "@sentry/react"

export function setUser(accountId, PersonEmail) {
  Sentry.setUser({
    id: accountId,
    email: PersonEmail
  })
}

export function sendError (e) {
  Sentry.captureException(e)
}

export function sendMessage (message, data) {
  for (const [key, value] of Object.entries(data)) {
    Sentry.setExtra(key, value)
  }
  Sentry.captureMessage(message)
}
