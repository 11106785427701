import { Alert, Button, Divider, Grid, Typography } from "@mui/material"
import { Box } from "@mui/system"
import Opportunities from "./Opportunities"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { hasLoginOnce, SalesforceUpdateStageStop } from "applicant-components/Store/salesforceSlice"
import { STAGE_ADMIS, STAGE_COURS, STAGE_QUALIFICATION, STAGE_WAIT_ATTACHMENTS } from "applicant-components/services/StageNameOpportunity"
import { useState } from "react"
import OpportunityRegistration from "./OpportunityRegistration"

const ApplicationPortal = ({ JPC, LogicYear, NewApplication, NewSupportingDocument, Summary }) => {

  const dispatch = useDispatch()
  const accountId = useSelector(state => state.salesforce.account.Id)
  const firstName = useSelector(state => state.salesforce.account.FirstName)
  const step = useSelector(state => state.salesforce.account.StageStop__c)
  const paymentType = useSelector((state) => state.salesforce.paymentType)
  const hasOneOppyCompleted = useSelector(state => state.salesforce.hasOneOppyCompleted)
  const stageName = useSelector(state => state.salesforce.opportunity.StageName)
  const documentsCompleted = useSelector(state => state.salesforce.opportunity.Tous_documents_valides__c)
  const stageStop = useSelector(state => state.salesforce.account.StageStop__c)
  const { t } = useTranslation()
  const [disabled, setDisabled] = useState(false)
  const opportunities = useSelector(state => state.salesforce.opportunities)
  const opportunitiesAddmited = opportunities.filter(el => el.StageName === STAGE_ADMIS)

  const goToForm = async () => {
    setDisabled(true)
    if (stageName === STAGE_WAIT_ATTACHMENTS && !documentsCompleted) {
      // On met à jour l'étape à celle d'avant et l'amène sur le formulaire
      if ((paymentType === 'JPC' && step === '6') || (paymentType === 'Candidature' && step === '5')) {
        const newStep = step - 1
        await dispatch(SalesforceUpdateStageStop({ accountId, newStep }))
        dispatch(hasLoginOnce(''))
      } else {
        dispatch(hasLoginOnce(''))
      }
    } else {
      dispatch(hasLoginOnce(''))
    }
  }

  return (
    <div aria-label="dashboard">
      <Grid container spacing={3}>
        <Grid item sm={hasOneOppyCompleted ? 5 : 6 }>
          <Typography variant="h6" sx={{ mb: 2 }}>{t('applicationPortal.hello')} {firstName}</Typography>

          {Summary !== undefined && <Summary />}
          <br />
          {JPC !== undefined && stageName !== STAGE_ADMIS && stageStop > 4 && <JPC />}
        </Grid>
        {/*opportunitiesAddmited.length === 0 &&*/ opportunities.length > 0 && hasOneOppyCompleted && (
          <Grid item sm={7}>
            <Typography variant="h6" sx={{ mb: 2 }}>{t('applicationPortal.my_applications')}</Typography>
            {LogicYear !== undefined && NewSupportingDocument !== undefined && <Opportunities opportunities={opportunities} opportunitiesAddmited={opportunitiesAddmited} LogicYear={LogicYear} NewSupportingDocument={NewSupportingDocument} />}
            <Box sx={{ my: 3 }}>
              {NewApplication !== undefined && <NewApplication />}
            </Box>
          </Grid>
        )}
        {/* {opportunitiesAddmited.length > 0 && (
          <Grid item sm={7}>
            <OpportunityRegistration opportunitiesAddmited={opportunitiesAddmited} />
          </Grid>
        )} */}
        {!hasOneOppyCompleted && (
          <Grid item sm={6}>
            <Alert color="info" sx={{ mb: 2 }}>
              {(stageName === undefined) && (
                <>Votre candidature n'est pas terminée. Veuillez reprendre la où vous en étiez pour la finaliser.</>
              )}
              {(stageName === STAGE_QUALIFICATION || stageName === STAGE_COURS) && (
                <>Votre candidature est incomplète. Merci de cliquer sur le bouton ci-dessous pour continuer votre candidature.</>
              )}
              {stageName === STAGE_WAIT_ATTACHMENTS && !documentsCompleted && (
                <>Vous n'avez pas encore renseigné toutes les pièces jointes. Merci de cliquer sur le bouton ci-dessous pour ajouter les derniers documents requis.</>
              )}
              {stageName === STAGE_WAIT_ATTACHMENTS && documentsCompleted && (
                <>Vous n'avez pas encore complété votre candidature. Cliquez sur le bouton ci-dessous puis validez votre candidature.</>
              )}
            </Alert>
            <Button disabled={disabled} onClick={goToForm} variant="contained">Continuer ma candidature</Button>
          </Grid>
        )}
      </Grid>
    </div>
  )
}

export default ApplicationPortal
