import { LoadingButton } from "@mui/lab"
import { Alert, AlertTitle, Box, Button, useMediaQuery, useTheme } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { updateOpportunity } from "applicant-components/Store/salesforceSlice"
import Salesforce from "applicant-components/services/Salesforce"
import { useState } from "react"
import { Document, Page, pdfjs } from "react-pdf"
import { useDispatch, useSelector } from "react-redux"

const useStyles = makeStyles((theme) => ({
  root: {
    height: `calc(100vh - ${theme.spacing(24)})`, // Soustraire la hauteur de l'en-tête fixe
    width: '100%',
    // maxWidth: '820px',
    margin: '0 auto',
    border: 'none',
    overflowY: 'auto',
  },
  content: {
    padding: theme.spacing(2),
  },
}))

const PDF = ({ document, documents }) => {

  const dispatch = useDispatch()
  const classes = useStyles()
  const theme = useTheme()
  const windowWidth = window.innerWidth
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const numberDocuments = documents.length
  const [currentDocument, setCurrentDocument] = useState(0)
  const opportunityId = useSelector(state => state.salesforce.opportunity.Id)
  const [loading, setLoading] = useState(false)

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.js',
    import.meta.url,
  ).toString();

  const renderPages = () => {
    const pages = []
    for (let i = 1; i <= numPages; i++) {
      pages.push(<Page pageNumber={i} key={i} loading="Chargement de la page" renderTextLayer={false} width={windowWidth - 100} renderAnnotationLayer={false} />)
    }
    return pages
  }

  const nextDocument = async () => {
    console.log('next document')
    if (currentDocument < numberDocuments - 1) {
      window.scrollTo(0, 0)
      setCurrentDocument(currentDocument + 1)
    } else {
      setLoading(true)
      // Mise à jour
      const opportunityUpdated = await Salesforce.updateOpportunity(
        {
          Id: opportunityId,
          RegistrationConsentDocuments__c: true
        },
        false,
        'Candidature'
      ).catch((e) => {

      })
      dispatch(updateOpportunity(opportunityUpdated.opportunity))
    }
  }

  return (
    <>
      <Alert elevation={6} severity="info" sx={{ mb: 2 }} variant="filled">
        <AlertTitle>IMPORTANT : Lisez ces documents avant de démarrer votre inscription</AlertTitle>
      </Alert>
      {/* <Box sx={{ width: '820px', margin: ' 20px auto 0 auto', height: 'calc(100vh - 136px)', overflowY: 'auto' }}> */}
      <Box className={classes.root}>
        <Document file={`${process.env.REACT_APP_SF_CONNECT_API}/api/getDocument?documentId=${documents[currentDocument].Id}`} loading="Chargement du PDF" noData={<Alert severity="error">Aucun document spécifié</Alert>} onLoadSuccess={onDocumentLoadSuccess}>
          {renderPages()}
        </Document>
      </Box>
      <Box display="flex" flexDirection="row-reverse">
        <LoadingButton loading={loading} onClick={nextDocument} variant="contained" sx={{ float: "right", mt: 2 }}>J'ai lu le document "{documents[currentDocument].Name}"</LoadingButton>
        {/* <Button onClick={nextDocument} variant="contained" sx={{ float: "right", mt: 2 }}>J'ai lu le document "{documents[currentDocument].Name}"</Button> */}
      </Box>
    </>
  )
}

export default PDF
