import { CssBaseline, useMediaQuery } from "@mui/material"
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
// import Footer from "../Footer"
import { createTheme } from "@mui/material/styles"
import { useSelector } from "react-redux"
import { useEffect } from "react"
import Layout from "./Layout"

const MaterialLayout = (props) => {
  const { children } = props
  // const classes = useStyles()
  const themeMode = useSelector(state => state.theme.theme)
  // const [finalTheme, setFinalTheme] = useState('light')
  // const [finalColor, setFinalColor] = useState(900)
  const isDarkModeEnabled = useMediaQuery('(prefers-color-scheme: dark)')
  // const isLogged = useSelector(state => state.salesforce.isLogged)

  const theme = createTheme(
    {
      status: {
        danger: '#e53e3e'
      },
      palette: { mode: 'light', primary: { main: '#ff4d4d' }, secondary: { main: '#ff4d4d' }, neutral: { main: '#5c6ac4' } }
    }
  )

  useEffect(() => {
    // if (themeMode === 'system') {
    //   if (isDarkModeEnabled) {
    //     setFinalTheme('dark')
    //     // setFinalColor(700)
    //   } else {
    //     setFinalTheme('light')
    //     // setFinalColor(900)
    //   }
    // } else {
    //   setFinalTheme(themeMode)
    //   if (themeMode === 'dark') {
    //     // setFinalColor(700)
    //   } else {
    //     // setFinalColor(900)
    //   }
    // }
  }, [themeMode, isDarkModeEnabled])

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Layout>
          {children}
        </Layout>
        {/* <Footer /> */}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default MaterialLayout
