import { useState, useEffect } from "react"
import { useField } from "formik"
import { Grid, TextField } from "@mui/material"
// import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import { DatePicker } from "@mui/x-date-pickers"
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import frLocale from 'date-fns/locale/fr'
import enLocale from 'date-fns/locale/en-US'
import { useSelector } from "react-redux"
import moment from "moment"
// import DateFnsUtils from "@date-io/date-fns/"

const localeMap = {
  en: enLocale,
  fr: frLocale
}

const DatePickerField = (props) => {
  const {datemax, ...rest} = props
  const [field, meta, helper] = useField(props)
  const { touched, error } = meta
  const { setValue } = helper
  const isError = touched && error && true
  const { value } = field
  const [selectedDate, setSelectedDate] = useState(null)
  // const [locale, setLocale] = useState('fr')
  const locale = useSelector(state => state.salesforce.language)

  useEffect(() => {
    if (value) {
      const date = new Date(value)
      setSelectedDate(date)
    }
  }, [value])

  function _onChange(date) {
    if (date) {
      setSelectedDate(date)
      try {
        // const ISODateString = date.toISOString()
        // setValue(ISODateString)
        const convertDate = moment(date).format('YYYY-MM-DD')
        setValue(convertDate)
      } catch (error) {
        setValue(date)
      }
    } else {
      setValue(date)
    }
  }

  return (
    <Grid container>
      <LocalizationProvider adapterLocale={localeMap[locale]} dateAdapter={AdapterDateFns}>
        <DatePicker
          {...rest}
          cancelText="Annuler"
          maxDate={datemax}
          onChange={_onChange}
          onError={(value, test, test2) => console.log('onerror', value, test, test2)}
          renderInput={(params) => <TextField {...props} {...params} error={isError} helperText={isError ? error : ''} value="" aria-label={field.name} />}
          value={selectedDate}
        />
      </LocalizationProvider>
    </Grid>
  )
}

export default DatePickerField
