import { useFormikContext } from "formik"
import { useEffect, useState } from "react"
import LogicApplication from "services/LogicApplication"
import LogicMscMba from "services/LogicMscMba"
import LogicPGE from "services/LogicPGE"
import LogicBMI from "services/LogicBMI"
import SelectField from "applicant-components/components/FormFields/SelectField"
import { useTranslation } from "react-i18next"
import { Alert } from "@mui/material"

const TrackProgram = ({ campus, program, sessions, setOptionsSpecialities, specialities, year, formField }) => {

  const [optionsTrack, setOptionsTrack] = useState([])
  const [trackDisabled, setTrackDisabled] = useState(true)
  const [showDesmap, setShowDesmap] = useState(false)
  const [messageDesmap, setMessageDesmap] = useState('')

  const {
    opportunity: {
      Track__c
    }
  } = formField

  const formikProps = useFormikContext()
  const { t } = useTranslation()

  useEffect(() => {
    setShowDesmap(false)
    if (year !== undefined) {
      // const tracks = LogicMscMba.track
      let tracks = undefined
      if (program === 'ISG MSC et MBA') {
        tracks = LogicMscMba.years.find(el => el.year === year)
      }
      if (program === 'ISG BS France') {
        tracks = LogicPGE.years.find(el => el.year === year)
      }
      if (program === 'ISG BS International') {
        tracks = LogicBMI.years.find(el => el.year === year)
      }
      if (tracks !== undefined) {
        setOptionsTrack(tracks.track)
        if (tracks.track.length === 1) {
          formikProps.setFieldValue(Track__c.name, tracks.track[0].value)
          trackFilled(tracks.track[0].value)
        }
      }
    }
  }, [program, year])

  const trackFilled = (value) => {
    formikProps.setFieldValue('application.sessionsDisabled', false)
    formikProps.setFieldValue('application.trackMinorDisabled', true)
    formikProps.setFieldValue('application.trackMajorDisabled', true)
    formikProps.setFieldValue('application.trackMinor', [])
    formikProps.setFieldValue('application.trackMinorId', [])
    formikProps.setFieldValue('application.trackMajor', '')
    formikProps.setFieldValue('opportunity.Session__c', '')
    setOptionsSpecialities([])
    let _sessions = []
    if (program === 'ISG MSC et MBA') {
      _sessions = LogicApplication.getSessionsMsc(program, year, campus, sessions, value)
    }
    if (program === 'ISG BS France' || program === 'ISG BS International') {
      _sessions = LogicApplication.getSessionsPGEBMI(program, year, campus, sessions, value)
    }
    formikProps.setFieldValue('application.optionsSessions', _sessions)
    if (_sessions.length === 1) {
      formikProps.setFieldValue('opportunity.Session__c', _sessions[0].value)
      const filterSpecialities = specialities.filter(el => el.Session__c === _sessions[0].value).map(el => ({
        label: el.Speciality__r.Name,
        value: el.Speciality__c
      }))
      setOptionsSpecialities(filterSpecialities)
      if (filterSpecialities.length === 1) {
        formikProps.setFieldValue('application.trackMajor', filterSpecialities[0].value)
      }
      formikProps.setFieldValue('application.trackMajorDisabled', false)
    }
  }

  useEffect(() => {
    if (campus !== undefined && campus !== '') {
      setShowDesmap(false)
      setTrackDisabled(false)
    } else {
      setTrackDisabled(true)
    }
  }, [campus])

  return (
    <>
      <SelectField
        data={optionsTrack}
        disabled={trackDisabled}
        fullWidth
        label={t(Track__c.name)}
        name={Track__c.name}
        onChange={e => {
          const { value } = e.target
          const campusTrack = optionsTrack.find(el => el.value === value).campus
          setShowDesmap(false)
          if (campusTrack !== undefined && !campusTrack.includes(campus)) {
            setShowDesmap(true)
            if (value === 'MSc Project Management 1') {
              setMessageDesmap(`Sur le campus de ${campus}, la filière MSc Project Management est remplacée par MSc Management et gestion de projet - DESMAP.`)
            }
            if (value === 'MSc DESMAP 1') {
              setMessageDesmap(`La filière MSc Management et gestion de projet - DESMAP n'est pas proposée sur le campus de ${campus}.`)
            }
          }
          formikProps.setFieldValue(Track__c.name, value)
          trackFilled(value)
        }}
      />
      {showDesmap && (
        <Alert severity="info" sx={{ mt: 2 }}>
          <strong>{messageDesmap}</strong>
        </Alert>
      )}
    </>
  )
}

export default TrackProgram
