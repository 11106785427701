import {
  Button,
  Grid,
  Icon,
  Tooltip,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Select,
  MenuItem,
  FormControl,
  FormLabel,
  Typography,
  Alert,
  AlertTitle,
  Box,
  Snackbar,
  CircularProgress,
  LinearProgress,
  Backdrop,
  useMediaQuery,
} from "@mui/material";

import { styled, useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import useStyles from "../styles";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FieldArray, useFormikContext } from "formik";
import { updateSupportingDocument } from "applicant-components/Store/salesforceSlice"
import Salesforce from "applicant-components/services/Salesforce";
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { STAGE_WAIT_ATTACHMENTS } from "applicant-components/services/StageNameOpportunity"

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

const SupportingDocument = (props) => {
  const dispatch = useDispatch()
  const classes = useStyles();
  const account = useSelector((state) => state.salesforce.account);
  let opportunity = useSelector((state) => state.salesforce.opportunity);

  let isSecondCycleParis = undefined

  if (props.opportunity !== undefined) {
    isSecondCycleParis = ((props.opportunity.Division__c === 'ISG BS France' && props.opportunity.ProgramYear__c === '4') || props.opportunity.Division__c === 'ISG MSC et MBA') && props.opportunity.SessionCampus__c === 'Paris'
  } else {
    isSecondCycleParis = ((opportunity.Division__c === 'ISG BS France' && opportunity.ProgramYear__c === '4') || opportunity.Division__c === 'ISG MSC et MBA') && opportunity.SessionCampus__c === 'Paris'
  }

  const hasOneOppyCompleted = useSelector(
    (state) => state.salesforce.hasOneOppyCompleted
  );
  const Grant_Student__c = useSelector(
    (state) => state.salesforce.account.Grant_Student__c
  );
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const { t } = useTranslation()

  const [filesToGet, setFilesToGet] = useState(
    t('supportingDocument.documents')
    // [
    //   "Pièce d'identité",
    //   "Bulletins de notes année N",
    //   "Bulletins de notes année N-1",
    //   "Diplômes obtenus",
    //   "CV",
    //   "Lettre de motivation",
    //   "Avis d'attribution de bourse",
    // ].sort()
  );
  const [gotFiles, setGotFiles] = useState([]);
  const [filesBySupportingDocument, setFilesBySupportingDocument] = useState(
    new Map([
      ["Pièce d'identité", 0],
      ["Bulletins de notes année N", 0],
      ["Bulletins de notes année N-1", 0],
      ["Diplômes obtenus", 0],
      ["CV", 0],
      ["Lettre de motivation", 0],
      ["Avis d'attribution de bourse", 0],
    ])
  );
  const [files, setFilesValue] = useState([]);
  const [snackUploadOpen, setSnackUploadOpen] = useState(false);
  const [snackSucessOpen, setSnackSucessOpen] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [buttonAddFilesDisabled, setButtonAddFilesDisabled] = useState(false);
  const [fieldArrayError, setFieldArrayError] = useState([]);
  const [errors, setErrors] = useState(false);
  const [selectedFile, setSelectedFile] = useState(t('supportingDocument.documents.0'));

  const mapSupportingDocument = new Map([
    ["Pièce d'identité", "Identity Document"],
    ["Bulletins de notes année N", "Grades year N"],
    ["Bulletins de notes année N-1", "Grades year N-1"],
    ["Diplômes obtenus", "diplomas obtained"],
    ["CV", "CV"],
    ["Lettre de motivation", "Motivation letter"],
    ["Avis d'attribution de bourse", "Notification grant scholarship"],
  ]);

  const { validateForm } = useFormikContext();
  const formikProps = useFormikContext();

  useEffect(() => {
    async function getAttachements(mounted) {
      if (!mounted) {
        let supportingDocument;
        if (formikProps.initialValues.SupportingDocument__c.length > 0) {
          supportingDocument = formikProps.initialValues.SupportingDocument__c;
          let filesToGetList = filesToGet;
          let gotFilesList = gotFiles;
          let filesList = files;
          for (let i = 0; i < supportingDocument.length; i++) {
            if (
              filesToGetList.includes(supportingDocument[i].supportingDocument)
            ) {
              let index = filesToGetList.indexOf(
                supportingDocument[i].supportingDocument
              );
              filesToGetList.splice(index, 1);
              gotFilesList.push(supportingDocument[i].supportingDocument);
              for (let j = 0; j < supportingDocument[i].files.length; j++) {
                filesList.push(supportingDocument[i].files[j]);
              }
            }
          }
          setFilesToGet(filesToGetList.sort());
          setFilesValue(filesList);
          if (filesList.length > 0) {
            setButtonDisabled(false);
          }
          if (filesToGetList.length === 0) {
            setSelectedFile(filesToGetList);
          } else {
            setSelectedFile(filesToGetList[0]);
          }
        }
        mounted = true;
      }
    }
    let mounted = false;
    getAttachements(mounted);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // if (hasOneOppyCompleted) {
    //   setFilesToGet(["CV", "Lettre de motivation"]);
    //   setSelectedFile("CV");
    // }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCloseSnackUpload = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackUploadOpen(false);
  };

  const abortUpload = async () => {
    formikProps.setFieldValue('opportunity.StageName', STAGE_WAIT_ATTACHMENTS)
    setLoading(true)
    formikProps.submitForm()
  }

  const handleCloseSnackSucess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackSucessOpen(false);
  };

  const handleChange = (event) => {
    setSelectedFile(event.target.value);
  };

  const uploadFiles = async () => {
    console.log('nb fichiers : ' + gotFiles.length)
    setButtonDisabled(true);
    setButtonAddFilesDisabled(true);
    setLoading(true);
    validateForm();
    while (fieldArrayError.length > 0) {
      fieldArrayError.pop();
    }
    setFieldArrayError(fieldArrayError);
    if (typeof formikProps.errors.SupportingDocument__c !== "undefined") {
      if (typeof formikProps.errors.SupportingDocument__c === "object") {
        for (
          let i = 0;
          i < formikProps.errors.SupportingDocument__c.length;
          i++
        ) {
          if (
            typeof formikProps.errors.SupportingDocument__c[i] !== "undefined"
          ) {
            fieldArrayError.push({
              name: formikProps.values.SupportingDocument__c[i]
                .supportingDocument,
              error: formikProps.errors.SupportingDocument__c[i].files,
            });
          }
        }
        setFieldArrayError(fieldArrayError);
        setButtonDisabled(false);
        setButtonAddFilesDisabled(false);
        setLoading(false);
      } else if (typeof formikProps.errors.SupportingDocument__c === "string") {
        fieldArrayError.push({
          error: formikProps.errors.SupportingDocument__c,
        });
        setFieldArrayError(fieldArrayError);
        setButtonDisabled(false);
        setButtonAddFilesDisabled(false);
        setLoading(false);
      }
    } else if (gotFiles.length === 0 && isSecondCycleParis) {
      fieldArrayError.push({
        error: "Vous devez renseigner tous les documents obligatoires",
      });
      setFieldArrayError(fieldArrayError);
      setButtonDisabled(false);
      setButtonAddFilesDisabled(false);
      setLoading(false);
    } else {
      if (gotFiles.length > 0) {
        setSnackUploadOpen(true);
      }
      let updateAttachmentAccount;
      let renamedFile = null;
      let newNameFile;
      let filesForAttachment = [];
      let filesToKeepFromSalesforce = [];
      for (let i = 0; i < gotFiles.length; i++) {
        for (let j = 0; j < files.length; j++) {
          if (files[j].fileValue === gotFiles[i]) {
            if (typeof files[j].Id !== "undefined") {
              filesToKeepFromSalesforce.push(files[j]);
            } else {
              newNameFile = await changeNameFile(gotFiles[i], files[j].name);
              renamedFile = await new File([files[j]], newNameFile, {
                type: files[j].type,
              });
              renamedFile.fileValue = gotFiles[i];
              filesForAttachment.push(renamedFile);
            }
          }
        }
      }
      if (hasOneOppyCompleted && props.opportunity !== null) {
        opportunity = props.opportunity;
      }
      setUploadProgress(10);
      await Salesforce.deleteAttachmentToSobject(
        filesToKeepFromSalesforce,
        account,
        opportunity
      ).catch((e) => {
        setErrors(true);
        setSnackUploadOpen(false);
        setButtonDisabled(false);
        setButtonAddFilesDisabled(false);
        setLoading(false);
      });
      setUploadProgress(20);
      updateAttachmentAccount = await Salesforce.addAttachmentToSobject(
        filesForAttachment,
        account.Id,
        opportunity.Id
      ).catch((e) => {
        setErrors(true);
        setSnackUploadOpen(false);
        setButtonDisabled(false);
        setButtonAddFilesDisabled(false);
        setLoading(false);
      });
      setUploadProgress(50);
      if (updateAttachmentAccount === undefined) {
        setErrors(true);
        setSnackUploadOpen(false);
        setButtonDisabled(false);
        setButtonAddFilesDisabled(false);
        setLoading(false);
      } else {
        let resultAttachmentArray = [];
        let uploadInProgress = 50;
        const startedBatchAttachments = updateAttachmentAccount.data;
        if (updateAttachmentAccount.success) {
          for (let k = 0; k < startedBatchAttachments.length; k++) {
            uploadInProgress =
              uploadInProgress + 30 / startedBatchAttachments.length;
            setUploadProgress(uploadInProgress);
            await Salesforce.checkAttachment(
              startedBatchAttachments[k].batchId,
              startedBatchAttachments[k].jobId
            ).catch((e) => {
              setErrors(true);
              setSnackUploadOpen(false);
              setButtonDisabled(false);
              setButtonAddFilesDisabled(false);
              setLoading(false);
            });
            let resultAttachmentAccount = await Salesforce.resultAttachment(
              startedBatchAttachments[k].batchId,
              startedBatchAttachments[k].jobId
            ).catch((e) => {
              setErrors(true);
              setSnackUploadOpen(false);
              setButtonDisabled(false);
              setButtonAddFilesDisabled(false);
              setLoading(false);
            });
            resultAttachmentArray.push(resultAttachmentAccount);
            if (k + 1 === startedBatchAttachments.length) {
              setUploadProgress(100);
            }
          }
        }
        let hasErrors;
        if (resultAttachmentArray.length > 0) {
          hasErrors = resultAttachmentArray.find((el) => el === false);
        }
        //check if all true in array
        if (hasErrors === undefined) {
          setSnackUploadOpen(false);
          if (gotFiles.length > 0) {
            setSnackSucessOpen(true);
          }
          console.log(filesForAttachment)
          if (filesForAttachment.find(el => el.fileValue === 'Pièce d\'identité')) {
            formikProps.setFieldValue("account.ID_Proof__c", true);
          }
          if (filesForAttachment.find(el => el.fileValue === 'Avis d\'attribution de bourse')) {
            formikProps.setFieldValue("account.Grant_Proof__c", true);
          }
          if ((filesForAttachment.find(el => el.fileValue === 'Bulletins de notes année N') || filesToKeepFromSalesforce.find(el => el.fileValue === 'Bulletins de notes année N')) && (filesForAttachment.find(el => el.fileValue === 'Bulletins de notes année N-1') || filesToKeepFromSalesforce.find(el => el.fileValue === 'Bulletins de notes année N-1'))) {
            formikProps.setFieldValue("account.Reports_Cards__c", true);
          }
          if (filesForAttachment.find(el => el.fileValue === 'Diplômes obtenus')) {
            formikProps.setFieldValue("account.Copy_of_Diploma__c", true);
          }
          if (filesForAttachment.find(el => el.fileValue === 'CV')) {
            formikProps.setFieldValue("opportunity.CV__c", true);
          }
          if (filesForAttachment.find(el => el.fileValue === 'Lettre de motivation')) {
            formikProps.setFieldValue("opportunity.MotivationLetter__c", true);
          }
          formikProps.setFieldValue('opportunity.StageName', STAGE_WAIT_ATTACHMENTS)
          // formikProps.setFieldValue("account.Reports_Cards__c", true);
          // formikProps.setFieldValue("account.Copy_of_Diploma__c", true);
          // formikProps.setFieldValue("account.ID_Proof__c", true);
          formikProps.submitForm();
          const supportingDocument = await Salesforce.getAttachmentFromSobject(account, opportunity)
          dispatch(updateSupportingDocument(supportingDocument.supportingDocument))
        }
        if (hasOneOppyCompleted && props.opportunity !== null) {
          props.handleClose();
        }
      }
    }
  };

  const changeNameFile = async (fileName, fileType) => {
    let englishName = mapSupportingDocument.get(fileName);
    let numberOfFile = filesBySupportingDocument.get(fileName);
    filesBySupportingDocument.set(fileName, numberOfFile + 1);
    setFilesBySupportingDocument(filesBySupportingDocument);
    let newNameFile;
    let extension = fileType.split('.')
    extension = extension[extension.length - 1]
    if (fileName === "CV" || fileName === "Lettre de motivation") {
      if (hasOneOppyCompleted && props.opportunity !== null) {
        opportunity = props.opportunity;
      }
      newNameFile = `${account.LastName}_${account.FirstName}_${
        opportunity.Id
      }_${englishName}_${numberOfFile + 1}.${extension}`
        .replace(/\s+/g, "")
        .toUpperCase();
    } else {
      newNameFile = `${account.LastName}_${account.FirstName}_${
        account.Id
      }_${englishName}_${numberOfFile + 1}.${extension}`
        .replace(/\s+/g, "")
        .toUpperCase();
    }
    return newNameFile;
  };

  const Input = styled("input")({
    display: "none",
  });

  const doneIconStyle = {
    color: "green",
    marginLeft: "10px",
    marginRight: "8px",
  };

  const deleteIconStyle = {
    cursor: "pointer",
    marginTop: "17px",
    marginLeft: "5px",
  };

  return (
    <>
      <Grid ml={2} mt={2} container spacing={2}>
        <Icon>file_present</Icon>
        <Typography gutterBottom ml={2} mb={5} variant="h5">
          {t('supportingDocument.title')}
        </Typography>
      </Grid>

      {errors && (
        <Box mt={2}>
          <Alert severity="error" variant="outlined">
            <AlertTitle>{t('supportingDocument.error')}</AlertTitle>
            {t('supportingDocument.error_detail')}.
          </Alert>
        </Box>
      )}
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Grid item sm={6} xs={12}>
          <h3>{t('supportingDocument.list')}</h3>
          <h4>Documents obligatoires</h4>
          {!isSecondCycleParis && (
            <ul>
              <li>
                {t('supportingDocument.identity_card')}
              </li>
              <li>
                {t('supportingDocument.grade_reports')}
              </li>
              <li>
                {t('supportingDocument.grade_reports_n1')}
              </li>
              <li>
                <em>{t('supportingDocument.diplomas')}{" "}</em>{t('supportingDocument.diplomas2')}{" "}
              </li>
              {opportunity.Division__c !== 'ISG MSC et MBA' && (
                <li><em>{t('supportingDocument.grant')}</em>, {t('supportingDocument.grant2')}{" "}</li>
              )}
            </ul>
          )}
          {isSecondCycleParis && (
            <ul>
              <li>
                {t('supportingDocument.identity_card_2nd')}
              </li>
              <li>
                {t('supportingDocument.grade_reports')}
              </li>
              <li>
                {t('supportingDocument.grade_reports_n1')}
              </li>
              <li>
                {t('supportingDocument.diplomas_2nd')}{" "}
              </li>
              <li>{t('supportingDocument.cv')}{" "}</li>
              <li>{t('supportingDocument.motivation_letter')}{" "}</li>
              {opportunity.Division__c === 'ISG BS France' && opportunity.SessionCampus__c === 'Paris' && (
                <li><em>{t('supportingDocument.grant')}</em>, {t('supportingDocument.grant2')}{" "}</li>
              )}
            </ul>
          )}
          <Alert color="warning" sx={{ mb: 2 }}>
            {!isSecondCycleParis && (
              <><strong>IMPORTANT</strong> : le dépôt de ces documents n'est pas requis pour pouvoir soumettre votre candidature. Néanmoins, ils devront être impérativement transmis à l'ISG avant la convocation devant un jury, sans quoi la candidature sera considérée comme abandonnée.</>
            )}
            {isSecondCycleParis && (
              <><strong>IMPORTANT</strong> : le dépôt de ces documents est obligatoire pour pouvoir soumettre votre candidature.</>
            )}
          </Alert>
          {!isSecondCycleParis && (
            <>
              <h4>Documents optionnels</h4>
              <ul>
                <li>{t('supportingDocument.cv')}{" "}</li>
                <li>{t('supportingDocument.motivation_letter')}{" "}</li>
              </ul>
            </>
          )}

        </Grid>
        <Grid item sm={6} xs={12} mb={2}>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item sm={6} xs={12}>
              <FormControl component="fieldset">
                <FormLabel sx={{ marginBottom: "10px" }} component="legend">
                  {t('supportingDocument.choice')}
                </FormLabel>
                <InputLabel id="demo-simple-select-label">
                  {t('supportingDocument.attachments')}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedFile}
                  label={t('supportingDocument.attachments')}
                  onChange={handleChange}
                >
                  {filesToGet.length > 0 &&
                    filesToGet.map((fileName, ind) => {
                      return (
                        <MenuItem key={ind} value={fileName}>
                          {" "}
                          {fileName}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <FieldArray name="SupportingDocument__c">
        {({ form, ...fieldArrayHelpers }) => {
          const onChangeFileValue = (event) => {
            event.preventDefault();
            setButtonDisabled(false);
            let filesList = files;
            let filesToAdd = [];
            for (let i = 0; i < event.target.files.length; i++) {
              event.target.files[i].fileValue = selectedFile;
              filesList.push(event.target.files[i]);
              filesToAdd.push(event.target.files[i]);
            }
            let supportingDocumentToAdd = {
              supportingDocument: selectedFile,
              files: filesToAdd,
            };
            let filesToGetList = filesToGet;
            let gotFilesList = gotFiles;
            let index = filesToGetList.indexOf(selectedFile);
            filesToGetList.splice(index, 1);
            gotFilesList.push(selectedFile);
            // setFilesToGet(filesToGetList.sort());
            setGotFiles(gotFilesList);
            setFilesValue(filesList);
            if (filesToGetList.length === 0) {
              setSelectedFile(filesToGetList);
            } else {
              setSelectedFile(filesToGetList[0]);
            }
            fieldArrayHelpers.push(supportingDocumentToAdd);
          };

          const removeFile = (e, fileIndex, value) => {
            e.preventDefault();
            if (value === 'Pièce d\'identité') {
              formikProps.setFieldValue("account.ID_Proof__c", false, false);
            }
            if (value === 'Avis d\'attribution de bourse') {
              formikProps.setFieldValue("account.Grant_Proof__c", false, false);
            }
            if (value === 'Bulletins de notes année N' || value === 'Bulletins de notes année N-1') {
              formikProps.setFieldValue("account.Reports_Cards__c", false, false);
            }
            if (value === 'Diplômes obtenus') {
              formikProps.setFieldValue("account.Copy_of_Diploma__c", false, false);
            }
            if (value === "Avis d'attribution de bourse") {
              formikProps.setFieldValue("account.Grant_Proof__c", false, false);
            }
            if (value === "CV") {
              formikProps.setFieldValue("opportunity.CV__c", false, false);
            }
            if (value === "Lettre de motivation") {
              formikProps.setFieldValue(
                "opportunity.MotivationLetter__c",
                false,
                false
              );
            }
            let filesList = files;
            let filesToGetList = filesToGet;
            let gotFilesList = gotFiles;
            let indexAttachmentsToRemove = [];
            for (let i = 0; i < filesList.length; i++) {
              if (filesList[i].fileValue === value) {
                indexAttachmentsToRemove.push(i);
              }
            }
            indexAttachmentsToRemove.sort(function (a, b) {
              return b - a;
            });
            for (let j = 0; j < indexAttachmentsToRemove.length; j++) {
              filesList.splice(indexAttachmentsToRemove[j], 1);
            }
            let index = gotFilesList.indexOf(value);
            gotFilesList.splice(index, 1);
            filesToGetList.push(value);
            setFilesToGet(filesToGetList.sort());
            setGotFiles(gotFilesList);
            setFilesValue(filesList);
            setSelectedFile(filesToGetList[filesToGetList.length - 1]);
            fieldArrayHelpers.remove(fileIndex);
            if (filesList.length === 0) {
              // setButtonDisabled(true);
            }
          };
          return (
            <>
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                }}
              >
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*,.pdf"
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={onChangeFileValue}
                    disabled={buttonAddFilesDisabled}
                  />
                  {filesToGet.length !== 0 && (
                    <Tooltip
                      title={t('supportingDocument.select_files')}
                      placement="bottom-end"
                    >
                      <Button
                        sx={{ marginLeft: "10px" }}
                        variant="contained"
                        disabled={buttonAddFilesDisabled}
                        component="span"
                      >
                        {t('supportingDocument.add_attachment')}
                      </Button>
                    </Tooltip>
                  )}
                </label>
              </Grid>
              <Grid container>
                <Typography
                  sx={{ mt: 4, mb: 2, width: "100%", textAlign: "center" }}
                  variant="h6"
                  component="div"
                >
                  {t('supportingDocument.attachments_added')}
                </Typography>
                <List dense={true} xs={12}>
                  {form.values.SupportingDocument__c &&
                    form.values.SupportingDocument__c.map((formFile, index) => {
                      return (
                        <ListItem
                          key={formFile.supportingDocument}
                          sx={{ mr: 2 }}
                        >
                          <Icon style={doneIconStyle}>done</Icon>
                          <ListItemText
                            key={`${formFile.supportingDocument} ${index}`}
                            sx={
                              matches
                                ? { width: "100%" }
                                : { width: "150px", overflow: "hidden" }
                            }
                            primary={formFile.supportingDocument}
                            secondary={files.map((file) => {
                              return (
                                <>
                                  {file.fileValue ===
                                    formFile.supportingDocument && (
                                    <span key={file.name}>
                                      {file.name}
                                      <br />
                                    </span>
                                  )}
                                </>
                              );
                            })}
                          />
                          {(!hasOneOppyCompleted ||
                            (hasOneOppyCompleted &&
                              (formFile.supportingDocument === "CV" ||
                                formFile.supportingDocument ===
                                  "Lettre de motivation"))) && (
                            <ListItemIcon>
                              <p></p>
                              {!loading && (
                                <Icon
                                  style={deleteIconStyle}
                                  onClick={(e) =>
                                    removeFile(
                                      e,
                                      index,
                                      formFile.supportingDocument
                                    )
                                  }
                                >
                                  delete
                                </Icon>
                              )}
                            </ListItemIcon>
                          )}
                        </ListItem>
                      );
                    })}
                </List>
              </Grid>
            </>
          );
        }}
      </FieldArray>
      {fieldArrayError.length > 0 && (
        <Box mt={2}>
          <Alert severity="error" variant="outlined">
            <AlertTitle>{t('supportingDocument.resolve_error')} :</AlertTitle>
            <ul>
              {fieldArrayError.map((errorObject, id) => {
                return (
                  <>
                    {typeof errorObject.name !== "undefined" ? (
                      <li key={id}>
                        {errorObject.error} {t('supportingDocument.for_item')} : {errorObject.name}
                      </li>
                    ) : (
                      <li key={id}>{errorObject.error}</li>
                    )}
                    <p>
                      {t('supportingDocument.contact')} :{" "}
                      <a
                        href="mailto:admission@isg.fr"
                        style={{ color: "black" }}
                      >
                        admission@isg.fr
                      </a>
                    </p>
                  </>
                );
              })}
            </ul>
          </Alert>
        </Box>
      )}
      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        <div className={classes.buttons}>
          <div
            style={{ display: "flex", justifyContent: "end" }}
            className={classes.wrapper}
          >
            <Button
              variant="contained"
              disabled={buttonDisabled}
              onClick={uploadFiles}
            >
              {!isSecondCycleParis && formikProps.values.SupportingDocument__c.length === 0 ? t('next') : t('supportingDocument.upload_files')}
            </Button>
            {loading && (
              <CircularProgress
                className={classes.buttonProgress}
                size={24}
                style={{ top: "20%" }}
              />
            )}
            {/* {(!isSecondCycleParis && formikProps.values.SupportingDocument__c.length === 0) && (
              <Tooltip
                title={`Vous pourrez uploader vos documents à tout moment après avoir cliqué sur ce bouton`}
                placement="bottom-end"
              >
                <Button
                  onClick={abortUpload}
                  disabled={loading}
                  sx={{ marginLeft: "10px" }}
                  variant="contained"
                  component="span"
                >
                  {t('next')}
                </Button>
            </Tooltip>
            )} */}
            {loading && (
              <CircularProgress
                className={classes.buttonProgress}
                size={24}
                style={{ top: "20%" }}
              />
            )}
          </div>
        </div>
      </Box>
      <Box sx={{ display: { xs: "block", sm: "none" } }}>
        <div className={classes.buttons}>
          <div
            style={{ display: "flex", justifyContent: "end" }}
            className={classes.wrapper}
          >
            <Button
              variant="contained"
              disabled={buttonDisabled}
              onClick={uploadFiles}
            >
              {!isSecondCycleParis && formikProps.values.SupportingDocument__c.length === 0 ? t('next') : t('supportingDocument.upload_files')}
            </Button>
            {loading && (
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            )}
          </div>
        </div>
      </Box>
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        onClose={handleCloseSnackUpload}
        open={snackUploadOpen}
      >
        <Alert elevation={6} onClose={handleCloseSnackUpload} severity="info">
          {t('supportingDocument.upload_files_loading')}...
          <LinearProgressWithLabel value={uploadProgress} />
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        autoHideDuration={5000}
        onClose={handleCloseSnackSucess}
        open={snackSucessOpen}
      >
        <Alert
          elevation={6}
          onClose={handleCloseSnackSucess}
          severity="success"
        >
          {t('supportingDocument.upload_success')}
        </Alert>
      </Snackbar>
    </>
  );
};

export default SupportingDocument;
