import * as Yup from "yup"
import moment from "moment"
import ionisConnectFormModel from "./ionisConnectFormModel"

const {
  formField: {
    academicLevel,
    birthdate,
    cgu,
    email,
    firstName,
    lastName,
    nationality,
    password,
    passwordConfirm
  }
} = ionisConnectFormModel

const validationSchema =
  Yup.object().shape({
    [academicLevel.name]: Yup.string().required(`${academicLevel.requiredErrorMsg}`),
    [birthdate.name]: Yup.date()
    .transform((value, rawValue) => {
      let correctDate = moment(rawValue, ["yyyy-mm-dd"]).toDate();
      return correctDate;
    })
    .min(
      moment(new Date(1900, 1, 1)).toDate(),
      "L'année de naissance doit être au minimum de 1900"
    )
    .required(`${birthdate.requiredErrorMsg}`),
    [cgu.name]: Yup.bool().oneOf([true], cgu.requiredErrorMsg),
    [email.name]: Yup.string().email(`${email.invalidEmail}`).max(80, `${email.maxLengthErrorMsg}`).required(`${email.requiredErrorMsg}`),
    // .test('testing-quote', `${email.invalidQuote}`, (value, context) => {
    //   return value.indexOf(`'`) === -1
    // }),
    [firstName.name]: Yup.string().trim().max(40, `${firstName.maxLengthErrorMsg}`).required(`${firstName.requiredErrorMsg}`),
    [lastName.name]: Yup.string().trim().max(80, `${lastName.maxLengthErrorMsg}`).required(`${lastName.requiredErrorMsg}`),
    // [nationality.name]: Yup.string().required(`${nationality.requiredErrorMsg}`),
    [password.name]: Yup.string().trim().min(8, password.invalidErrorMsg).required(`${password.requiredErrorMsg}`),
    [passwordConfirm.name]: Yup.string().trim().min(8, passwordConfirm.invalidErrorMsg).required(`${passwordConfirm.requiredErrorMsg}`).oneOf([Yup.ref('password'), null], `${passwordConfirm.passwordDontMatch}`),
  })

export default validationSchema
