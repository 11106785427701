import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { Alert, AlertTitle } from "@mui/material";
import { Form, Formik } from "formik";
import { CheckboxField, InputField } from "../../FormFields";
import validationSchemaParcoursup from "../FormModel/validationSchemaParcoursup";
import ionisConnectFormModel from "../FormModel/ionisConnectFormModel";
import formInitialValues from "../FormModel/formInitialValues";
import useStyles from "../styles";
import { useSelector, useDispatch } from "react-redux";
import Salesforce from "../../../services/Salesforce";
import { updateIonisConnect } from "../../../Store/salesforceSlice";
import { useEffect, useState } from "react";
// import Cookie from "js-cookie"
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next"
import { Language } from "../../Buttons"

const RegistrationParcoursup = () => {
  const [criticalErrors, setCriticalErrors] = useState(false);
  const classes = useStyles();
  const { formId, formField } = ionisConnectFormModel;
  const {
    cgu,
    email,
    password,
    passwordConfirm,
  } = formField;

  const history = useHistory();
  const recordTypeIdAccount = useSelector(
    (state) => state.salesforce.recordTypeAccount
  );
  const division = useSelector((state) => state.salesforce.division);
  const dispatch = useDispatch();
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(updateIonisConnect(false));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const _handleSubmit = async (values, actions) => {
    const user = await Salesforce.checkUser(values.email, division).catch(
      (e) => {
        setCriticalErrors(true);
        actions.setSubmitting(false);
      }
    );
    if (!user.exist) {
      // Création du compte
      const createUser = await Salesforce.createUser(
        values,
        recordTypeIdAccount
      ).catch((e) => {
        setCriticalErrors(true);
        actions.setSubmitting(false);
      });
      actions.setStatus({ userCreated: createUser });
    } else {
      actions.setStatus({ userExist: true });
    }
    actions.setSubmitting(false);
  };

  const switchToLogin = () => {
    history.push("/connexion");
  };

  return (
    <div className={classes.registration}>
      {/* <Box className={classes.registration_language}>
        <Language />
      </Box> */}
      <Typography align="center" className={classes.registration_h2} gutterBottom variant="h4">
        Création de votre profil
      </Typography>
      <Formik
        initialValues={formInitialValues}
        validationSchema={validationSchemaParcoursup}
        onSubmit={_handleSubmit}
      >
        {({ isSubmitting, status, values }) => (
        <Form id={formId}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <InputField fullWidth label={t(email.name)} name={email.name} type="email" />
            </Grid>
            <Grid item xs={12}>
              <InputField fullWidth label={t(password.name)} name={password.name} type="password" />
            </Grid>
            <Grid item xs={12}>
              <InputField fullWidth label={t(passwordConfirm.name)} name={passwordConfirm.name} type="password" />
            </Grid>
            <Grid item xs={12}>
              <CheckboxField label={<div>En cochant cette case, je reconnais avoir pris connaissance des <Link className={classes.link} to={{ pathname: 'https://www.isg.fr/mentions-legales' }} tabIndex={-1} target="_blank">Conditions Générales d'Utilisation</Link> et je les accepte.</div>} name={cgu.name} />
              {/* <CheckboxField label={<div>J'autorise l'ISG à m'adresser des informations concernant ses événements et processus d'admissions.</div>} name={optIn.name} /> */}
            </Grid>
          </Grid>
          <div className={classes.buttons}>
            {/* <Button className={classes.button} color="primary" onClick={switchToLogin} tabIndex={-1} variant="outlined">Se connecter</Button> */}
            <div className={classes.wrapper}>
              <Button className={classes.button} color="primary" disabled={isSubmitting || (status && status.userCreated)} type="submit" variant="contained">
                S'inscrire
              </Button>
              {isSubmitting && (
                <CircularProgress className={classes.buttonProgress} size={24} />
              )}
            </div>
          </div>
          { status && status.userCreated && <div><Box mt={2} mb={2}><Alert severity="warning" variant="outlined">Un e-mail de confirmation a été envoyé à l'adresse <strong>{values.email}</strong>.<br />Veuillez cliquer sur le lien de confirmation qui se trouve dans cet e-mail afin de finaliser la création de votre compte.</Alert></Box>

              <Button className={classes.button} color="primary" onClick={switchToLogin} variant="contained">J'ai validé mon adresse e-mail</Button>
            </div>
          }
          { status && status.userExist && <div><Box mt={2} mb={2}><Alert severity="warning" variant="outlined">Un compte est déjà associé à cette adresse e-mail.</Alert></Box>

              <Button className={classes.button} color="primary" onClick={switchToLogin} variant="contained">Se connecter</Button>

            </div>
          }
          {criticalErrors && <Alert severity="error" sx={{ mt: 2 }} variant="outlined">
            <AlertTitle>Une erreur est survenue</AlertTitle>
            Une erreur est survenue lors de la soumission du formulaire. Veuillez réessayer ou retenter un peu plus tard.
          </Alert>}
        </Form>
        )}
      </Formik>
    </div>
  )
}

export default RegistrationParcoursup

