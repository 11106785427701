import HelpIcon from '@mui/icons-material/Help';
import { Box, Button, Drawer, IconButton, Link, Tooltip, Typography } from '@mui/material'
import { useState } from 'react'
import { useSelector } from 'react-redux'

const Help = () => {

  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const documents = useSelector(state => state.salesforce.documents)
  const isRegistration = useSelector(state => state.salesforce.hasOneOppyRegisteredFinished || state.salesforce.hasOneOppyToBeRegistered)

  console.log(documents)

  return (
    <>
      {isRegistration && (
        <>
          <Tooltip title="Besoin d'aide ?">
            <IconButton onClick={handleOpen}>
              <HelpIcon />
            </IconButton>
          </Tooltip>
          <Box
            onKeyDown={handleClose}
          >
            <Drawer
              anchor="right"
              open={open}
              onClose={handleClose}
            >
              <Box sx={{ padding: 3 }}>
                <Typography mb={2} variant="h5">Besoin d'aide ?</Typography>
                {documents.map((document, index) => (
                  <Box sx={{ mb: 2 }}>
                    <Button component={Link} key={index} href={`${process.env.REACT_APP_SF_CONNECT_API}/api/getDocument?documentId=${document.Id}`} target="_blank" variant="contained">{document.Name}</Button>
                  </Box>
                ))}
              </Box>
            </Drawer>
          </Box>
        </>
      )}
    </>
  )
}

export default Help
