import { Grid } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { InputField, SelectField } from "applicant-components/components/FormFields"

const Validation = (props) => {
  const {
    formField: {
      account: {
        Description,

      },
      opportunity: {
        First_information_source__c
      }
    }
  } = props

  const optionsFirstInformationSource = useSelector(state => state.salesforce.firstInformationSource)
  const { t } = useTranslation()

  return (
    <>
      <Grid container spacing={3}>
        <Grid item sm={6} xs={12}>
          <InputField fullWidth label={t(Description.name)} multiline name={Description.name} minRows={3} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <SelectField data={optionsFirstInformationSource} fullWidth label={t(First_information_source__c.name)} name={First_information_source__c.name} />
        </Grid>
      </Grid>
    </>
  )
}

export default Validation
