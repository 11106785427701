import { Checkbox, FormControl, FormHelperText, InputLabel, ListItemText, MenuItem, OutlinedInput, Select } from "@mui/material"
import { useFormikContext } from "formik"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

const TrackMinor = ({ formField }) => {

  const [minors, setMinors] = useState([])
  const [minorName, setMinorName] = useState([]);
  const [error, setError] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const specialities = useSelector(state => state.salesforce.specialities)
  const { t } = useTranslation()

  const {
    opportunity: {
      TrackMinor__c
    }
  } = formField

  const {
    values : {
      application: {
        trackMajor,
        trackMinor,
        trackMinorDisabled
      },
      opportunity
    }
  } = useFormikContext()

  useEffect(() => {
    if (opportunity !== null && opportunity.Track__c !== undefined && trackMajor !== null) {
      // On recherche les majors
      const majorsList = specialities.filter(el => el.Session__c === opportunity.Session__c && el.Speciality__c !== trackMajor)
      if (majorsList.length > 0) {
        setMinors(majorsList.map(el => el.Speciality__r.Name))
      } else {
        setMinors([])
      }
    }
  }, [opportunity, trackMajor])

  useEffect(() => {
    if (trackMinor !== undefined && trackMinor.length === 0) {
      setMinorName([])
    } else {
      setMinorName(trackMinor)
    }
  }, [trackMinor])

  const formikProps = useFormikContext()

  useEffect(() => {
    if (formikProps.errors.application !== undefined) {
      setError(true)
      setErrorMsg(formikProps.errors.application.trackMinor)
    } else {
      setError(false)
      setErrorMsg('')
    }
  }, [formikProps.errors])

  const handleChange = (event) => {
    const {
      target: { value },
    } = event
    setMinorName(
      // On autofill we get a the stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    // formikProps.setFieldValue('opportunity.TrackM__c', value.join(', '))
    formikProps.setFieldValue('application.trackMinor', value)
    let specialitiesId = []
    for (let i = 0; i < value.length; i ++) {
      const speciality = specialities.find(el => el.Speciality__r.Name === value[i])
      if (speciality !== undefined) {
        specialitiesId.push(speciality.Speciality__c)
      }
    }
    formikProps.setFieldValue('application.trackMinorId', specialitiesId)
  }

  return (
    <>
      <FormControl fullWidth error={error}>
        <InputLabel id="demo-multiple-checkbox-label">{t(TrackMinor__c.name)}</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          disabled={trackMinorDisabled}
          id="demo-multiple-checkbox"
          label={t(TrackMinor__c.name)}
          multiple
          name="application.trackMinor"
          value={minorName}
          onChange={handleChange}
          input={<OutlinedInput label={t(TrackMinor__c.name)} />}
          renderValue={(selected) => selected.join(', ')}
          // MenuProps={MenuProps}
        >
          {minors.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={minorName.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
        {error && <FormHelperText>{errorMsg}</FormHelperText>}
      </FormControl>
    </>
  )
}

export default TrackMinor
