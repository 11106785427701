import { at } from "lodash"
import { useField } from "formik"
import { Checkbox, FormControl, FormControlLabel, FormHelperText } from "@mui/material"

const CheckboxField = (props) => {
  const { disabled, label } = props
  const [field, meta, helper] = useField(props)
  const { value } = field
  const { setValue } = helper
  const [touched, error] = at(meta, 'touched', 'error')

  function _onChange(e) {
    setValue(e.target.checked)
  }

  return (
    <FormControl error={touched && error && true}>
      <FormControlLabel
        aria-label={field.name}
        checked={field.checked}
        control={<Checkbox checked={value} {...field} onChange={_onChange} />}
        disabled={disabled}
        label={label}
        value={field.checked}
      >
      </FormControlLabel>
      {touched && error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}

export default CheckboxField
