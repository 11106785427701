import { Alert } from "@mui/material"
import { CheckboxField } from "applicant-components/components/FormFields"
import { STAGE_COURS, STAGE_QUALIFICATION } from "applicant-components/services/StageNameOpportunity"
import { updateCampaignId } from "applicant-components/Store/salesforceSlice"
import { useFormikContext } from "formik"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"

const JPC = () => {

  const dispatch = useDispatch()
  const campaigns = useSelector(state => state.salesforce.campaigns)
  const paymentType = useSelector(state => state.salesforce.paymentType)
  const { SessionCampus__c: campus } = useSelector(state => state.salesforce.opportunity)
  const [jpcAvailable, setJPCAvailable] = useState(true)
  const [campusJPC, setCampusJPC] = useState('')
  const { t } = useTranslation()

  const formikProps = useFormikContext()

  const {
    values : {
      opportunity,
      JPCWished
    }
  } = useFormikContext()

  useEffect(() => {
    const campusHasJpc = campaigns.find(el => el.Campus__c === campus && el.Status === 'In Progress')
    const CampusJPC = campaigns.filter(el => el.Status === 'In Progress').map(el => el.Campus__c).sort().join(', ')
    setJPCAvailable(campusHasJpc !== undefined)
    setCampusJPC(CampusJPC)
    if (campusHasJpc !== undefined) {
      dispatch(updateCampaignId(campusHasJpc.Id))
    }
  }, [])

  useEffect(() => {
    if (JPCWished) {
      formikProps.setFieldValue('opportunity.StageName', STAGE_QUALIFICATION)
    } else {
      formikProps.setFieldValue('opportunity.StageName', STAGE_COURS)
    }
  }, [opportunity, JPCWished])

  return (
    <>
      <p>{t('jpc.text', { campus: campus })}.<br /> <br />

      {t('jpc.text2')} :</p>

      <ul>
        <li>{t('jpc.list')}</li>
        <li>{t('jpc.list2')}</li>
        <li>{t('jpc.list3')}</li>
      </ul>
      {paymentType === 'JPC' &&
        <CheckboxField disabled={!jpcAvailable} label={t('jpc_wished')} name="JPCWished" />
      }
      {paymentType === 'JPC' && jpcAvailable === false && <Alert elevation={6} severity="warning">
        {campusJPC !== '' && <span>{t('application.jpc_closed')} {t('application.jpc_closed2')} <strong>{campus}</strong>. {t('application.jpc_closed3')} : <strong>{campusJPC}</strong>.</span>}
        {campusJPC === '' && <span>{t('application.jpc_closed')}.</span>}{' '}

        <a href="https://www.isg.fr/contacts-ecole" rel="noreferrer" style={{ 'color' : 'inherit' }} target="_blank">Cliquez ici</a> pour les coordonnées des campus.
      </Alert>}
    </>
  )
}

export default JPC
