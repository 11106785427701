import { Alert } from "@mui/material";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import Salesforce from "../../services/Salesforce";
import CheckboxListField from "../FormFields/CheckboxListField";

const SpecialisedEducation = (props) => {
  const [optionsSpecialites, setOptionsSpecialites] = useState([]);
  const {
    formField: { SpecialisedEducation: SpecialisedEducationField },
  } = props;

  // const optionsSpecialites = Salesforce.getSpecialisedEducationValues()

  const {
    values: {
      account: { Section },
      SpecialisedEducation: SpecialisedEducationValues,
    },
  } = useFormikContext();

  const formikProps = useFormikContext();

  async function removeSpecialitiesFields() {
    formikProps.setFieldValue("account.TermGEnsSpeArts__c", false, false);
    formikProps.setFieldValue(
      "account.TermGEnsSpeHumaniteLitteraturePhilo__c",
      false,
      false
    );
    formikProps.setFieldValue(
      "account.TermGEnsSpeLittelanguesCultAntiq__c",
      false,
      false
    );
    formikProps.setFieldValue(
      "account.TermGEnsSpeSciencesInge__c",
      false,
      false
    );
    formikProps.setFieldValue(
      "account.TermGEnsSpeLanguesLitteCultEtr__c",
      false,
      false
    );
    formikProps.setFieldValue("account.TermGEnsSpeMaths__c", false, false);
    formikProps.setFieldValue(
      "account.TermGEnsSpePhysiqueChimie__c",
      false,
      false
    );
    formikProps.setFieldValue(
      "account.TermGEnsSpeSciencesEco__c",
      false,
      false
    );
    formikProps.setFieldValue(
      "account.TermGEnsSpeHistGeoSciencesPo__c",
      false,
      false
    );
    formikProps.setFieldValue(
      "account.TermGEnsSpeNumSciencesInfo__c",
      false,
      false
    );
    formikProps.setFieldValue("account.TermGEnsSpeSVT__c", false, false);
    formikProps.setFieldValue(
      "account.TermGEnsSpeBioEcologie__c",
      false,
      false
    );
  }

  useEffect(() => {
    async function getSpecialisedEducationValues() {
      let response;
      try {
        response = await Salesforce.getSpecialisedEducationValues();
        console.log("response", response);
        setOptionsSpecialites(response);
      } catch (error) {
        console.log("errorerror", error);
        setOptionsSpecialites(error);
      }
    }
    getSpecialisedEducationValues();
  }, []);

  useEffect(() => {
    if (
      Section !== undefined &&
      Section !== "Générale" &&
      SpecialisedEducationValues.length > 0
    ) {
      // @todo Pourquoi quand on revient en arrière Section === undefined ?
      formikProps.setFieldValue("SpecialisedEducation", [], false);
    }
    console.log("SpecialisedEducationValues :", SpecialisedEducationValues);
    console.log("optionsSpecialites :", optionsSpecialites);
  }, [Section]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    removeSpecialitiesFields();
    if (SpecialisedEducationValues.length > 0) {
      for (let i = 0; i < SpecialisedEducationValues.length; i++) {
        const specialite = SpecialisedEducationValues[i];

        let fieldValue = "";
        switch (specialite) {
          case optionsSpecialites[0]:
            fieldValue = "account.TermGEnsSpeArts__c";
            break;
          case optionsSpecialites[1]:
            fieldValue = "account.TermGEnsSpeHumaniteLitteraturePhilo__c";
            break;
          case optionsSpecialites[2]:
            fieldValue = "account.TermGEnsSpeLittelanguesCultAntiq__c";
            break;
          case optionsSpecialites[3]:
            fieldValue = "account.TermGEnsSpeSciencesInge__c";
            break;
          case optionsSpecialites[4]:
            fieldValue = "account.TermGEnsSpeLanguesLitteCultEtr__c";
            break;
          case optionsSpecialites[5]:
            fieldValue = "account.TermGEnsSpeMaths__c";
            break;
          case optionsSpecialites[6]:
            fieldValue = "account.TermGEnsSpePhysiqueChimie__c";
            break;
          case optionsSpecialites[7]:
            fieldValue = "account.TermGEnsSpeSciencesEco__c";
            break;
          case optionsSpecialites[8]:
            fieldValue = "account.TermGEnsSpeHistGeoSciencesPo__c";
            break;
          case optionsSpecialites[9]:
            fieldValue = "account.TermGEnsSpeNumSciencesInfo__c";
            break;
          case optionsSpecialites[10]:
            fieldValue = "account.TermGEnsSpeSVT__c";
            break;
          case optionsSpecialites[11]:
            fieldValue = "account.TermGEnsSpeBioEcologie__c";
            break;
          default:
        }
        formikProps.setFieldValue(fieldValue, true);
      }
    }
  }, [SpecialisedEducationValues]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {typeof optionsSpecialites === "string" && (
        <Alert elevation={3} severity="error">
          Une erreur est survenue lors du chargement du formulaire. Veuillez
          rafraichir la page ou revenir dans quelques instants.
        </Alert>
      )}
      {typeof optionsSpecialites === "object" && (
        <CheckboxListField
          grid={3}
          options={optionsSpecialites}
          label={SpecialisedEducationField.label}
          name={SpecialisedEducationField.name}
        />
      )}
    </>
  );
};

export default SpecialisedEducation;
