import { Alert, Button, Drawer, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import { Box } from "@mui/material";
import SupportingDocument from "components/Portal/Forms/SupportingDocument";
import { Form, Formik } from "formik";
import validationSchema from "components/Portal/FormModel/validationSchema";
import portalFormModel from "components/Portal/FormModel/portalFormModel";
import Salesforce from "applicant-components/services/Salesforce";
import {
  updateOpportunities,
  updateOpportunity,
  updateSupportingDocument,
} from "applicant-components/Store/salesforceSlice";
import { STAGE_COMPLETE } from "applicant-components/services/StageNameOpportunity";
import { useTranslation } from "react-i18next"

const NewSupportingDocument = (props) => {
  const dispatch = useDispatch();
  const { formId } = portalFormModel;
  const [open, setOpen] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [updatedAttachements, setUpdatedAttachements] = useState(false);
  const handleOpen = () => setOpen(true);
  const [criticalErrors, setCriticalErrors] = useState(false);
  const { t } = useTranslation()

  const account = useSelector((state) => state.salesforce.account);
  const SupportingDocument__c = useSelector(
    (state) => state.salesforce.supportingDocument
  );
  // const actualOpportunity = useSelector(state => state.salesforce.opportunity)
  const validationSchemaSupportingDocuments = validationSchema[4];

  useEffect(() => {
    async function updateAttachments() {
      try {
        const supportingDocument = await Salesforce.getAttachmentFromSobject(
          account,
          props.opportunity
        );
        if (supportingDocument.supportingDocument.length > 0) {
          dispatch(
            updateSupportingDocument(supportingDocument.supportingDocument)
          );
          setUpdatedAttachements(true);
        }
      } catch (error) {
        setCriticalErrors(true);
        setSnackOpen(true);
      }
    }
    updateAttachments();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackOpen(false);
  };
  const handleClose = async () => {
    setOpen(false);
    try {
      const supportingDocument = await Salesforce.getAttachmentFromSobject(
        account,
        props.opportunity
      );
      if (supportingDocument.supportingDocument.length > 0) {
        dispatch(
          updateSupportingDocument(supportingDocument.supportingDocument)
        );
      }
      setUpdatedAttachements(true);
    } catch (error) {
      setCriticalErrors(true);
      setSnackOpen(true);
    }
  };

  async function _handleSubmit(values, action) {
    const opportunityUpdate = {
      AccountId: account.Id,
      Id: props.opportunity.Id,
      CV__c: values.opportunity.CV__c,
      MotivationLetter__c: values.opportunity.MotivationLetter__c,
      StageName: STAGE_COMPLETE,
    };
    const opportunityUpdated = await Salesforce.updateOpportunity(
      opportunityUpdate
    );
    if (opportunityUpdated.success) {
      setSnackOpen(true);
      handleClose();
      setTimeout(() => {
        dispatch(updateOpportunity(opportunityUpdated.opportunity));
        dispatch(updateOpportunities(opportunityUpdated.opportunities));
      }, 5000);
    }
  }

  return (
    <>
      <Button onClick={handleOpen} startIcon={<AddIcon />} variant="contained">
        {t('newSupportingDocument.button_add_document')}
      </Button>
      <Box
        // onClick={handleClose}
        onKeyDown={handleClose}
      >
        <Drawer anchor="right" open={open} onClose={handleClose}>
          {updatedAttachements && (
            <Box sx={{ padding: 3 }}>
              <Formik
                initialValues={{ account, opportunity: props.opportunity, SupportingDocument__c }}
                validationSchema={validationSchemaSupportingDocuments}
                onSubmit={_handleSubmit}
              >
                {({ isSubmitting }) => (
                  <Form id={formId}>
                    <SupportingDocument
                      opportunity={props.opportunity}
                      handleClose={handleClose}
                    />
                    <Button
                      color="primary"
                      onClick={handleClose}
                      variant="outlined"
                    >
                      {t('newSupportingDocument.cancel')}
                    </Button>
                  </Form>
                )}
              </Formik>
            </Box>
          )}
        </Drawer>
      </Box>
      {!criticalErrors && (
        <Snackbar
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
          autoHideDuration={5000}
          onClose={handleCloseSnack}
          open={snackOpen}
        >
          <Alert elevation={6} onClose={handleCloseSnack} severity="success">
            {t('newSupportingDocument.application_completed')}.
          </Alert>
        </Snackbar>
      )}
      {criticalErrors && (
        <Snackbar
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
          autoHideDuration={5000}
          onClose={handleCloseSnack}
          open={snackOpen}
        >
          <Alert elevation={6} onClose={handleCloseSnack} severity="error">
            {t('error_form2')}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default NewSupportingDocument;
