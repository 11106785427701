import { FormHelperText, Grid, Typography } from "@mui/material"
import { CheckboxField, DatePickerField, InputField, SelectField } from "applicant-components/components/FormFields"
import { useFormikContext } from "formik"
import RadioButtonExample from "applicant-components/components/FormFields/RadioButtonExample"
import { useSelector } from "react-redux"
import { useEffect, useState } from "react"
import SpecialisedEducation from "applicant-components/components/SpecialisedEducation"
import { useTranslation } from "react-i18next"
import PhoneField from "applicant-components/components/FormFields/PhoneField"

const PersonalInformations = (props) => {

  const {
    formField: {
      account: {
        AcademicLevel__pc,
        BACYear__pc,
        Country_of_birth__pc,
        FirstName,
        Grant_Student__c,
        LastName,
        Nationalite__c,
        Nationalite_2__c,
        Native_Language__pc,
        PersonBirthdate,
        PersonMailingCity,
        PersonMailingCountry,
        PersonMailingPostalCode,
        PersonMailingStreet,
        PersonMobilePhone,
        Phone,
        Salutation,
        Section__pc,
        SectionOfBAC__pc,
      }
    }
  } = props

  const optionsAcademicLevel = useSelector(state => state.salesforce.academicLevels)
  const optionsCountries = useSelector(state => state.salesforce.countries)
  const optionsLanguages = useSelector(state => state.salesforce.languages)
  const optionsNationalities = useSelector(state => state.salesforce.nationalities)
  const optionsSectionOfBac = useSelector(state => state.salesforce.sectionOfBac)
  const opportunitiesLength = useSelector(state => state.salesforce.opportunities).length
  const accountUser = useSelector(state => state.salesforce.account.CreatedBy.Name)
  const sections = useSelector(state => state.salesforce.sections)

  const [optionsSections, setOptionsSections] = useState([])
  const [optionsNationalities2, setOptionsNationalities] = useState([])
  // const [maxYear, setMaxYear] = useState(null) // @todo on l'utilise si on peut à l'ouverture du datepicker avoir une valeur par défaut
  const {
    values : {
      account: {
        AcademicLevel__pc: AcademicLevel,
        BACYear__pc: BACYear,
        Nationalite__c: Nationalite,
        Nationalite_2__c: Nationalite2,
        Section__pc: Section,
        SectionOfBAC__pc: SectionOfBAC
      }
    }
  } = useFormikContext()

  const formikProps = useFormikContext() // @todo dépend si on peut vider le champs section prorprement
  const { t } = useTranslation()

  useEffect(() => {
    const nationalities = [...optionsNationalities]
    nationalities.unshift({ label: `--- ${t('personalInformations.nationality')} ---`, value: undefined })
    setOptionsNationalities(nationalities)
  }, [optionsNationalities])

  useEffect(() => {
    // formikProps.setFieldValue('account.Section__pc', '') // @todo trouver une solution pour reset proprement
    const options = sections.find(el => el.academicLevel === AcademicLevel)
    if (options !== undefined) {
      setOptionsSections(options.sections)
    }
  }, [AcademicLevel, sections])

  // useEffect(() => {
  //   const date = moment()
  //   const year = parseISO(date.add('year', -15).format('YYYY-MM-DD'))
  //   setMaxYear(year)
  // }, [])

  useEffect(() => {
    if (Nationalite2 === undefined) {
      formikProps.setFieldValue('account.Nationalite_2__c', '', false)
    }
  }, [Nationalite2]) // eslint-disable-line react-hooks/exhaustive-deps

  // const salutations = [
  //   { key: 'Madame', value: 'Mme'},
  //   { key: 'Monsieur', value: 'M.' }
  // ]

  return (
    <div>
      <Typography gutterBottom mb={5} variant="h5">
        {t('personalInformations.title')}
      </Typography>
      <Grid container spacing={3}>
        <Grid item sm={12}>
          <RadioButtonExample
            label={t(Salutation.name)}
            name={Salutation.name}
            options={t('civility')}
            required
            row
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          {/* <FastField component={InputExample} label={t(FirstName.name)} name={FirstName.name} /> */}
          <InputField fullWidth label={t(FirstName.name)} name={FirstName.name} required />
        </Grid>
        <Grid item sm={6} xs={12}>
          <InputField fullWidth label={t(LastName.name)} name={LastName.name} required />
          {/* <FastField component={InputExample} label={t(LastName.name)} name={LastName.name} /> */}
        </Grid>
        <Grid item sm={6} xs={12}>
          <DatePickerField fullWidth label={t(PersonBirthdate.name)} datemax={new Date()} name={PersonBirthdate.name} required />
        </Grid>
        <Grid item sm={6} xs={12}>
          <SelectField data={optionsCountries} fullWidth label={t(Country_of_birth__pc.name)} name={Country_of_birth__pc.name} required />
        </Grid>
        <Grid item sm={6} xs={12}>
          <SelectField data={optionsNationalities} disableValue={Nationalite2} fullWidth label={t(Nationalite__c.name)} name={Nationalite__c.name} required />
        </Grid>
        <Grid item sm={6} xs={12}>
          <SelectField data={optionsNationalities2} disableValue={Nationalite} displayEmpty={true} fullWidth label={t(Nationalite_2__c.name)} name={Nationalite_2__c.name} />
        </Grid>
        <Grid item xs={12}>
          <FormHelperText sx={{ textAlign: 'center' }}>Vous devez remplir obligatoirement un numéro de téléphone</FormHelperText>
        </Grid>
        <Grid item sm={6} xs={12}>
          {/* <InputField fullWidth label={t(Phone.name)} name={Phone.name} /> */}
          <PhoneField label={t(Phone.name)} name={Phone.name} />
          {/* <FastField component={InputExample} label={t(Phone.name)} name={Phone.name} /> */}
        </Grid>
        <Grid item sm={6} xs={12}>
          {/* <InputField fullWidth label={t(PersonMobilePhone.name)} name={PersonMobilePhone.name} /> */}
          <PhoneField label={t(PersonMobilePhone.name)} name={PersonMobilePhone.name} />
          {/* <FastField component={InputExample} label={t(PersonMobilePhone.name)} name={PersonMobilePhone.name} /> */}
        </Grid>
        <Grid item sm={6} xs={12}>
          <SelectField data={optionsAcademicLevel} disabled={opportunitiesLength > 0 && accountUser !== 'formulaires Utilisateur invité du site'} fullWidth label={t(AcademicLevel__pc.name)} name={AcademicLevel__pc.name} required />
        </Grid>
        <Grid item sm={6} xs={12}>
          <SelectField data={optionsSections} fullWidth label={t(Section__pc.name)} name={Section__pc.name} required />
        </Grid>
        {AcademicLevel === 'Terminale (BAC)' && Section === 'Générale' && <Grid item sm={12} xs={12}>
          <SpecialisedEducation formField={props.formField} />
        </Grid>}
        {AcademicLevel !== 'Terminale (BAC)' && <Grid item sm={6} xs={12}>
          <InputField fullWidth InputProps={{ inputProps: { min: 1970, max: 2021 } }} label={t(BACYear__pc.name)} name={BACYear__pc.name} type="number" required />
          {/* <FastField component={InputExample} InputProps={{ inputProps: { min: 1970, max: 2021 } }} label={BACYear__pc.label} name={BACYear__pc.name} type="number" /> */}
        </Grid>}
        {AcademicLevel !== 'Terminale (BAC)' && <Grid item sm={6} xs={12}>
          <SelectField data={optionsSectionOfBac} fullWidth label={t(SectionOfBAC__pc.name)} name={SectionOfBAC__pc.name} required />
        </Grid>}
        {SectionOfBAC === 'Générale' && BACYear >= 2021 && <Grid item sm={12} xs={12}>
          <SpecialisedEducation formField={props.formField} />
        </Grid>}
        <Grid item sm={6} xs={12}>
          <SelectField data={optionsLanguages} fullWidth label={t(Native_Language__pc.name)} name={Native_Language__pc.name} required />
        </Grid>
      </Grid>
      <Typography gutterBottom variant="h6" sx={{ mb: 4, mt: 4 }}>
        {t('personalInformations.address')}
      </Typography>
      <Grid container spacing={3}>
        <Grid item sm={6} xs={12}>
          <SelectField data={optionsCountries} fullWidth label={t(PersonMailingCountry.name)} name={PersonMailingCountry.name} required />
        </Grid>
        <Grid item sm={6} xs={12}>
          <InputField fullWidth label={t(PersonMailingStreet.name)} minRows={2} multiline name={PersonMailingStreet.name} required />
          {/* <FastField component={InputExample} label={PersonMailingStreet.label} minRows={2} multiline name={PersonMailingStreet.name} /> */}
        </Grid>
        <Grid item sm={6} xs={12}>
          <InputField fullWidth label={t(PersonMailingPostalCode.name)} name={PersonMailingPostalCode.name} required />
          {/* <FastField component={InputExample} label={PersonMailingPostalCode.label} name={PersonMailingPostalCode.name} /> */}
        </Grid>
        <Grid item sm={6} xs={12}>
          <InputField fullWidth label={t(PersonMailingCity.name)} name={PersonMailingCity.name} required />
          {/* <FastField component={InputExample} label={PersonMailingCity.label} name={PersonMailingCity.name} /> */}
        </Grid>
        {/* <CapAdresse formField={props.formField} /> */}
      </Grid>
      <Grid container spacing={3} sx={{ mt: 2 }}>
        <Grid item xs={12}>
          <CheckboxField label={t(Grant_Student__c.name)} name={Grant_Student__c.name} />
        </Grid>
      </Grid>
    </div>
  )
}

export default PersonalInformations
