import { Accordion, AccordionDetails, AccordionSummary, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import ExpandMoreIcon  from "@mui/icons-material/ExpandMore"
import { useSelector } from "react-redux"
import moment from "moment"
import { useTranslation } from "react-i18next"

const Course = () => {

  const academicInformation = useSelector(state => state.salesforce.academicInformation)
  const workExperience = useSelector(state => state.salesforce.workExperience)
  const { t } = useTranslation()

  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          // aria-controls="panel1a-content"
          aria-controls={`panel2a-content`}
          // id="panel1a-header"
          id={`panel2a-header`}
        >
          {t('academic.title')}
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="h5">{t('academic.dilomas')}</Typography>
          {academicInformation.length === 0 && <p>{t('academic.no_diploma')}</p>}
          {academicInformation.length > 0 && <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('AcademicInformation__c.EstablishmentName__c')}</TableCell>
                  <TableCell>{t('AcademicInformation__c.Location__c')}</TableCell>
                  <TableCell>{t('AcademicInformation__c.YearOfGraduation__c')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {academicInformation.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.EstablishmentName__c}</TableCell>
                    <TableCell>{row.Location__c}</TableCell>
                    <TableCell>{row.YearOfGraduation__c}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>}

          <Typography variant="h5" sx={{ mt: 2 }}>{t('academic.experience')}</Typography>
          {workExperience.length === 0 && <p>{t('academic.no_experience')}</p>}
          {workExperience.length > 0 && <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('WorkExperience__c.Employer__c')}</TableCell>
                  <TableCell>{t('WorkExperience__c.Location__c')}</TableCell>
                  <TableCell>{t('WorkExperience__c.Position__c')}</TableCell>
                  <TableCell>{t('WorkExperience__c.StartDate__c')}</TableCell>
                  <TableCell>{t('WorkExperience__c.EndDate__c')}</TableCell>
                  <TableCell>{t('WorkExperience__c.Description__c')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {workExperience.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.Employer__c}</TableCell>
                    <TableCell>{row.Location__c}</TableCell>
                    <TableCell>{row.Position__c}</TableCell>
                    <TableCell>{moment(row.StartDate__c).format('MM/YY')}</TableCell>
                    <TableCell>{moment(row.EndDate__c).format('MM/YY')}</TableCell>
                    <TableCell>{row.Description__c}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>}
        </AccordionDetails>
      </Accordion>
    </>
  )
}

export default Course
