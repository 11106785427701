import {
  Alert,
  Button,
  CircularProgress,
  Drawer,
  Snackbar,
  Typography,
} from "@mui/material";
import { Box } from "@mui/material";
import { Formik, Form } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Salesforce from "applicant-components/services/Salesforce";
import {
  STAGE_COMPLETE,
  STAGE_COURS,
  STAGE_QUALIFICATION,
  STAGE_WAIT_ATTACHMENTS,
} from "applicant-components/services/StageNameOpportunity";
import portalFormModel from "components/Portal/FormModel/portalFormModel";
import validationSchema from "components/Portal/FormModel/validationSchema";
import Application from "components/Portal/Forms/Application";
import useStyles from "components/Portal/styles";
import {
  updateCampaignMember,
  updateOpportunity,
  updateOpportunities,
  updateSupportingDocument,
} from "applicant-components/Store/salesforceSlice";
import AddIcon from "@mui/icons-material/Add";
import SupportingDocument from "components/Portal/Forms/SupportingDocument";
import Payment from "components/Portal/Forms/Payment";
import { useTranslation } from "react-i18next"

const NewApplication = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [wantJPC, setWantJPC] = useState(false);
  const [opportunityCreated, setOpportunityCreated] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setOpportunityCreated(false);
  };
  const [criticalErrors, setCriticalErrors] = useState(false);
  const { formId, formField } = portalFormModel;
  const account = useSelector((state) => state.salesforce.account);
  const SupportingDocument__c = useSelector(
    (state) => state.salesforce.supportingDocument
  );
  const hasPayed =
    useSelector((state) => state.salesforce.statusPayment) === "OK";
  const recordTypeId = useSelector(
    (state) => state.salesforce.recordTypeOpportunity
  );
  const campaignId = useSelector((state) => state.salesforce.campaignId);
  const actualOpportunity = useSelector(
    (state) => state.salesforce.opportunity
  );
  const paymentType = useSelector((state) => state.salesforce.paymentType);
  const campaignMember = useSelector(
    (state) => state.salesforce.campaignMember
  );
  const currentValidationSchema = validationSchema[1];
  const validationSchemaSupportingDocuments = validationSchema[4];
  const [documents, setDocuments] = useState([]);
  const { t } = useTranslation()

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackOpen(false);
  };

  const opportunity = {
    AccountId: account.Id,
    CloseDate: Date.now(),
    OnlineApplication__c: true,
    RecordTypeId: recordTypeId,
    Session__c: "",
    StageName: hasPayed ? STAGE_COURS : STAGE_QUALIFICATION,
  };

  useEffect(() => {
    const docs = SupportingDocument__c.filter(
      (x) =>
        x.supportingDocument !== "CV" &&
        x.supportingDocument !== "Lettre de motivation"
    );
    setDocuments(docs);
  }, [SupportingDocument__c]);

  async function _handleSubmit(values, action) {
    const opportunity = values.opportunity;
    const JPCWished = values.JPCWished;
    setWantJPC(JPCWished);
    const opportunityUpdated = await Salesforce.updateOpportunity(
      opportunity,
      JPCWished
    ).catch((e) => {
      setCriticalErrors(true);
      setSnackOpen(true);
    });
    if (opportunityUpdated.success) {
      dispatch(updateOpportunity(opportunityUpdated.opportunity));
      dispatch(updateOpportunities(opportunityUpdated.opportunities));
      if (
        JPCWished &&
        campaignMember &&
        Object.keys(campaignMember).length === 0 &&
        Object.getPrototypeOf(campaignMember) === Object.prototype
      ) {
        const campaignMemberCreated = await Salesforce.createCampaignMember(
          account.PersonContactId,
          campaignId
        ).catch((e) => {
          setCriticalErrors(true);
          setSnackOpen(true);
        });
        dispatch(updateCampaignMember(campaignMemberCreated));
      }
      setOpportunityCreated(true);
    }
  }

  async function _handleSumitPJ(values, action) {
    const opportunityUpdate = {
      AccountId: account.Id,
      Id: actualOpportunity.Id,
      // StageName: actualOpportunity.Tous_documents_valides__c ? STAGE_COMPLETE : STAGE_WAIT_ATTACHMENTS,
    };
    const opportunityMerged = { ...values.opportunity, ...opportunityUpdate };
    const opportunityUpdated = await Salesforce.updateOpportunity(
      opportunityMerged
    );
    if (opportunityUpdated.success) {
      // dispatch(updateOpportunity(opportunityUpdated.opportunity));
      // dispatch(updateOpportunities(opportunityUpdated.opportunities));
      // On met à jour le statut (dégueulasse mais pas le choix ?)
      const opportunityUpdate2 = {
        AccountId: account.Id,
        Id: actualOpportunity.Id,
        StageName: opportunityUpdated.opportunity.Tous_documents_valides__c ? STAGE_COMPLETE : STAGE_WAIT_ATTACHMENTS
      }
      // const opportunityMerged2 = { ...values.opportunity, ...opportunityUpdate2 };
      const opportunityUpdated2 = await Salesforce.updateOpportunity(
        opportunityUpdate2
      )
      if (opportunityUpdated2.success) {
        dispatch(updateOpportunity(opportunityUpdated2.opportunity));
        dispatch(updateOpportunities(opportunityUpdated2.opportunities));
        handleClose();
        setSnackOpen(true);
      }
    }
  }

  const handleCloseSupportingDocument = async () => {
    setOpen(false);
    try {
      const supportingDocument = await Salesforce.getAttachmentFromSobject(
        account,
        actualOpportunity
      );
      if (supportingDocument.supportingDocument.length > 0) {
        dispatch(
          updateSupportingDocument(supportingDocument.supportingDocument)
        );
      }
    } catch (error) {
      setCriticalErrors(true);
      setSnackOpen(true);
    }
  };

  // const paymentCancel = () => setWantJPC(false)

  const application = {
    campusDisabled: true,
    optionsCampus: [],
    optionsSessions: [],
    optionsYears: [],
    sessionsDisabled: true,
    trackMajor: [],
    trackMajorDisabled: true,
    yearDisabled: true,
  };

  return (
    <>
      <Button onClick={handleOpen} startIcon={<AddIcon />} variant="contained">
        {t('newApplication.button_new_application')}
      </Button>
      <Box
        // onClick={handleClose}
        onKeyDown={handleClose}
      >
        <Drawer anchor="right" open={open} onClose={handleClose}>
          {!opportunityCreated && (
            <Box sx={{ padding: 3 }}>
              <Typography gutterBottom variant="h5">
              {t('newApplication.title')}
              </Typography>
              <Formik
                initialValues={{ JPCWished: false, application, opportunity }}
                validationSchema={currentValidationSchema}
                onSubmit={_handleSubmit}
              >
                {({ isSubmitting }) => (
                  <Form id={formId}>
                    <Application formField={formField} />
                    <div className={classes.buttons}>
                      <div className={classes.wrapper}>
                        <Button
                          className={classes.button}
                          color="primary"
                          disabled={isSubmitting}
                          type="submit"
                          variant="contained"
                        >
                          {t('newApplication.button_confirm')}
                        </Button>
                        {isSubmitting && (
                          <CircularProgress
                            className={classes.buttonProgress}
                            size={24}
                          />
                        )}
                        <Button
                          className={classes.button}
                          color="primary"
                          onClick={handleClose}
                          variant="outlined"
                        >
                          {t('newApplication.cancel')}
                        </Button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </Box>
          )}
          {opportunityCreated &&
            (wantJPC || paymentType === "Candidature") &&
            !hasPayed && (
              <Box sx={{ padding: 3 }}>
                <Formik initialValues={{ account }}>
                  <Form id={formId}>
                    <Payment formField={formField} />
                  </Form>
                </Formik>
              </Box>
            )}
          {opportunityCreated &&
            (hasPayed || (!wantJPC && paymentType === "JPC")) && (
              <Box sx={{ padding: 3 }}>
                <Formik
                  initialValues={{ account, opportunity: actualOpportunity, SupportingDocument__c: documents }}
                  validationSchema={validationSchemaSupportingDocuments}
                  onSubmit={_handleSumitPJ}
                >
                  {({ isSubmitting }) => (
                    <Form id={formId}>
                      <SupportingDocument
                        opportunity={actualOpportunity}
                        handleClose={handleCloseSupportingDocument}
                        formField={formField}
                      />
                      <Button
                        className={classes.button}
                        color="primary"
                        onClick={handleClose}
                        variant="outlined"
                      >
                        {t('newApplication.cancel')}
                      </Button>
                    </Form>
                  )}
                </Formik>
              </Box>
            )}
        </Drawer>
      </Box>
      {criticalErrors && (
        <Snackbar
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
          autoHideDuration={5000}
          onClose={handleCloseSnack}
          open={snackOpen}
        >
          <Alert elevation={6} onClose={handleCloseSnack} severity="error">
            {t('error_form2')}
          </Alert>
        </Snackbar>
      )}
      {!criticalErrors && (
        <Snackbar
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
          autoHideDuration={5000}
          onClose={handleCloseSnack}
          open={snackOpen}
        >
          <Alert elevation={6} onClose={handleCloseSnack} severity="success">
            {t('newApplication.application_completed')}.
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default NewApplication;
