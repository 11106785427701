import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from './App';
import store from 'applicant-components/Store/Store'
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import { persistStore } from "redux-persist"
import reportWebVitals from './reportWebVitals';

Sentry.init({
  dsn: "https://0083c36deaad4a9185d050b527148870@o118665.ingest.sentry.io/5947497",
  integrations: [new Integrations.BrowserTracing()],
  normalizeDepth: 10,
  environment: process.env.REACT_APP_ENVIRONMENT || 'local development',
  release: '1.4',
  beforeSend: (event, eventHint) => {
    // On supprime certaines infos inutiles car on est limité lorsqu'on envoi des données à Sentry
    const redux = event.contexts['redux.state']
    if (redux) {
      delete redux.buttons
      delete redux.counter
      delete redux.capAdresse
      delete redux.salesforce.countries
      delete redux.salesforce.nationalities
      delete redux.salesforce.sessions
      delete redux.theme
      delete redux['_persist']
    }
    return event
  },

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

let persistor = persistStore(store)

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
