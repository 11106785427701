import { Alert, Snackbar } from "@mui/material"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

const IsOnline = () => {
  const [isOnline, setIsOnline] = useState(true)
  const { t } = useTranslation()

  const InternetErrMessagenger = () => {
    const isOnline = navigator.onLine === true
    setIsOnline(isOnline)
  }

  useEffect(() => {
    const interval = setInterval(InternetErrMessagenger, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <>
      {/* {isOnline === true ? 'Tu es en ligne' : 'Tu es hors ligne'} */}
      <Snackbar anchorOrigin={{ horizontal: 'center', vertical: 'top' }} open={!isOnline}>
        <Alert severity="error">{t('isOnline')}.</Alert>
      </Snackbar>
    </>
  )
}

export default IsOnline
