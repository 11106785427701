import ionisConnectFormModel from "./ionisConnectFormModel"
import Cookie from "js-cookie"

const cookies = Cookie.get('ISG_infos')
const cookiesParse = cookies !== undefined ? JSON.parse(Cookie.get('ISG_infos')) : undefined
const academicLevelValue = cookiesParse !== undefined ? cookiesParse.academicLevel : ''
const nationalityValue = cookiesParse !== undefined ? cookiesParse.nationality : ''

const {
  formField: {
    academicLevel,
    birthdate,
    cgu,
    currentPassword,
    email,
    firstName,
    lastName,
    optIn,
    nationality,
    password,
    passwordConfirm
  }
} = ionisConnectFormModel

const formInitialValues = {
  [academicLevel.name]: academicLevelValue,
  [birthdate.name]: '',
  [cgu.name]: false,
  [currentPassword.name]: '',
  [email.name]: '',
  [firstName.name]: '',
  [lastName.name]: '',
  [optIn.name]: false,
  [nationality.name]: nationalityValue,
  [password.name]: '',
  [passwordConfirm.name]: ''
}

export default formInitialValues
