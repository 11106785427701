import { Alert, Button, Link, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { Trans, useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

const CandidaturePaiementKO = () => {

  const history = useHistory()
  const { t } = useTranslation()

  const goToForm = () => {
    history.push('/')
  }

  return (
    <>
      <Box
        alt="ISG"
        // className={classes.title}
        component="img"
        src="/logo-isg.jpg"
        sx={{ display: 'block', marginTop: 2, marginBottom: 2, mx: 'auto', height: 112, width: 112 }}
      >
      </Box>
      <Alert elevation={3} severity="error" sx={{ mb: 2 }}>
        <Trans i18nKey="page_payment_ko.alert">
          Echec de paiement pour votre candidature à l'ISG.
        </Trans>
      </Alert>

      <Typography sx={{ mb: 2 }} variant="body1">
        <Trans i18nKey="page_payment_ko.text">
          Vous avez souhaité candidater à l'un des programmes de l'ISG sur notre site Internet.
          Cependant, votre candidature n'a pas pu être complétée car <strong>nous n'avons pas reçu votre paiement en ligne</strong>. Cela a peut-être été causé par un problème technique ou une difficulté de paiement avec la carte utilisée (carte non-acceptée par le système ou fonds insuffisants sur le compte).
        </Trans>
      </Typography>
      <Typography sx={{ mb: 2 }} variant="body1">
        <Trans i18nKey="page_payment_ko.text2">
          Afin de finaliser votre candidature, veuillez-vous reconnecter à notre site avec votre email et votre mot de passe.
          Vous pourrez y reprendre la procédure de candidature en vous munissant d’une carte de paiement valide : Carte Bleue, Visa, Eurocard / Mastercard, American Express ou Diners (pas nécessairement la votre).
        </Trans>
      </Typography>
      <Typography variant="body1">
        <Trans i18nKey="page.contact">
          Pour tout renseignement complémentaire, n'hésitez pas à <Link href="https://www.isg.fr/contacts-ecole" rel="noopener" target="_blank">contacter</Link> notre Service des Admissions.
        </Trans>
      </Typography>

      <Button onClick={goToForm} sx={{ mt: 2 }} variant="contained">{t('page.back')}</Button>
    </>
  )
}

export default CandidaturePaiementKO
