import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import useStyles from "../Portal/styles"

const ConfirmationNoJPC = ({ openPopup, closeJPC }) => {

  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { t } = useTranslation()

  const handleClose = () => {
    setOpen(false)
    closeJPC(false)
  }

  const handleNoJPC = () => {
    setIsSubmitting(true)
    closeJPC(true)
  }

  useEffect(() => {
    if (openPopup !== undefined) {
      setOpen(openPopup)
    }
  }, [openPopup])

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth="md"
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">
          {t('popinjpc.title')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {t('popinjpc.text')} <strong>{t('popinjpc.button_cancel')}</strong>, {t('popinjpc.text2')}.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className={classes.button} onClick={handleClose}>{t('popinjpc.button_pay')}</Button>
          <div className={classes.buttons}>
            <div className={classes.wrapper}>
              <Button className={classes.button} disabled={isSubmitting} onClick={handleNoJPC} variant="contained">
              {t('popinjpc.button_cancel')}
              </Button>
              {isSubmitting && (
                <CircularProgress className={classes.buttonProgress} size={24} />
              )}
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ConfirmationNoJPC
