import { Alert, Box, Button, Link, Typography } from "@mui/material"
import { Trans, useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

const CandidaturePaiementOK = () => {

  const history = useHistory()
  const { t } = useTranslation()

  const goToForm = () => {
    history.push('/')
  }

  return (
    <>
      <Box
        alt="ISG"
        // className={classes.title}
        component="img"
        src="/logo-isg.jpg"
        sx={{ display: 'block', marginTop: 2, marginBottom: 2, mx: 'auto', height: 112, width: 112 }}
      >
      </Box>
      <Alert elevation={3} severity="success" sx={{ mb: 2 }}>
        <Trans i18nKey="page_payment_ok.alert">
        Votre paiement a bien été pris en compte.
        </Trans>
      </Alert>

      <Typography sx={{ mb: 2 }} variant="body1">
        <Trans i18nKey="page_payment_ok.text">
          Nous vous confirmons que nous avons bien reçu votre paiement CB.
        </Trans>
      </Typography>
      <Typography sx={{ mb: 2 }} variant="body1">
        <Trans i18nKey="page_payment_ok.text2">
          Vous devez finaliser votre candidature pour qu’elle soit prise en compte.<br />
          Pour des raisons de sécurité il vous sera demandé de vous identifier à nouveau après avoir cliqué sur <strong>JE FINALISE MA CANDIDATURE</strong>
        </Trans>
      </Typography>
      <Typography variant="body1">
        <Trans i18nKey="page.contact">
          Pour tout renseignement complémentaire, n'hésitez pas à <Link href="https://www.isg.fr/contacts-ecole" rel="noopener" target="_blank">contacter</Link> notre Service des Admissions.
        </Trans>
      </Typography>

      <Button onClick={goToForm} sx={{ mt: 2 }} variant="contained">Je finalise ma candidature</Button>
    </>
  )
}

export default CandidaturePaiementOK
