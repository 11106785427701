const currentYear = new Date().getFullYear()

const registrationFormModel = {
  formId: 'registrationForm',
  formField: {
    account: {
      BACHonour__pc: {
        label: '',
        name: 'account.BACHonour__pc',
      },
      BACYear__pc: {
        label: 'Année d\'obtention du BAC*',
        name: 'account.BACYear__pc',
        requiredErrorMsg: 'L\'année du BAC est requise',
        minMaxErrorMsg: 'L\'année du BAC doit être comprise entre 1970 et ' + currentYear
      },
      BIC__c: {
        label: '',
        name: 'account.BIC__c',
        requiredErrorMsg: 'Le BIC est requis',
        minLengthErrorMsg: 'Le BIC doit contenir au minimum ${min} caractères', // eslint-disable-line no-template-curly-in-string
        maxLengthErrorMsg: 'Le BIC doit contenir au maximum ${max} caractères' // eslint-disable-line no-template-curly-in-string
      },
      Birthplace__pc: {
        label: '',
        name: 'account.Birthplace__pc',
        requiredErrorMsg: 'La ville de naissance est requise',
        maxLengthErrorMsg: 'La ville de naissance doit contenir au maximum ${max} caractères' // eslint-disable-line no-template-curly-in-string
      },
      CGV__c: {
        name: 'account.CGV__c'
      },
      Country_of_birth__pc: {
        label: 'Pays de naissance*',
        name: 'account.Country_of_birth__pc',
        requiredErrorMsg: 'Le pays de naissance est requis'
      },
      EmergencyDegreeOfRelationship__pc: {
        label: '',
        name: 'account.EmergencyDegreeOfRelationship__pc',
        requiredErrorMsg: `Le lien de parenté du contact d'urgence est requis`
      },
      EmergencyFirstName__pc: {
        label: '',
        name: 'account.EmergencyFirstName__pc',
        requiredErrorMsg: `Le prénom du contact d'urgence est requis`,
        maxLengthErrorMsg: 'Le prénom du contact d\'urgence doit contenir au maximum ${max} caractères' // eslint-disable-line no-template-curly-in-string
      },
      EmergencyLastName__pc: {
        label: '',
        name: 'account.EmergencyLastName__pc',
        requiredErrorMsg: `Le nom du contact d'urgence est requis`,
        maxLengthErrorMsg: 'Le nom du contact d\'urgence doit contenir au maximum ${max} caractères' // eslint-disable-line no-template-curly-in-string
      },
      EmergencyMail__pc: {
        label: '',
        name: 'account.EmergencyMail__pc',
        requiredErrorMsg: `L'e-mail du contact d'urgence est requis`,
        invalidEmail: `L'e-mail du contact d'urgence est invalide`,
        maxLengthErrorMsg: 'L\'email du contact d\'urgence doit contenir au maximum ${max} caractères' // eslint-disable-line no-template-curly-in-string
      },
      EmergencyMobile__pc: {
        label: '',
        name: 'account.EmergencyMobile__pc',
        requiredErrorMsg: `Le mobile du contact d'urgence est requis`,
      },
      EmergencyPhone__pc: {
        label: '',
        name: 'account.EmergencyPhone__pc'
      },
      EmergencyTitle__pc: {
        label: '',
        name: 'account.EmergencyTitle__pc',
        requiredErrorMsg: `La civilité du contact d'urgence est requise`,
      },
      FinancialRepDegreeOfRelationship__pc: {
        label: '',
        name: 'account.FinancialRepDegreeOfRelationship__pc',
        requiredErrorMsg: 'Le lien de parenté du responsable financier est requis'
      },
      FinancialRepresentativeCivility__pc: {
        label: '',
        name: 'account.FinancialRepresentativeCivility__pc',
        requiredErrorMsg: 'La civilité du responsable financier est requise'
      },
      FinancialRepresentativeFirstName__pc: {
        label: '',
        name: 'account.FinancialRepresentativeFirstName__pc',
        requiredErrorMsg: 'Le prénom du responsable financier est requis',
        maxLengthErrorMsg: 'Le prénom du responsable financier doit contenir au maximum ${max} caractères' // eslint-disable-line no-template-curly-in-string
      },
      FinancialRepresentativeHomeCity__pc: {
        label: '',
        name: 'account.FinancialRepresentativeHomeCity__pc',
        requiredErrorMsg: 'La ville du responsable financier est requise',
        maxLengthErrorMsg: 'La ville du responsable financier doit contenir au maximum ${max} caractères' // eslint-disable-line no-template-curly-in-string
      },
      FinancialRepresentativeHomeCountry__pc: {
        label: '',
        name: 'account.FinancialRepresentativeHomeCountry__pc',
        requiredErrorMsg: 'Le pays du responsable financier est requis'
      },
      FinancialRepresentativeHomeStreet__pc: {
        label: '',
        name: 'account.FinancialRepresentativeHomeStreet__pc',
        requiredErrorMsg: `L'adresse du responsable financier est requise`,
        maxLengthErrorMsg: 'L\'adresse du responsable financier doit contenir au maximum ${max} caractères' // eslint-disable-line no-template-curly-in-string
      },
      FinancialRepresentativeHomeZipCode__pc: {
        label: '',
        name: 'account.FinancialRepresentativeHomeZipCode__pc',
        requiredErrorMsg: 'Le code postal du responsable financier est requis',
        maxLengthErrorMsg: 'Le code postal du responsable financier doit contenir au maximum ${max} caractères' // eslint-disable-line no-template-curly-in-string
      },
      FinancialRepresentativeLastName__pc: {
        label: '',
        name: 'account.FinancialRepresentativeLastName__pc',
        requiredErrorMsg: 'Le nom du responsable financier est requis',
        maxLengthErrorMsg: 'Le nom du responsable financier doit contenir au maximum ${max} caractères' // eslint-disable-line no-template-curly-in-string
      },
      FinancialRepresentativeMail__pc: {
        label: '',
        name: 'account.FinancialRepresentativeMail__pc',
        requiredErrorMsg: `L'e-mail du responsable financier est requis`,
        invalidEmail: `L'e-mail du responsable financier est invalide`,
        maxLengthErrorMsg: 'L\'email du responsable financier doit contenir au maximum ${max} caractères' // eslint-disable-line no-template-curly-in-string
      },
      FinancialRepresentativeMobilePhone__pc: {
        label: '',
        name: 'account.FinancialRepresentativeMobilePhone__pc',
        requiredErrorMsg: 'Le mobile du responsable financier est requis',
        maxLengthErrorMsg: 'Le mobile du responsable financier doit contenir au maximum ${max} caractères' // eslint-disable-line no-template-curly-in-string
      },
      FinancialRepresentativePhone__pc: {
        label: '',
        name: 'account.FinancialRepresentativePhone__pc',
        maxLengthErrorMsg: 'Le téléphone du responsable financier doit contenir au maximum ${max} caractères' // eslint-disable-line no-template-curly-in-string
      },
      FinancialRepReceiveScholarInformations__pc: {
        label: '',
        name: 'account.FinancialRepReceiveScholarInformations__pc',
        requiredErrorMsg: 'Vous devez autoriser ou non si le responsable financier doit avoir accès au suivi de votre scolarité'
      },
      FirstName: {
        label: 'Prénom*',
        name: 'account.FirstName',
        requiredErrorMsg: 'Le prénom est requis',
        maxLengthErrorMsg: 'Le prénom doit contenir au maximum ${max} caractères' // eslint-disable-line no-template-curly-in-string
      },
      FirstName2__c: {
        label: '',
        name: 'account.FirstName2__c',
        maxLengthErrorMsg: 'Le prénom 2 doit contenir au maximum ${max} caractères' // eslint-disable-line no-template-curly-in-string
      },
      FirstName3__c: {
        label: '',
        name: 'account.FirstName3__c',
        maxLengthErrorMsg: 'Le prénom 3 doit contenir au maximum ${max} caractères' // eslint-disable-line no-template-curly-in-string
      },
      FirstnameBrotherSisterStudentIonis__pc: {
        label: '',
        name: 'account.FirstnameBrotherSisterStudentIonis__pc',
        requiredErrorMsg: 'Le prénom du frère ou de la soeur est requis',
        maxLengthErrorMsg: 'Le prénom du frère ou de la soeur doit contenir au maximum ${max} caractères' // eslint-disable-line no-template-curly-in-string
      },
      Grant_Student__c: {
        label: 'Je suis boursier de l\'éducation nationale',
        name: 'account.Grant_Student__c'
      },
      IBAN__c: {
        label: '',
        name: 'account.IBAN__c',
        requiredErrorMsg: `L'IBAN est requis`,
        minLengthErrorMsg: 'Le BIC doit contenir au minimum ${min} caractères', // eslint-disable-line no-template-curly-in-string
        maxLengthErrorMsg: 'Le BIC doit contenir au maximum ${max} caractères' // eslint-disable-line no-template-curly-in-string
      },
      INENumber__c: {
        label: '',
        name : 'account.INENumber__c',
        requiredErrorMsg: 'Le numéro INE est requis',
        minLengthErrorMsg: 'Le numéro INE doit contenir ${min} caractères', // eslint-disable-line no-template-curly-in-string
        maxLengthErrorMsg: 'Le numéro INE doit contenir ${max} caractères' // eslint-disable-line no-template-curly-in-string
      },
      Langue_1__c: {
        label: 'Langue vivante 1*',
        name: 'account.Langue_1__c',
        requiredErrorMsg: 'La langue vivante 1 est requise',
        notSameValue: 'Vous ne pouvez pas sélectionner deux langues vivantes identiques'
      },
      Langue_2__c: {
        label: 'Langue vivante 2',
        name: 'account.Langue_2__c',
        requiredErrorMsg: 'La langue vivante 2 est requise',
        notSameValue: 'Vous ne pouvez pas sélectionner deux langues vivantes identiques'
      },
      Langue_3__c: {
        label: 'Langue vivante 3',
        name: 'account.Langue_3__c',
        requiredErrorMsg: 'La langue vivante 3 est requise',
        notSameValue: 'Vous ne pouvez pas sélectionner deux langues vivantes identiques'
      },
      LastName: {
        label: 'Nom*',
        name: 'account.LastName',
        requiredErrorMsg: 'Le nom est requis',
        maxLengthErrorMsg: 'Le nom doit contenir au maximum ${max} caractères' // eslint-disable-line no-template-curly-in-string
      },
      LastnameBrotherSisterStudentIonis__c: {
        label: '',
        name: 'account.LastnameBrotherSisterStudentIonis__c',
        requiredErrorMsg: 'Le nom du frère ou de la soeur est requis',
        maxLengthErrorMsg: 'Le nom du frère ou de la soeur doit contenir au maximum ${max} caractères' // eslint-disable-line no-template-curly-in-string
      },
      MoreThan1FinancialRep__c: {
        label: '',
        name: 'account.MoreThan1FinancialRep__c'
      },
      Nationalite__c: {
        label: 'Nationalité*',
        name: 'account.Nationalite__c',
        requiredErrorMsg: 'La nationalité est requise'
      },
      Nationalite_2__c: {
        label: 'Autre nationalité',
        name: 'account.Nationalite_2__c'
      },
      Native_Language__pc: {
        label: 'Langue maternelle*',
        name: 'account.Native_Language__pc',
        requiredErrorMsg: 'La langue maternelle est requise'
      },
      Niveau_langue_1__c: {
        label: 'Niveau de langue*',
        name: 'account.Niveau_langue_1__c',
        requiredErrorMsg: 'Le niveau de langue est requis'
      },
      Niveau_langue_2__c: {
        label: 'Niveau de langue',
        name: 'account.Niveau_langue_2__c',
        requiredErrorMsg: 'Le niveau de langue est requis'
      },
      Niveau_Langue_3__c: {
        label: 'Niveau de langue',
        name: 'account.Niveau_Langue_3__c',
        requiredErrorMsg: 'Le niveau de langue est requis'
      },
      Parent1Address__pc: {
        label: '',
        name: 'account.Parent1Address__pc',
        requiredErrorMsg: `L'adresse du parent 1 est requise`,
        maxLengthErrorMsg: 'L\'adresse du parent 1 doit contenir au maximum ${max} caractères' // eslint-disable-line no-template-curly-in-string
      },
      Parent1City__pc: {
        label: '',
        name: 'account.Parent1City__pc',
        requiredErrorMsg: 'La ville du parent 1 est requise',
        maxLengthErrorMsg: 'La ville du parent 1 doit contenir au maximum ${max} caractères' // eslint-disable-line no-template-curly-in-string
      },
      Parent1Civility__pc: {
        label: '',
        name: 'account.Parent1Civility__pc',
        requiredErrorMsg: 'La civilité du parent 1 est requise'
      },
      Parent1Country__pc: {
        label: '',
        name: 'account.Parent1Country__pc',
        requiredErrorMsg: 'Le pays du parent 1 est requis'
      },
      Parent1CP__pc: {
        label: '',
        name: 'account.Parent1CP__pc',
        requiredErrorMsg: 'Le code postal du parent est requis',
        maxLengthErrorMsg: 'Le code postal du parent 1 doit contenir au maximum ${max} caractères' // eslint-disable-line no-template-curly-in-string
      },
      Parent1CSP__pc: {
        label: '',
        name: 'account.Parent1CSP__pc',
        requiredErrorMsg: 'La catégorie socio-professionnelle du parent 1 est requise'
      },
      Parent1DegreeOfRelationship__pc: {
        label: '',
        name: 'account.Parent1DegreeOfRelationship__pc',
        requiredErrorMsg: 'Le lien de parenté du parent 1 est requis'
      },
      Parent1Email__pc: {
        label: '',
        name: 'account.Parent1Email__pc',
        requiredErrorMsg: `L'e-mail du parent 1 est requis`,
        invalidEmail: `L'e-mail du parent 1 est invalide`,
        maxLengthErrorMsg: 'L\'email du parent 1 doit contenir au maximum ${max} caractères' // eslint-disable-line no-template-curly-in-string
      },
      Parent1Firstname__pc: {
        label: '',
        name: 'account.Parent1Firstname__pc',
        requiredErrorMsg: 'Le prénom du parent 1 est requis',
        maxLengthErrorMsg: 'Le prénom du parent 1 doit contenir au maximum ${max} caractères' // eslint-disable-line no-template-curly-in-string
      },
      Parent1Name__pc: {
        label: '',
        name: 'account.Parent1Name__pc',
        requiredErrorMsg: 'Le nom du parent 1 est requis',
        maxLengthErrorMsg: 'Le nom du parent 1 doit contenir au maximum ${max} caractères' // eslint-disable-line no-template-curly-in-string
      },
      Parent1MobilePhone__pc: {
        label: '',
        name: 'account.Parent1MobilePhone__pc',
        requiredErrorMsg: 'Le mobile du parent 1 est requis',
        maxLengthErrorMsg: 'Le mobile du parent 1 doit contenir au maximum ${max} caractères' // eslint-disable-line no-template-curly-in-string
      },
      Parent1Phone__pc: {
        label: '',
        name: 'account.Parent1Phone__pc',
        maxLengthErrorMsg: 'Le téléphone du parent 1 doit contenir au maximum ${max} caractères' // eslint-disable-line no-template-curly-in-string
      },
      Parent2Address__pc: {
        label: '',
        name: 'account.Parent2Address__pc',
        requiredErrorMsg: `L'adresse du parent 2 est requise`,
        maxLengthErrorMsg: 'L\'adresse du parent 2 doit contenir au maximum ${max} caractères' // eslint-disable-line no-template-curly-in-string
      },
      Parent2City__pc: {
        label: '',
        name: 'account.Parent2City__pc',
        requiredErrorMsg: 'La ville du parent 2 est requise',
        maxLengthErrorMsg: 'La ville du parent 2 doit contenir au maximum ${max} caractères' // eslint-disable-line no-template-curly-in-string
      },
      Parent2Civility__pc: {
        label: '',
        name: 'account.Parent2Civility__pc',
        requiredErrorMsg: 'La civilité du parent 2 est requise'
      },
      Parent2Country__pc: {
        label: '',
        name: 'account.Parent2Country__pc',
        requiredErrorMsg: 'Le pays du parent 2 est requis'
      },
      Parent2CP__pc: {
        label: '',
        name: 'account.Parent2CP__pc',
        requiredErrorMsg: 'Le code postal du parent est requis',
        maxLengthErrorMsg: 'Le code postal du parent 2 doit contenir au maximum ${max} caractères' // eslint-disable-line no-template-curly-in-string
      },
      Parent2CSP__pc: {
        label: '',
        name: 'account.Parent2CSP__pc',
        requiredErrorMsg: 'La catégorie socio-professionnelle du parent 2 est requise'
      },
      Parent2DegreeOfRelationship__pc: {
        label: '',
        name: 'account.Parent2DegreeOfRelationship__pc',
        requiredErrorMsg: 'Le lien de parenté du parent 2 est requis'
      },
      Parent2Email__pc: {
        label: '',
        name: 'account.Parent2Email__pc',
        invalidEmail: `L'e-mail du parent 2 est invalide`,
        requiredErrorMsg: `L'e-mail du parent 2 est requis`,
        maxLengthErrorMsg: 'L\'email du parent 2 doit contenir au maximum ${max} caractères' // eslint-disable-line no-template-curly-in-string
      },
      Parent2Firstname__pc: {
        label: '',
        name: 'account.Parent2Firstname__pc',
        requiredErrorMsg: 'Le prénom du parent 2 est requis',
        maxLengthErrorMsg: 'Le prénom du parent 2 doit contenir au maximum ${max} caractères' // eslint-disable-line no-template-curly-in-string
      },
      Parent2Name__pc: {
        label: '',
        name: 'account.Parent2Name__pc',
        requiredErrorMsg: 'Le nom du parent 2 est requis',
        maxLengthErrorMsg: 'Le nom du parent 2 doit contenir au maximum ${max} caractères' // eslint-disable-line no-template-curly-in-string
      },
      Parent2MobilePhone__pc: {
        label: '',
        name: 'account.Parent2MobilePhone__pc',
        requiredErrorMsg: 'Le mobile du parent 2 est requis',
        maxLengthErrorMsg: 'Le mobile du parent 1 doit contenir au maximum ${max} caractères' // eslint-disable-line no-template-curly-in-string
      },
      Parent2Phone__pc: {
        label: '',
        name: 'account.Parent2Phone__pc',
        maxLengthErrorMsg: 'Le téléphone du parent 2 doit contenir au maximum ${max} caractères' // eslint-disable-line no-template-curly-in-string
      },
      PersonBirthdate: {
        label: 'Date de naissance*',
        name: 'account.PersonBirthdate',
        requiredErrorMsg: 'La date de naissance est requise'
      },
      PersonMailingCity: {
        label: 'Ville*',
        name: 'account.PersonMailingCity',
        requiredErrorMsg: 'La ville est requise',
        maxLengthErrorMsg: 'La ville doit contenir au maximum ${max} caractères' // eslint-disable-line no-template-curly-in-string
      },
      PersonMailingCountry: {
        label: 'Pays de résidence*',
        name: 'account.PersonMailingCountry',
        requiredErrorMsg: 'Le pays de résidence est requis'
      },
      PersonMailingPostalCode: {
        label: 'Code postal*',
        name: 'account.PersonMailingPostalCode',
        requiredErrorMsg: 'Le code postal est requis',
        maxLengthErrorMsg: 'Le code postal doit contenir au maximum ${max} caractères' // eslint-disable-line no-template-curly-in-string
      },
      PersonMailingStreet: {
        label: 'Adresse*',
        name: 'account.PersonMailingStreet',
        requiredErrorMsg: 'L\'adresse est requise',
        maxLengthErrorMsg: 'L\'adresse postale doit contenir au maximum ${max} caractères' // eslint-disable-line no-template-curly-in-string
      },
      PersonMobilePhone: {
        label: 'Mobile',
        name: 'account.PersonMobilePhone',
        requiredErrorMsg: 'Le téléphone fixe ou le mobile est requis ',
        formatInvalid: 'Le format du mobile est incorrect',
        maxLengthErrorMsg: 'Le mobile doit contenir au maximum ${max} caractères' // eslint-disable-line no-template-curly-in-string
      },
      Phone: {
        label: 'Téléphone fixe',
        name: 'account.Phone',
        requiredErrorMsg: 'Le téléphone fixe ou le mobile est requis ',
        formatInvalid: 'Le format du téléphone fixe est incorrect',
        maxLengthErrorMsg: 'Le téléphone fixe doit contenir au maximum ${max} caractères' // eslint-disable-line no-template-curly-in-string
      },
      RelativeInIonisSchool__c: {
        label: '',
        name: 'account.RelativeInIonisSchool__c',
        requiredErrorMsg: `L'école où le frère ou soeur a étudié est requis`
      },
      Salutation: {
        label: 'Civilité',
        name: 'account.Salutation',
        requiredErrorMsg: 'La civilité est requise'
      },
      SchoolEstablishment__pc: {
        label: '',
        name: 'account.SchoolEstablishment__pc'
      },
      SchoolSupCity__c: {
        label: '',
        name: 'account.SchoolSupCity__c',
        requiredErrorMsg: 'La ville du dernier établissement fréquenté est requis'
      },
      SchoolSupCountry__c: {
        label: '',
        name: 'account.SchoolSupCountry__c',
        requiredErrorMsg: 'Le pays du dernier établissement fréquenté est requis'
      },
      SchoolSupName__c: {
        label: '',
        name: 'account.SchoolSupName__c',
        requiredErrorMsg: 'Le dernier établissement fréquenté est requis'
      },
      Section__pc: {
        label: 'Section*',
        name: 'account.Section__pc',
        requiredErrorMsg: 'La section est requise'
      },
      SectionOfBAC__pc: {
        label: 'Type de BAC*',
        name: 'account.SectionOfBAC__pc',
        requiredErrorMsg: 'Le type de BAC est requis'
      },
      WhoIsFinancialRepresentative__pc: {
        label: '',
        name: 'account.WhoIsFinancialRepresentative__pc',
        requiredErrorMsg: 'Vous devez choisir votre représentant financier'
      }
    },
    opportunity: {
      CGV__c: {
        label: '',
        name: 'opportunity.CGV__c',
        requiredErrorMsg: 'Vous devez cocher cette case'
      },
      DirectDebitMode__c: {
        label: '',
        name: 'opportunity.DirectDebitMode__c',
        requiredErrorMsg: 'Vous devez choisir une modalité de règlement'
      },
      ReadPricesFile__c: {
        label: '',
        name: 'opportunity.ReadPricesFile__c',
        requiredErrorMsg: 'Vous devez cocher cette case'
      },
      RegistrationPaymentMode__c: {
        label: '',
        name: 'opportunity.RegistrationPaymentMode__c',
        requiredErrorMsg: 'Vous devez choisir un mode de paiement'
      }
    },
    emergencyContact: {
      name: 'emergencyContact',
      requiredErrorMsg: 'Vous devez cocher au minimum une case'
    },
    hasOneBrotherOrSister: {
      label: '',
      requiredErrorMsg: 'Vous devez remplir ce champ'
    },
    Parent1Live: {
      label: '',
      name: 'addresses.parent1'
    },
    Parent2Live: {
      label: '',
      name: 'addresses.parent2'
    },
    parentsScolar: {
      name: 'parentsScolar',
      requiredErrorMsg: 'Vous devez cocher au minimum une case'
    },
    providedFrenchEstablishment: {
      label: '',
      requiredErrorMsg: 'Vous devez remplir ce champ'
    },
    SpecialisedEducation: {
      label: 'Enseignement de spécialités',
      name: 'SpecialisedEducation',
      minErrorMsg: 'Vous devez choisir au minimun 2 spécialités',
      maxErrorMsg: 'Vous devez choisir au maximum 2 spécialités'
    }
  }
}

export default registrationFormModel
