import {
  Alert,
  AlertTitle,
  Button,
  CircularProgress,
  Snackbar,
} from "@mui/material";
import { Form, Formik } from "formik";
import { useState } from "react";
import { useSelector } from "react-redux";
import Salesforce from "../../../services/Salesforce";
import { InputField } from "../../FormFields";
import useStyles from "../../Portal/styles";
import { Link } from "react-router-dom";
import forgotPasswordValidationSchema from "../FormModel/forgotPasswordValidationSchema";
import { sendError } from "../../../services/Errors";
import { useTranslation } from "react-i18next"

const ForgotPassword = () => {
  const classes = useStyles();
  const division = useSelector((state) => state.salesforce.division);
  const [criticalErrors, setCriticalErrors] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("error");
  const { t } = useTranslation()

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  async function _handleSubmit(values, actions) {
    setCriticalErrors(false);
    const email = values.email;
    const resetPassword = await Salesforce.resetPassword(email, division).catch(
      (e) => {
        sendError(e);
        setCriticalErrors(true);
      }
    );
    if (resetPassword.success) {
      setSeverity("success");
      setMessage(t('ionisConnect.password_forgotten_email_sent', { email: email}));
    } else {
      setSeverity("error");
      setMessage(
        t('ionisConnect.password_forgotten_no_email')
      );
    }
    setOpen(true);
    actions.setSubmitting(false);
  }

  return (
    <>
      <Formik
        initialValues={{ email: "" }}
        onSubmit={_handleSubmit}
        validationSchema={forgotPasswordValidationSchema}
      >
        {({ isSubmitting }) => (
          <Form id="forgot-form">
            <InputField
              fullWidth
              label={t('email')}
              name="email"
              type="email"
            />
            <div className={classes.buttons}>
              <div className={classes.wrapper}>
                <Button
                  className={classes.button}
                  component={Link}
                  to="/connexion"
                >
                  {t('login')}
                </Button>
                <Button
                  color="primary"
                  className={classes.button}
                  disabled={isSubmitting}
                  type="submit"
                  variant="contained"
                >
                  {t('reset_password')}
                </Button>
                {isSubmitting && (
                  <CircularProgress
                    className={classes.buttonProgress}
                    size={24}
                  />
                )}
              </div>
            </div>
            {criticalErrors && (
              <Alert severity="error" sx={{ mt: 2 }} variant="outlined">
                <AlertTitle>{t('error_form')}</AlertTitle>
                {t('error_form2')}
              </Alert>
            )}
          </Form>
        )}
      </Formik>
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        // autoHideDuration={15000}
        onClose={handleClose}
        open={open}
      >
        <Alert
          elevation={6}
          onClose={handleClose}
          severity={severity}
          sx={{ width: "100%" }}
          variant="filled"
        >
          {message}
          <br /><br />
          Vous n'avez pas reçu le mail sur votre adresse e-mail ?
          <ul>
              <li>Vérifiez que vous avez saisi la bonne adresse.</li>
              <li>Vérifiez dans votre boite SPAM ou Courrier Indésirables, il arrive parfois que votre fournisseur d’accès le dépose dans ce dossier. Si c’est le cas cliquez sur « Ne pas considérer comme Spam ».</li>
              <li><a href="https://www.isg.fr/contacts-ecole/" rel="noreferrer" style={{ 'color' : 'inherit' }} target="_blank">Contactez le campus</a> si vous n’avez pas reçu le mail après ces vérifications.</li>
          </ul>
        </Alert>
      </Snackbar>
    </>
  );
};

export default ForgotPassword;
