import { Alert, Box, Button, Link, Typography } from "@mui/material"
import { Trans, useTranslation } from "react-i18next"
import { useHistory } from "react-router"

const ConfirmationCandidature = () => {

  const history = useHistory()
  const { t } = useTranslation()

  const goToForm = () => {
    history.push('/')
  }

  return (
    <>
      <Box
        alt="ISG"
        component="img"
        src="/logo-isg.jpg"
        sx={{ display: 'block', marginTop: 2, marginBottom: 2, mx: 'auto', height: 112, width: 112 }}
      >
      </Box>

      <Alert elevation={3} severity="success" sx={{ mb: 2 }}>
        <Trans i18nKey="page_confirmation_application.alert">
          Votre candidature en ligne a bien été enregistrée.
        </Trans>
      </Alert>

      <Typography paragraph variant="body2">
        <Trans i18nKey="page_confirmation_application.text">
          Vous pouvez compléter votre dossier à tout moment en retournant sur le <Link onClick={goToForm} style={{ cursor: 'pointer' }}>formulaire de candidature</Link> et en vous connectant avec votre e-mail et votre mot de passe.
        </Trans>
      </Typography>

      <Typography paragraph variant="body2">
        <Trans i18nKey="page.contact">
          Pour tout renseignement complémentaire, n'hésitez pas à <Link href="https://www.isg.fr/contacts-ecole" rel="noopener" target="_blank">contacter</Link> notre Service des Admissions.
        </Trans>
      </Typography>

      <Button onClick={goToForm} sx={{ mt: 2 }} variant="contained">{t('page.back')}</Button>
    </>
  )
}

export default ConfirmationCandidature
