import * as Yup from "yup"
import registrationFormModel from "./registrationFormModel"
import { isValidBIC, isValidIBAN } from "ibantools"
import moment from "moment"
import { checkIfFilesAreCorrectType, checkIfFilesAreTooBig } from "applicant-components/services/Validation"
const currentYear = new Date().getFullYear()

const {
  formField: {
    account: {
      BACYear__pc,
      BIC__c,
      Birthplace__pc,
      Country_of_birth__pc,
      EmergencyDegreeOfRelationship__pc,
      EmergencyFirstName__pc,
      EmergencyLastName__pc,
      EmergencyMail__pc,
      EmergencyMobile__pc,
      EmergencyPhone__pc,
      EmergencyTitle__pc,
      FinancialRepDegreeOfRelationship__pc,
      FinancialRepresentativeCivility__pc,
      FinancialRepresentativeFirstName__pc,
      FinancialRepresentativeHomeCity__pc,
      FinancialRepresentativeHomeCountry__pc,
      FinancialRepresentativeHomeStreet__pc,
      FinancialRepresentativeHomeZipCode__pc,
      FinancialRepresentativeLastName__pc,
      FinancialRepresentativeMail__pc,
      FinancialRepresentativeMobilePhone__pc,
      FinancialRepresentativePhone__pc,
      FinancialRepReceiveScholarInformations__pc,
      FirstName2__c,
      FirstName3__c,
      FirstnameBrotherSisterStudentIonis__pc,
      IBAN__c,
      INENumber__c,
      LastnameBrotherSisterStudentIonis__c,
      Nationalite__c,
      Parent1Address__pc,
      Parent1City__pc,
      Parent1Civility__pc,
      Parent1Country__pc,
      Parent1CP__pc,
      Parent1CSP__pc,
      Parent1DegreeOfRelationship__pc,
      Parent1Email__pc,
      Parent1Firstname__pc,
      Parent1Name__pc,
      Parent1MobilePhone__pc,
      Parent1Phone__pc,
      Parent2Address__pc,
      Parent2City__pc,
      Parent2Civility__pc,
      Parent2Country__pc,
      Parent2CP__pc,
      Parent2CSP__pc,
      Parent2DegreeOfRelationship__pc,
      Parent2Email__pc,
      Parent2Firstname__pc,
      Parent2MobilePhone__pc,
      Parent2Name__pc,
      Parent2Phone__pc,
      PersonBirthdate,
      PersonMobilePhone,
      PersonMailingCity,
      PersonMailingCountry,
      PersonMailingPostalCode,
      PersonMailingStreet,
      Phone,
      RelativeInIonisSchool__c,
      SchoolSupCity__c,
      SchoolSupCountry__c,
      SchoolSupName__c,
      WhoIsFinancialRepresentative__pc
    },
    opportunity: {
      CGV__c,
      DirectDebitMode__c,
      ReadPricesFile__c,
      RegistrationPaymentMode__c
    },
    emergencyContact,
    hasOneBrotherOrSister,
    parentsScolar,
    providedFrenchEstablishment
  }
} = registrationFormModel

const validationSchema = [
  // Infos personnelles
  Yup.object().shape({
    account: Yup.object().shape({
      Birthplace__pc: Yup.string().trim().max(38, Birthplace__pc.maxLengthErrorMsg).required(Birthplace__pc.requiredErrorMsg),
      Country_of_birth__pc: Yup.string().required(
        `${Country_of_birth__pc.requiredErrorMsg}`
      ),
      FirstName2__c: Yup.string().max(40, FirstName2__c.maxLengthErrorMsg),
      FirstName3__c: Yup.string().max(40, FirstName3__c.maxLengthErrorMsg),
      FirstnameBrotherSisterStudentIonis__pc: Yup.string().max(20, `${FirstnameBrotherSisterStudentIonis__pc.maxLengthErrorMsg}`).test('testfirstnamebrother', `${FirstnameBrotherSisterStudentIonis__pc.requiredErrorMsg}`, (value, context) => {
        const hasBrother = context.from.find(el => el.value.hasOneBrotherOrSister === 'Oui')
        return hasBrother === undefined || value !== undefined
      }),
      INENumber__c: Yup.string().trim().min(11, `${INENumber__c.minLengthErrorMsg}`).max(11, `${INENumber__c.maxLengthErrorMsg}`).test('testinenumber', `${INENumber__c.requiredErrorMsg}`, (value, context) => {
        const provided = context.from.find(el => el.value.providedFrenchEstablishment === 'Oui')
        return provided === undefined || value !== undefined
      }),
      LastnameBrotherSisterStudentIonis__c: Yup.string().max(20, `${LastnameBrotherSisterStudentIonis__c.maxLengthErrorMsg}`).test('testlastnamebrother', `${LastnameBrotherSisterStudentIonis__c.requiredErrorMsg}`, (value, context) => {
        const hasBrother = context.from.find(el => el.value.hasOneBrotherOrSister === 'Oui')
        return hasBrother === undefined || value !== undefined
      }),
      Nationalite__c: Yup.string().required(
        `${Nationalite__c.requiredErrorMsg}`
      ),
      PersonBirthdate: Yup.date()
        .transform((value, rawValue) => {
          let correctDate = moment(rawValue, ["yyyy-mm-dd"]).toDate();
          return correctDate;
        })
        .min(
          moment(new Date(1900, 1, 1)).toDate(),
          PersonBirthdate.minimumYear
        )
        .required(`${PersonBirthdate.requiredErrorMsg}`),
      PersonMailingCity: Yup.string()
        .trim()
        .max(40, PersonMailingCity.maxLengthErrorMsg)
        .required(`${PersonMailingCity.requiredErrorMsg}`),
      PersonMailingCountry: Yup.string()
        .trim()
        .required(`${PersonMailingCountry.requiredErrorMsg}`),
      PersonMailingPostalCode: Yup.string()
        .trim()
        .max(20, PersonMailingPostalCode.maxLengthErrorMsg)
        .required(`${PersonMailingPostalCode.requiredErrorMsg}`),
      PersonMailingStreet: Yup.string()
        .trim()
        .max(80, PersonMailingStreet.maxLengthErrorMsg)
        .required(`${PersonMailingStreet.requiredErrorMsg}`),
      Phone: Yup.string()
        .trim()
        .max(40, Phone.maxLengthErrorMsg)
        .when("PersonMobilePhone", {
          is: (phone) => !phone || phone.length === 0,
          then: Yup.string().required(`${Phone.requiredErrorMsg}`),
          otherwise: Yup.string(),
        }),
      PersonMobilePhone: Yup.string()
        .trim()
        .max(40, PersonMobilePhone.maxLengthErrorMsg)
        .when("Phone", {
          is: (phone) => !phone || phone.length === 0,
          then: Yup.string().required(
            `${PersonMobilePhone.requiredErrorMsg}`
          ),
          otherwise: Yup.string(),
        }),
      RelativeInIonisSchool__c: Yup.string().test('testschoolbrother', `${RelativeInIonisSchool__c.requiredErrorMsg}`, (value, context) => {
        const hasBrother = context.from.find(el => el.value.hasOneBrotherOrSister === 'Oui')
        return hasBrother === undefined || value !== undefined
      }),
      SchoolSupName__c: Yup.string().trim().required(`${SchoolSupName__c.requiredErrorMsg}`),
      SchoolSupCity__c: Yup.string().trim().when("SchoolEstablishment__pc", {
        is: (val) => val === undefined,
        then: Yup.string().required(`${SchoolSupCity__c.requiredErrorMsg}`),
        otherwise: Yup.string()
      }),
      SchoolSupCountry__c: Yup.string().trim().when("SchoolEstablishment__pc", {
        is: (val) => val === undefined,
        then: Yup.string().required(`${SchoolSupCountry__c.requiredErrorMsg}`),
        otherwise: Yup.string()
      })
    }, [["Phone", "PersonMobilePhone"]]),
    hasOneBrotherOrSister: Yup.string().required(`${hasOneBrotherOrSister.requiredErrorMsg}`).oneOf(['Oui' , 'Non']),
    providedFrenchEstablishment: Yup.string().required(`${providedFrenchEstablishment.requiredErrorMsg}`).oneOf(['Oui' , 'Non'])

  }),
  // Parents
  Yup.object().shape({
    account: Yup.object().shape({
      Parent1Address__pc: Yup.string().trim().max(255, `${Parent1Address__pc.maxLengthErrorMsg}`).required(`${Parent1Address__pc.requiredErrorMsg}`),
      Parent1City__pc: Yup.string().trim().max(38, `${Parent1City__pc.maxLengthErrorMsg}`).required(`${Parent1City__pc.requiredErrorMsg}`),
      Parent1Civility__pc: Yup.string().required(`${Parent1Civility__pc.requiredErrorMsg}`),
      Parent1Country__pc: Yup.string().trim().required(`${Parent1Country__pc.requiredErrorMsg}`),
      Parent1CP__pc: Yup.string().trim().max(20, `${Parent1CP__pc}`).required(`${Parent1CP__pc.requiredErrorMsg}`),
      Parent1CSP__pc: Yup.string().required(`${Parent1CSP__pc.requiredErrorMsg}`),
      Parent1DegreeOfRelationship__pc: Yup.string().required(`${Parent1DegreeOfRelationship__pc.requiredErrorMsg}`),
      Parent1Email__pc: Yup.string().email(`${Parent1Email__pc.invalidEmail}`).max(80, `${Parent1Email__pc.maxLengthErrorMsg}`).required(`${Parent1Email__pc.requiredErrorMsg}`),
      Parent1Firstname__pc: Yup.string().trim().max(20, `${Parent1Firstname__pc}`).required(`${Parent1Firstname__pc.requiredErrorMsg}`),
      Parent1Name__pc: Yup.string().trim().max(40, `${Parent1Name__pc.maxLengthErrorMsg}`).required(`${Parent1Name__pc.requiredErrorMsg}`),
      Parent1MobilePhone__pc: Yup.string().trim().max(40, `${Parent1MobilePhone__pc.maxLengthErrorMsg}`).required(`${Parent1MobilePhone__pc.requiredErrorMsg}`),
      Parent1Phone__pc: Yup.string().trim().max(40, `${Parent1MobilePhone__pc.maxLengthErrorMsg}`).optional(),
      Parent2Address__pc: Yup.string().trim().max(255, `${Parent2Address__pc.maxLengthErrorMsg}`).required(`${Parent2Address__pc.requiredErrorMsg}`),
      Parent2City__pc: Yup.string().trim().max(38, `${Parent2City__pc.maxLengthErrorMsg}`).required(`${Parent2City__pc.requiredErrorMsg}`),
      Parent2Civility__pc: Yup.string().required(`${Parent2Civility__pc.requiredErrorMsg}`),
      Parent2Country__pc: Yup.string().trim().required(`${Parent2Country__pc.requiredErrorMsg}`),
      Parent2CP__pc: Yup.string().trim().max(20, `${Parent2CP__pc}`).required(`${Parent2CP__pc.requiredErrorMsg}`),
      Parent2CSP__pc: Yup.string().required(`${Parent2CSP__pc.requiredErrorMsg}`),
      Parent2DegreeOfRelationship__pc: Yup.string().required(`${Parent2DegreeOfRelationship__pc.requiredErrorMsg}`),
      Parent2Email__pc: Yup.string().email(`${Parent2Email__pc.invalidEmail}`).max(80, `${Parent2Email__pc.maxLengthErrorMsg}`).required(`${Parent2Email__pc.requiredErrorMsg}`),
      Parent2Firstname__pc: Yup.string().trim().max(20, `${Parent2Firstname__pc}`).required(`${Parent2Firstname__pc.requiredErrorMsg}`),
      Parent2Name__pc: Yup.string().trim().max(40, `${Parent2Name__pc.maxLengthErrorMsg}`).required(`${Parent2Name__pc.requiredErrorMsg}`),
      Parent2MobilePhone__pc: Yup.string().trim().max(40, `${Parent2MobilePhone__pc.maxLengthErrorMsg}`).required(`${Parent2MobilePhone__pc.requiredErrorMsg}`),
      Parent2Phone__pc: Yup.string().trim().max(40, `${Parent2MobilePhone__pc.maxLengthErrorMsg}`).optional()
    }),
    parentsScolar: Yup.array().min(1, `${parentsScolar.requiredErrorMsg}`)
  }),
  // Contact uegence
  Yup.object().shape({
    account: Yup.object().shape({
      EmergencyDegreeOfRelationship__pc: Yup.string().when('WhoEmergencyContact__pc', {
        is: (val) => val === 'Autre',
        then: Yup.string().required(`${EmergencyDegreeOfRelationship__pc.requiredErrorMsg}`),
        otherwise: Yup.string()
      }),
      EmergencyFirstName__pc: Yup.string().when('WhoEmergencyContact__pc', {
        is: (val) => val === 'Autre',
        then: Yup.string().trim().max(40, `${EmergencyFirstName__pc.maxLengthErrorMsg}`).required(`${EmergencyFirstName__pc.requiredErrorMsg}`),
        otherwise: Yup.string()
      }),
      EmergencyLastName__pc: Yup.string().when('WhoEmergencyContact__pc', {
        is: (val) => val === 'Autre',
        then: Yup.string().max(40, `${EmergencyLastName__pc.maxLengthErrorMsg}`).required(`${EmergencyLastName__pc.requiredErrorMsg}`),
        otherwise: Yup.string()
      }),
      EmergencyMail__pc: Yup.string().when('WhoEmergencyContact__pc', {
        is: (val) => val === 'Autre',
        then: Yup.string().email(`${EmergencyMail__pc.invalidEmail}`).max(80, `${EmergencyMail__pc.maxLengthErrorMsg}`).required(`${EmergencyMail__pc.requiredErrorMsg}`),
        otherwise: Yup.string()
      }),
      EmergencyMobile__pc: Yup.string().when('WhoEmergencyContact__pc', {
        is: (val) => val === 'Autre',
        then: Yup.string().trim().max(40, `${EmergencyMobile__pc.maxLengthErrorMsg}`).required(`${EmergencyMobile__pc.requiredErrorMsg}`),
        otherwise: Yup.string()
      }),
      EmergencyPhone__pc: Yup.string().when('WhoEmergencyContact__pc', {
        is: (val) => val === 'Autre',
        then: Yup.string().trim().max(40, `${EmergencyPhone__pc.maxLengthErrorMsg}`),
        otherwise: Yup.string()
      }),
      EmergencyTitle__pc: Yup.string().when('WhoEmergencyContact__pc', {
        is: (val) => val === 'Autre',
        then: Yup.string().required(`${EmergencyTitle__pc.requiredErrorMsg}`),
        otherwise: Yup.string()
      })
    }),
    emergencyContact: Yup.array().min(1, `${emergencyContact.requiredErrorMsg}`)
  }),
  // Responsable financier
  Yup.object().shape({
    account: Yup.object().shape({
      FinancialRepDegreeOfRelationship__pc: Yup.string().when('WhoIsFinancialRepresentative__pc', {
        is: (val) => val === 'Autre',
        then: Yup.string().required(`${FinancialRepDegreeOfRelationship__pc.requiredErrorMsg}`),
        otherwise: Yup.string()
      }),
      FinancialRepresentativeCivility__pc: Yup.string().when('WhoIsFinancialRepresentative__pc', {
        is: (val) => val === 'Autre',
        then: Yup.string().required(`${FinancialRepresentativeCivility__pc.requiredErrorMsg}`),
        otherwise: Yup.string()
      }),
      FinancialRepresentativeFirstName__pc: Yup.string().when('WhoIsFinancialRepresentative__pc', {
        is: (val) => val === 'Autre',
        then: Yup.string().trim().max(40, `${FinancialRepresentativeFirstName__pc.maxLengthErrorMsg}`).required(`${FinancialRepresentativeFirstName__pc.requiredErrorMsg}`),
        otherwise: Yup.string()
      }),
      FinancialRepresentativeHomeCity__pc: Yup.string().when('WhoIsFinancialRepresentative__pc', {
        is: (val) => val === 'Autre',
        then: Yup.string().trim().max(38, `${FinancialRepresentativeHomeCity__pc.maxLengthErrorMsg}`).required(`${FinancialRepresentativeHomeCity__pc.requiredErrorMsg}`),
        otherwise: Yup.string()
      }),
      FinancialRepresentativeHomeCountry__pc: Yup.string().when('WhoIsFinancialRepresentative__pc', {
        is: (val) => val === 'Autre',
        then: Yup.string().required(`${FinancialRepresentativeHomeCountry__pc.requiredErrorMsg}`),
        otherwise: Yup.string()
      }),
      FinancialRepresentativeHomeStreet__pc: Yup.string().when('WhoIsFinancialRepresentative__pc', {
        is: (val) => val === 'Autre',
        then: Yup.string().trim().max(255, `${FinancialRepresentativeHomeStreet__pc.maxLengthErrorMsg}`).required(`${FinancialRepresentativeHomeStreet__pc.requiredErrorMsg}`),
        otherwise: Yup.string()
      }),
      FinancialRepresentativeHomeZipCode__pc: Yup.string().when('WhoIsFinancialRepresentative__pc', {
        is: (val) => val === 'Autre',
        then: Yup.string().trim().max(38, `${FinancialRepresentativeHomeZipCode__pc.maxLengthErrorMsg}`).required(`${FinancialRepresentativeHomeZipCode__pc.requiredErrorMsg}`),
        otherwise: Yup.string()
      }),
      FinancialRepresentativeLastName__pc: Yup.string().when('WhoIsFinancialRepresentative__pc', {
        is: (val) => val === 'Autre',
        then: Yup.string().trim().max(40, `${FinancialRepresentativeLastName__pc.maxLengthErrorMsg}`).required(`${FinancialRepresentativeLastName__pc.requiredErrorMsg}`),
        otherwise: Yup.string()
      }),
      FinancialRepresentativeMail__pc: Yup.string().when('WhoIsFinancialRepresentative__pc', {
        is: (val) => val === 'Autre',
        then: Yup.string().email(`${FinancialRepresentativeMail__pc.invalidEmail}`).trim().max(80, `${FinancialRepresentativeMail__pc.maxLengthErrorMsg}`).required(`{FinancialRepresentativeMail__pc.requiredErrorMsg}`),
        otherwise: Yup.string()
      }),
      FinancialRepresentativeMobilePhone__pc: Yup.string().when('WhoIsFinancialRepresentative__pc', {
        is: (val) => val === 'Autre',
        then: Yup.string().trim().max(40, `${FinancialRepresentativeMobilePhone__pc.maxLengthErrorMsg}`).required(`${FinancialRepresentativeMobilePhone__pc.requiredErrorMsg}`),
        otherwise: Yup.string()
      }),
      FinancialRepresentativePhone__pc: Yup.string().when('WhoIsFinancialRepresentative__pc', {
        is: (val) => val === 'Autre',
        then: Yup.string().trim().max(40, `${FinancialRepresentativePhone__pc.maxLengthErrorMsg}`),
        otherwise: Yup.string()
      }),
      FinancialRepReceiveScholarInformations__pc: Yup.string().when('WhoIsFinancialRepresentative__pc', {
        is: (val) => val === 'Autre',
        then: Yup.string().required(`${FinancialRepReceiveScholarInformations__pc.requiredErrorMsg}`),
        otherwise: Yup.string()
      }),
      WhoIsFinancialRepresentative__pc: Yup.string().required(`${WhoIsFinancialRepresentative__pc.requiredErrorMsg}`)
    })
  }),
  // Modalités de paiement
  Yup.object().shape({
    account: Yup.object().shape({
      BIC__c: Yup.string().trim().min(8, `${BIC__c.minLengthErrorMsg}`).max(11, `${BIC__c.maxLengthErrorMsg}`).required(`${BIC__c.requiredErrorMsg}`),
      IBAN__c: Yup.string().when("BIC__c", {
        is: (val) => {
          return isValidBIC(val)
        },
        then: Yup.string().trim().required(`${IBAN__c.requiredErrorMsg}`).test(
          "checkIBAN",
          "Le code IBAN que vous avez renseigné est invalide",
          (data) => {
            const isIBANValid = isValidIBAN(data === undefined ? data : data.replace(/ /g,''))
            return isIBANValid
          }
        ).min(14, `${IBAN__c.minLengthErrorMsg}`).max(34, `${IBAN__c.maxLengthErrorMsg}`),
        otherwise: Yup.string()
      }),
    }),
    opportunity: Yup.object().shape({
      CGV__c: Yup.bool().oneOf([true], `${CGV__c.requiredErrorMsg}`),
      DirectDebitMode__c: Yup.string().nullable().required(`${DirectDebitMode__c.requiredErrorMsg}`),
      ReadPricesFile__c: Yup.bool().oneOf([true], `${ReadPricesFile__c.requiredErrorMsg}`),
      RegistrationPaymentMode__c: Yup.string().nullable().required(`${RegistrationPaymentMode__c.requiredErrorMsg}`)
    }),
    // rib: Yup.mixed().required('Vous devez fournir un RIB').test('filetobig', 'Le poids du fichier doit être inférieur à 5Mo', checkIfFilesAreTooBig).test('wrongextension', 'Le fichier doit être de type jpg, png ou pdf', checkIfFilesAreCorrectType)
  }),
  Yup.object().shape({
    recap: Yup.object().shape({
      hasCheckedParents: Yup.bool().oneOf([true], 'blabla')
    })
  })
]

export default validationSchema
