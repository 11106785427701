import { Alert, Box, Button, CircularProgress, Container, Typography } from "@mui/material";
import { CheckboxField } from "applicant-components/components/FormFields";
import { useDispatch, useSelector } from "react-redux";
import { show } from "applicant-components/Store/buttonsSlice";
import useStyles from "../styles";
import { useEffect, useRef, useState } from "react";
import { useFormikContext } from "formik";
import {
  SalesforceGoToPayment,
  SalesforceUpdateStageStop,
  updateCampaignMember,
} from "applicant-components/Store/salesforceSlice";
import { persistStore } from "redux-persist";
import store from "applicant-components/Store/Store";
import { ConfirmationNoJPC } from "applicant-components/components/Popin";
import Salesforce from "applicant-components/services/Salesforce";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next"
import { sendMessage } from "applicant-components/services/Errors"
import StripeContainer from "applicant-components/components/Stripe/StripeContainer"

const Payment = ({ newApplication = false, paymentCancel, ...props }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const refForm = useRef();
  const { t } = useTranslation()
  const [priceEuro, setPriceEuro] = useState(0);
  const [openPopup, setOpenPopup] = useState(false);
  const statusBank = useSelector((state) => state.salesforce.statusPayment);
  const opportunity = useSelector((state) => state.salesforce.opportunity);
  const opportunityId = useSelector(
    (state) => state.salesforce.opportunities[0].Id
  ); // @todo ou alors récupérer opportunity.Id après création de l'oppy
  // const opportunities = useSelector(state => state.salesforce.opportunities)
  const account = useSelector((state) => state.salesforce.account);
  const hasOneOppyCompleted = useSelector(
    (state) => state.salesforce.hasOneOppyCompleted
  );
  const paymentJPC =
    useSelector((state) => state.salesforce.paymentType) === "JPC";
  const campaignMemberId = useSelector(
    (state) =>
      state.salesforce.campaignMember.Id || state.salesforce.campaignMember.id
  );
  let persistor = persistStore(store);
  const {
    formField: {
      account: { CGV__c },
    },
  } = props;
  const {
    values: {
      account: { CGV__c: CGV },
    },
  } = useFormikContext();

  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [payboxData, setPayboxData] = useState({})

  useEffect(() => {
    async function getPayboxData() {
      const paybox = await Salesforce.getPayboxData(account, null, 'CAND', {
        paiement_annule: process.env.REACT_APP_PAYMENT_ANNULE,
        paiement_ok: process.env.REACT_APP_PAYMENT_EFFECTUE,
        paiement_refuse: process.env.REACT_APP_PAYMENT_REFUSE,
        paiement_traitement: process.env.REACT_APP_PAYMENT_TRAITEMENT
      })
      setPayboxData(paybox)
      setPriceEuro(paybox.priceEuro)
    }
    getPayboxData();

    sendMessage("debug campaignmember", {
      campaignMemberId: campaignMemberId
    })


  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setButtonDisabled(!CGV)
  }, [CGV])

  useEffect(() => {
    if (statusBank === "OK") {
      dispatch(show());
    }
  }, [statusBank]); // eslint-disable-line react-hooks/exhaustive-deps

  const goToBank = (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    setLoading(true);
    dispatch(SalesforceGoToPayment({ opportunityId, type: 'candidature' }));
    persistor.purge().then(() => {
      refForm.current.submit();
    });
  };

  const cancelJPC = () => setOpenPopup(true);

  const closeJPC = async (close) => {
    if (close && !hasOneOppyCompleted) {
      const newStep = 5;
      const accountId = account.Id;
      const canceledJPCWithSuccess = await Salesforce.cancelJPC(
        campaignMemberId
      );
      if (canceledJPCWithSuccess) {
        await Salesforce.updateOpportunity(opportunity, false, "JPC");
      }
      await dispatch(SalesforceUpdateStageStop({ accountId, newStep }));
      dispatch(updateCampaignMember({}));
    } else if (close && hasOneOppyCompleted) {
      const canceledJPCWithSuccess = await Salesforce.cancelJPC(
        campaignMemberId
      );
      if (canceledJPCWithSuccess) {
        await Salesforce.updateOpportunity(opportunity, false, "JPC");
      }
      dispatch(updateCampaignMember({}));
      console.log("newApp", newApplication);
      if (!newApplication) {
        history.push("/");
      } else {
        setOpenPopup(false);
        paymentCancel();
      }
    } else {
      setOpenPopup(false);
    }
  };

  return (
    <>
      <Typography variant="h5">
        {paymentJPC
          ? t('payment.title_jpc')
          : t('payment.title_application')}
      </Typography>
      {statusBank === "reached_bank_website" && (
        <Alert severity="info" sx={{ mt: 2 }}>
          {paymentJPC
            ? t('payment.payment_jpc_no')
            : t('payment.payment_application_no')}{" "}
          {t('payment.continue_payment')}.
        </Alert>
      )}
      {statusBank === "KO" && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {paymentJPC
            ? t('payment.payment_jpc_ko')
            : t('payment.payment_application_ko')}{" "}
          {t('payment.continue_payment')}.
        </Alert>
      )}
      {statusBank === "OK" && (
        <Alert severity="success" sx={{ mt: 2 }}>
          {paymentJPC
            ? t('payment.payment_jpc_ok')
            : t('payment.payment_application_ok')}{" "}
          {t('payment.continue_form')}.
        </Alert>
      )}

      {statusBank !== "OK" && (
        <Box>
          <p>
            {paymentJPC
              ? t('payment.frais_jpc')
              : t('payment.frais_candidature')}{" "}
            {t('payment.frais_sont')} {priceEuro}€.
          </p>

          <CheckboxField
            label={
              <div>
                {t('payment.cgv')}{" "}
                <a
                  href={process.env.REACT_APP_URL_CGV}
                  className={classes.link}
                  rel="noreferrer"
                  target="_blank"
                >
                  {t('payment.cgv2')}
                </a>{" "}
                {t('payment.cgv3')}{" "}
                <a
                  href={process.env.REACT_APP_SITE_URL}
                  className={classes.link}
                  rel="noreferrer"
                  target="_blank"
                >
                  {process.env.REACT_APP_SITE_URL}
                </a>{" "}
                {t('payment.cgv4')}.
              </div>
            }
            name={CGV__c.name}
          />
          {/* {!buttonDisabled && (
            <Container sx={{ mb: 5, mt: 5 }} maxWidth="sm">
              <StripeContainer />
            </Container>
          )} */}
          <form
            method="POST"
            action={process.env.REACT_APP_PAYBOX_URL}
            ref={refForm}
          >
            <input type="hidden" name="PBX_SITE" value={payboxData.pbxSite} />
            <input type="hidden" name="PBX_RANG" value={payboxData.pbxRang} />
            <input
              type="hidden"
              name="PBX_IDENTIFIANT"
              value={payboxData.pbxIdentifiant}
            />
            <input type="hidden" name="PBX_TOTAL" value={payboxData.pbxTotal} />
            <input type="hidden" name="PBX_DEVISE" value={payboxData.pbxDevise} />
            <input type="hidden" name="PBX_CMD" value={payboxData.pbxCmd} />
            <input
              type="hidden"
              name="PBX_SHOPPINGCART"
              value={payboxData.pbxShoppingCart}
            />
            <input type="hidden" name="PBX_PORTEUR" value={payboxData.pbxPorteur} />
            <input type="hidden" name="PBX_BILLING" value={payboxData.pbxBilling} />
            <input type="hidden" name="PBX_RETOUR" value={payboxData.pbxRetour} />
            <input type="hidden" name="PBX_HASH" value={payboxData.pbxHash} />
            <input type="hidden" name="PBX_TIME" value={payboxData.pbxTime} />
            <input type="hidden" name="PBX_EFFECTUE" value={payboxData.pbxEffectue} />
            <input type="hidden" name="PBX_REFUSE" value={payboxData.pbxRefuse} />
            <input type="hidden" name="PBX_ANNULE" value={payboxData.pbxAnnule} />
            <input type="hidden" name="PBX_REPONDRE_A" value={payboxData.pbxRepondreA} />
            <input type="hidden" name="PBX_HMAC" value={payboxData.pbxHmac} />

            {/* <Button onClick={simulatePayment} variant="contained">Simuler paiement en ligne</Button> */}
            <div className={classes.buttons}>
              <div className={classes.wrapper}>
                {paymentJPC && (
                  <Button
                    color="warning"
                    className={classes.button}
                    onClick={cancelJPC}
                    variant="outlined"
                  >
                    {t('payment.button_no_jpc')}
                  </Button>
                )}
                <Button
                  color="primary"
                  className={classes.button}
                  disabled={buttonDisabled}
                  onClick={goToBank}
                  type="submit"
                  variant="contained"
                >
                  {paymentJPC
                    ? t('payment.button_jpc')
                    : t('payment.button_application')}
                </Button>
                {loading && (
                  <CircularProgress
                    className={classes.buttonProgress}
                    size={24}
                  />
                )}
              </div>
            </div>
          </form>
        </Box>
      )}
      <ConfirmationNoJPC openPopup={openPopup} closeJPC={closeJPC} />
    </>
  );
};

export default Payment;
