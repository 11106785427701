import { Alert, Grid, Link, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { useStyles } from "./styles"
import { Language } from "applicant-components/components/Buttons"
import { useTranslation } from "react-i18next"

const RegistrationLayout = ({children}) => {

  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.login}>
      <Typography align="center" className={classes.registration_h2} gutterBottom variant="h4">
        {t('ionisConnect.profile_creation')}
      </Typography>
      <Grid container>
        <Grid item lg={2} xs={12}></Grid>
        <Grid item lg={10} xs={12}>
          <Alert severity="warning" sx={{ mb: 2 }}>A noter : Les formations en 2<sup>nd</sup> cycle (entrée en 4<sup>e</sup> année du Programme Grande École ou en 1<sup>e</sup> année d’un Master of Science ou d’un MBA) <strong>ne sont accessibles qu’aux candidats âgés de moins de 28 ans au jour de la rentrée (au 30 septembre)</strong>. Pour tout complément d’information, vous pouvez prendre <Link href="https://www.isg.fr/contacts-ecole/" target="_blank">contact avec nos équipes</Link>.</Alert>
        </Grid>
      </Grid>
      {children}
    </div>
  )
}

export default RegistrationLayout
