import { Paper } from "@mui/material"
import { useSelector } from "react-redux"
import Header from "applicant-components/components/Header"
import { useStyles } from "./styles"

const Layout = ({ children }) => {

  const classes = useStyles()
  const isLogged = useSelector(state => state.salesforce.isLogged)

  return (
    <>
      <div className={classes.website}>
        { isLogged && <Header logo="/logo-isg.jpg" school="ISG" /> }
        <div className={isLogged ? classes.root : classes.root_login}>
          <Paper className={isLogged ? classes.paper : classes.paper_login} elevation={3}>{children}</Paper>
        </div>
      </div>
    </>
  )
}

export default Layout
