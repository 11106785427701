import { FastField, FieldArray, useField } from "formik"
import { Alert, Button, Grid, Paper } from "@mui/material"
import { InputExample } from "../FormFields/InputExample"
import { DatePickerField, InputField, SelectField } from "../FormFields"
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import React from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

const WorkExperienceItem = ({ index }) => {

  const [DescriptionField] = useField({ name: `WorkExperience__c[${index}].Description__c`})
  const [EmployerField] = useField({ name: `WorkExperience__c[${index}].Employer__c`})
  const [EndDateField] = useField({ name: `WorkExperience__c[${index}].EndDate__c`})
  const [LocationField] = useField({ name: `WorkExperience__c[${index}].Location__c`})
  const [PositionField] = useField({ name: `WorkExperience__c[${index}].Position__c`})
  const [StartDateField] = useField({ name: `WorkExperience__c[${index}].StartDate__c`})
  const optionsCountries = useSelector(state => state.salesforce.countries)
  // const currentYear = new Date().getFullYear()
  const { t } = useTranslation()

  return (
    <>
      <Grid item sm={6} xs={12}>
        <FastField component={InputExample} label={t('WorkExperience__c.Employer__c')} name={EmployerField.name} />
      </Grid>
      <Grid item sm={6} xs={12}>
        <SelectField data={optionsCountries} fullWidth label={t('WorkExperience__c.Location__c')} name={LocationField.name} required />
      </Grid>
      <Grid item sm={4} xs={12}>
        <FastField component={InputExample} label={t('WorkExperience__c.Position__c')} name={PositionField.name} />
      </Grid>
      <Grid item sm={4} xs={12}>
        <DatePickerField fullWidth inputFormat="MM/yyyy" label={t('WorkExperience__c.StartDate__c')} name={StartDateField.name} views={['month', 'year']} />
      </Grid>
      <Grid item sm={4} xs={12}>
        <DatePickerField fullWidth inputFormat="MM/yyyy" label={t('WorkExperience__c.EndDate__c')} name={EndDateField.name} views={['month', 'year']} />
      </Grid>
      <Grid item xs={12}>
        <InputField fullWidth label={t('WorkExperience__c.Description__c')} minRows={3} multiline name={DescriptionField.name} required />
      </Grid>
    </>
  )
}

const WorkExperience = () => {

  const accountId = useSelector(state => state.salesforce.account.Id)
  const { t } = useTranslation()

  return (
    <FieldArray name="WorkExperience__c">
      {({ form, ...fieldArrayHelpers }) => {
        const onAddClick = (e) => {
          e.preventDefault()
          fieldArrayHelpers.push({
            // Id: form.values.WorkExperience__c.length,
            Applicant__c: accountId,
            Description__c: '',
            Employer__c: '',
            EndDate__c: '',
            Location__c: '',
            Position__c: '',
            StartDate__c: ''
          })
        }
        const onRemoveClick = (e, index) => {
          e.preventDefault()
          fieldArrayHelpers.remove(index)
        }
        return (
          <>
            {form.values.WorkExperience__c.length === 0 && <Alert elevation={6} severity="info">{t('experience.info')} <strong>{t('experience.button', { other: '' })}</strong>.</Alert>}
            <Paper sx={{ display: form.values.WorkExperience__c.length === 0 ? 'none' : 'block' }}>
              <Grid alignItems="center" container spacing={3} sx={{ m:0, pl: 0, pr: 12, pt: 3, pb: 3 }}>
                {form.values.WorkExperience__c.map((work, index) => (
                  <React.Fragment key={index}>
                    <WorkExperienceItem
                    index={index}
                    />
                    <Grid item sm={12}>
                      <Button color="error" onClick={(e) => { onRemoveClick(e, index)}} startIcon={<DeleteForeverIcon />} variant="text">{t('experience.delete')}</Button>
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>
            </Paper>
            <Button onClick={onAddClick} variant="outlined" sx={{ mt: 2 }}>{t('experience.button', { other: form.values.WorkExperience__c.length > 0 ? t('experience.other') : '' })}</Button>
          </>
        )
      }}
    </FieldArray>
  )
}

export default WorkExperience
