import { createSlice } from '@reduxjs/toolkit'

export const themeSlice = createSlice({
  name: 'theme',
  initialState: {
    theme: 'system'
  },
  reducers: {
    modifyTheme: (state, action) => {
      state.theme = action.payload
      // state = action.payload
    }
  }
})

export const { modifyTheme } = themeSlice.actions

export default themeSlice.reducer
