import * as Yup from "yup"
import ionisConnectFormModel from "./ionisConnectFormModel"

const {
  formField: {
    email,
  }
} = ionisConnectFormModel

const forgotPasswordValidationSchema =
  Yup.object().shape({
    [email.name]: Yup.string().email(`${email.invalidEmail}`).required(`${email.requiredErrorMsg}`)
    // .test('testing-quote', `${email.invalidQuote}`, (value, context) => {
    //   return value.indexOf(`'`) === -1
    // })
  })

export default forgotPasswordValidationSchema
