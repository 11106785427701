import * as Yup from "yup";
import moment from "moment";
import portalFormModel from "./portalFormModel";
import i18n from "i18next"
import { matchIsValidTel } from "mui-tel-input"
const currentYear = new Date().getFullYear();
let supportingDocumentList = [
  i18n.t('supportingDocument.0'),
  i18n.t('supportingDocument.1'),
  i18n.t('supportingDocument.2'),
  i18n.t('supportingDocument.3'),
  i18n.t('supportingDocument.4')
];

const {
  formField: {
    account: {
      AcademicLevel__pc,
      BACYear__pc,
      Country_of_birth__pc,
      FirstName,
      LastName,
      Nationalite__c,
      Native_Language__pc,
      Langue_1__c,
      Langue_2__c,
      Langue_3__c,
      Niveau_langue_1__c,
      Niveau_langue_2__c,
      Niveau_Langue_3__c,
      PersonBirthdate,
      PersonMobilePhone,
      PersonMailingCity,
      PersonMailingCountry,
      PersonMailingPostalCode,
      PersonMailingStreet,
      Phone,
      // Salutation,
      Section__pc,
      SectionOfBAC__pc,
      // Reports_Cards__c,
      // Copy_of_Diploma__c,
      // ID_Proof__c,
      Grant_Proof__c,
    },
    opportunity: { Session__c },
    SpecialisedEducation,
  },
} = portalFormModel;

console.log('Country of birth error message:', Country_of_birth__pc.requiredErrorMsg)

const validationSchema = [
  //step 1
  Yup.object().shape({
    account: Yup.object().shape(
      {
        AcademicLevel__pc: Yup.string().required(
          `${AcademicLevel__pc.requiredErrorMsg}`
        ),
        // BACYear__pc: Yup.number().required(`${BACYear__pc.requiredErrorMsg}`),
        BACYear__pc: Yup.number().when("AcademicLevel__pc", {
          is: "Terminale (BAC)",
          then: Yup.number(),
          otherwise: Yup.number()
            .min(1970, BACYear__pc.minMaxErrorMsg)
            .max(currentYear, BACYear__pc.minMaxErrorMsg)
            .required(`${BACYear__pc.requiredErrorMsg}`),
        }),
        Country_of_birth__pc: Yup.string().required(
          `${Country_of_birth__pc.requiredErrorMsg}`
        ),
        FirstName: Yup.string()
          .trim()
          .max(40, FirstName.maxLengthErrorMsg)
          .required(`${FirstName.requiredErrorMsg}`),
        LastName: Yup.string()
          .trim()
          .max(80, LastName.maxLengthErrorMsg)
          .required(`${LastName.requiredErrorMsg}`),
        Nationalite__c: Yup.string().required(
          `${Nationalite__c.requiredErrorMsg}`
        ),
        Native_Language__pc: Yup.string().required(
          `${Native_Language__pc.requiredErrorMsg}`
        ),
        PersonBirthdate: Yup.date()
          .transform((value, rawValue) => {
            let correctDate = moment(rawValue, ["yyyy-mm-dd"]).toDate();
            return correctDate;
          })
          .min(
            moment(new Date(1900, 1, 1)).toDate(),
            PersonBirthdate.minimumYear
          )
          .required(`${PersonBirthdate.requiredErrorMsg}`),
        Phone: Yup.string()
          .trim()
          .max(40, Phone.maxLengthErrorMsg)
          .when("PersonMobilePhone", {
            is: (phone) => !phone || phone.length === 0,
            then: Yup.string().required(`${Phone.requiredErrorMsg}`)
            .test('test-phone', 'Le numéro que vous avez renseigné semble incorrect',
            (val) => {
              return val !== undefined && (val === '' || matchIsValidTel(val))
            }),
            otherwise: Yup.string(),
          }),
        PersonMailingCity: Yup.string()
          .trim()
          .max(40, PersonMailingCity.maxLengthErrorMsg)
          .required(`${PersonMailingCity.requiredErrorMsg}`),
        PersonMailingCountry: Yup.string()
          .trim()
          .required(`${PersonMailingCountry.requiredErrorMsg}`),
        PersonMailingPostalCode: Yup.string()
          .trim()
          .max(20, PersonMailingPostalCode.maxLengthErrorMsg)
          .required(`${PersonMailingPostalCode.requiredErrorMsg}`),
        PersonMailingStreet: Yup.string()
          .trim()
          .max(80, PersonMailingStreet.maxLengthErrorMsg)
          .required(`${PersonMailingStreet.requiredErrorMsg}`),
        PersonMobilePhone: Yup.string()
          .trim()
          .max(40, PersonMobilePhone.maxLengthErrorMsg)
          .when("Phone", {
            is: (phone) => !phone || phone.length === 0,
            then: Yup.string().required(
              `${PersonMobilePhone.requiredErrorMsg}`
            )
            .test('test-mobile-phone', 'Le numéro que vous avez renseigné semble incorrect',
            (val) => {
              return val !== undefined && (val === '' || matchIsValidTel(val))
            }),
            otherwise: Yup.string(),
          }),
        Section__pc: Yup.string().required(`${Section__pc.requiredErrorMsg}`),
        // SectionOfBAC__pc: Yup.string().required(`${SectionOfBAC__pc.requiredErrorMsg}`)
        SectionOfBAC__pc: Yup.string().when("AcademicLevel__pc", {
          is: "Terminale (BAC)",
          then: Yup.string(),
          otherwise: Yup.string().required(
            `${SectionOfBAC__pc.requiredErrorMsg}`
          ),
        }),
      },
      [["Phone", "PersonMobilePhone"]]
    ),
    SpecialisedEducation: Yup.array().when("account.AcademicLevel__pc", {
      is: "Terminale (BAC)",
      then: Yup.array().when("account.Section__pc", {
        is: "Générale",
        then: Yup.array()
          .min(2, SpecialisedEducation.minErrorMsg)
          .max(2, SpecialisedEducation.maxErrorMsg)
      }),
      otherwise: Yup.array().when("account.SectionOfBAC__pc", {
        is: "Générale",
        then: Yup.array().when("account.BACYear__pc", {
          is: (val) => val >= 2021,
          then: Yup.array()
            .min(2, SpecialisedEducation.minErrorMsg)
            .max(2, SpecialisedEducation.maxErrorMsg)
        })
      })
    })
  }),
  //step 2
  Yup.object().shape({
    application: Yup.object().shape({
      trackMajor: Yup.string().when('trackMajorDisabled', {
        is: val => val === false,
        then: Yup.string().required('Vous devez choisir une spécialité principale'),
        otherwise: Yup.string()
      }),
      trackMinor: Yup.array().max(2, "Vous devez choisir au maximum 2 spécialités")
    }),
    opportunity: Yup.object().shape({
      Session__c: Yup.string().required(`${Session__c.requiredErrorMsg}`),
    }),
  }),
  //step 3
  Yup.object().shape({
    AcademicInformation__c: Yup.array().of(
      Yup.object().shape({
        EstablishmentName__c: Yup.string()
          .trim()
          .required(i18n.t('fields.required', { field: i18n.t('AcademicInformation__c.EstablishmentName__c') })),
        Location__c: Yup.string().trim().required(i18n.t('fields.required', { field: i18n.t('AcademicInformation__c.Location__c') })),
        YearOfGraduation__c: Yup.number()
          .min(1970, BACYear__pc.minMaxErrorMsg)
          .max(currentYear, BACYear__pc.minMaxErrorMsg)
          .required(i18n.t('fields.required', { field: i18n.t('AcademicInformation__c.YearOfGraduation__c') })),
      })
    ),
    account: Yup.object().shape(
      {
        Langue_1__c: Yup.string()
          .required(`${Langue_1__c.requiredErrorMsg}`)
          .notOneOf([Yup.ref("Langue_2__c")], Langue_2__c.notSameValue)
          .notOneOf([Yup.ref("Langue_3__c")], Langue_3__c.notSameValue),
        Niveau_langue_1__c: Yup.string().required(
          `${Niveau_langue_1__c.requiredErrorMsg}`
        ),
        Langue_2__c: Yup.string().when("Niveau_langue_2__c", {
          is: (niveaulangue2) =>
            niveaulangue2 !== undefined && niveaulangue2.length > 0,
          then: Yup.string()
            .required(`${Langue_2__c.requiredErrorMsg}`)
            .notOneOf([Yup.ref("Langue_1__c")], Langue_1__c.notSameValue)
            .notOneOf([Yup.ref("Langue_3__c")], Langue_3__c.notSameValue),
          otherwise: Yup.string(),
        }),
        Niveau_langue_2__c: Yup.string().when("Langue_2__c", {
          is: (langue2) => langue2 !== undefined && langue2.length > 0,
          then: Yup.string().required(`${Niveau_langue_2__c.requiredErrorMsg}`),
          otherwise: Yup.string(),
        }),
        Langue_3__c: Yup.string().when("Niveau_Langue_3__c", {
          is: (niveaulangue3) =>
            niveaulangue3 !== undefined && niveaulangue3.length > 0,
          then: Yup.string()
            .required(`${Langue_3__c.requiredErrorMsg}`)
            .notOneOf([Yup.ref("Langue_1__c")], Langue_1__c.notSameValue)
            .notOneOf([Yup.ref("Langue_2__c")], Langue_2__c.notSameValue),
          otherwise: Yup.string(),
        }),
        Niveau_Langue_3__c: Yup.string().when("Langue_3__c", {
          is: (langue3) => langue3 !== undefined && langue3.length > 0,
          then: Yup.string().required(`${Niveau_Langue_3__c.requiredErrorMsg}`),
          otherwise: Yup.string(),
        }),
      },
      [
        ["Niveau_langue_2__c", "Langue_2__c"],
        ["Langue_3__c", "Niveau_Langue_3__c"],
      ]
    ),
    WorkExperience__c: Yup.array().of(
      Yup.object().shape({
        Description__c: Yup.string()
          .trim()
          .required(i18n.t('fields.required', { field: i18n.t('WorkExperience__c.Description__c') })),
        // Employer__c: Yup.string().trim().required('L\'employeur est requis'),
        EndDate__c: Yup.date()
          .default(() => new Date())
          .typeError(i18n.t('fields.formatDate')),
        // .when("StartDate__c", (startDate, yup) => startDate && yup.min(startDate, "La date de fin ne peut pas être inférieure à la date de début")),
        Location__c: Yup.string().required(i18n.t('fields.required', { field: i18n.t('WorkExperience__c.Location__c') })),
        // Position__c: Yup.string().trim().required('Le poste est requis'),
        StartDate__c: Yup.date()
          .default(() => new Date())
          .typeError(i18n.t('fields.formatDate')),
      })
    ),
  }),
  null,
  //step 5
  Yup.object().shape({
    // account: Yup.object().shape({
    //   Copy_of_Diploma__c: Yup.boolean().when("AcademicLevel__pc", {
    //     is: "Terminale (BAC)",
    //     then: Yup.boolean(),
    //     otherwise: Yup.boolean()
    //       .required(i18n.t('supportingDocument.errors.bac_exam'))
    //       .oneOf([true], i18n.t('supportingDocument.errors.bac_exam')),
    //   }),
    //   Grant_Proof__c: Yup.boolean().when("Grant_Student__c", {
    //     is: true,
    //     then: Yup.boolean()
    //       .required(Grant_Proof__c.requiredErrorMsg)
    //       .oneOf([true], Grant_Proof__c.requiredErrorMsg),
    //     otherwise: Yup.boolean(),
    //   }),
    // }),
    SupportingDocument__c: Yup.array()
      .of(
        Yup.object().shape({
          // supportingDocument: Yup.string()
          //   .required()
          //   .test(
          //     "check-mandatory-supporting-document",
          //     i18n.t('supportingDocument.errors.upload_all_files'),
          //     async (supportingDocument) => {
          //       if (
          //         supportingDocument === i18n.t('supportingDocument.documents.0') &&
          //         !supportingDocumentList.includes(supportingDocument)
          //       ) {
          //         supportingDocumentList.push(supportingDocument);
          //       }
          //       if (
          //         supportingDocument === i18n.t('supportingDocument.documents.1') &&
          //         !supportingDocumentList.includes(supportingDocument)
          //       ) {
          //         supportingDocumentList.push(supportingDocument);
          //       }
          //       if (
          //         supportingDocument === i18n.t('supportingDocument.documents.2') &&
          //         !supportingDocumentList.includes(supportingDocument)
          //       ) {
          //         supportingDocumentList.push(supportingDocument);
          //       }
          //       if (
          //         supportingDocument === i18n.t('supportingDocument.documents.3') &&
          //         !supportingDocumentList.includes(supportingDocument)
          //       ) {
          //         supportingDocumentList.push(supportingDocument);
          //       }
          //       if (
          //         supportingDocument === i18n.t('supportingDocument.documents.4') &&
          //         !supportingDocumentList.includes(supportingDocument)
          //       ) {
          //         supportingDocumentList.push(supportingDocument);
          //       }

          //       if (supportingDocumentList.includes(supportingDocument)) {
          //         let index =
          //           supportingDocumentList.indexOf(supportingDocument);
          //         supportingDocumentList.splice(index, 1);
          //       }
          //       return true;
          //     }
          //   ),
          files: Yup.array()
            .required()
            .test({
              name: "check-files-type",
              message:
                i18n.t('supportingDocument.errors.accept_files'),
              test: (files) => {
                // console.log(files)
                let valid;
                if (files) {
                  valid = files.map((file) => {
                    // console.log(file.Id)
                    if (file.Id !== null && file.Id !== undefined) {
                      return true;
                    }
                    console.log(file.type)
                    if (
                      file.type === "" ||
                      file.type === null ||
                      ![
                        "application/pdf",
                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                        "image/png",
                        "image/jpg",
                        "image/jpeg",
                        "image/webp",
                      ].includes(file.type)
                    ) {
                      return false;
                    } else {
                      return true;
                    }
                  });
                }
                if (valid.includes(false)) {
                  return false;
                } else {
                  return true;
                }
              },
            })
            .test(
              "check-files-weight",
              i18n.t('supportingDocument.errors.max_size'),
              async (files) => {
                let valid;
                if (files) {
                  valid = files.map((file) => {
                    const size = file.size / 1024 / 1024;
                    if (size > 5) {
                      return false;
                    } else {
                      return true;
                    }
                  });
                }
                if (valid.includes(false)) {
                  return false;
                } else {
                  return true;
                }
              }
            )
            .min(1, i18n.t('supportingDocument.errors.minimum_files'))
            .max(5, i18n.t('supportingDocument.errors.maximum_files')),
        })
      )
      .required(i18n.t('supportingDocument.errors.upload_all_files2'))
      .test(
        "check-mandatory-supporting-document",
        i18n.t('supportingDocument.errors.upload_all_files'),
        async (supportingDocument, context) => {
          const arrayClone = [];
          if (
            supportingDocument.find(
              (x) => x.supportingDocument === i18n.t('supportingDocument.documents.0')
            ) !== undefined &&
            !arrayClone.includes(i18n.t('supportingDocument.documents.0'))
          ) {
            arrayClone.push(i18n.t('supportingDocument.documents.0'));
          }
          if (
            supportingDocument.find(
              (x) => x.supportingDocument === i18n.t('supportingDocument.documents.1')
            ) !== undefined &&
            !arrayClone.includes(i18n.t('supportingDocument.documents.1'))
          ) {
            arrayClone.push(i18n.t('supportingDocument.documents.1'));
          }
          if (
            supportingDocument.find(
              (x) => x.supportingDocument === i18n.t('supportingDocument.documents.2')
            ) !== undefined &&
            !arrayClone.includes(i18n.t('supportingDocument.documents.2'))
          ) {
            arrayClone.push(i18n.t('supportingDocument.documents.2'));
          }
          if (
            supportingDocument.find(
              (x) => x.supportingDocument === i18n.t('supportingDocument.documents.3')
            ) !== undefined &&
            !arrayClone.includes(i18n.t('supportingDocument.documents.3'))
          ) {
            arrayClone.push(i18n.t('supportingDocument.documents.3'));
          }
          if (
            supportingDocument.find(
              (x) => x.supportingDocument === i18n.t('supportingDocument.documents.4')
            ) !== undefined &&
            !arrayClone.includes(i18n.t('supportingDocument.documents.4'))
          ) {
            arrayClone.push(i18n.t('supportingDocument.documents.4'));
          }
          if (
            supportingDocument.find(
              (x) => x.supportingDocument === i18n.t('supportingDocument.documents.5')
            ) !== undefined &&
            !arrayClone.includes(i18n.t('supportingDocument.documents.5'))
          ) {
            arrayClone.push(i18n.t('supportingDocument.documents.5'));
          }
          const boursier = context.parent.account.Grant_Student__c
          if (boursier) {
            if (
              supportingDocument.find(
                (x) => x.supportingDocument === i18n.t('supportingDocument.documents.6')
              ) !== undefined &&
              !arrayClone.includes(i18n.t('supportingDocument.documents.6'))
            ) {
              arrayClone.push(i18n.t('supportingDocument.documents.6'));
            }
          }
          // if (supportingDocument.find(x => x.supportingDocument === "Diplômes obtenus") !== undefined && !arrayClone.includes("Diplômes obtenus")) {
          //   arrayClone.push("Diplômes obtenus")
          // }
          if (((context.parent.opportunity.Division__c === 'ISG BS France' && context.parent.opportunity.ProgramYear__c === '4') || context.parent.opportunity.Division__c === 'ISG MSC et MBA') && context.parent.opportunity.SessionCampus__c === 'Paris') {
            if (arrayClone.length >= 6) {
              return true;
            } else {
              return false;
            }
          } else {
            return true
          }

        }
      )
      // .test('check-files-weight-total', "Le poids total de l'ensemble de vos fichiers ne dois pas dépasser 10Mo", async (files) => {
      //   let sizes = 0
      //   if (files) {
      //     for (let i = 0; i < files.length; i++) {
      //       const pieces = files[i].files
      //       for (let j = 0; j < pieces.length; j++) {
      //         const file = pieces[j]
      //         const size = file.size / 1024 / 1024
      //         sizes += size
      //       }
      //     }
      //   }
      //   if ( sizes > 10 ) {
      //     return false
      //   }
      //   else {
      //     return true
      //   }
      // })
      // .min(3, i18n.t('supportingDocument.errors.all_type_document')),
  }),
   //step 5
   null,
   //step 6
   null
];

export default validationSchema;
