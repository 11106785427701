import { Box, CircularProgress, Grid } from "@mui/material"
import { useTranslation } from "react-i18next"

const Loading = ({ logo, school }) => {

  const { t } = useTranslation()

  return (
    <>
      <Grid alignItems="center" container direction="column" justifyContent="center">
        <Grid item xs={12}>
          {logo !== undefined && <Box
            alt={school}
            component="img"
            src={logo}
            sx={{  marginTop: 2, marginBottom: 2, height: 112, width: 112 }}
          >
          </Box>}
        </Grid>
        <Grid item xs={6}>
          <CircularProgress />
        </Grid>
        <Grid item xs={6}>
          {t('loading_form')}
        </Grid>
      </Grid>
    </>
  )
}

export default Loading
