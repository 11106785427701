import { loadStripe } from "@stripe/stripe-js"
import { Elements, useStripe } from "@stripe/react-stripe-js"
import { useState } from "react"
import { useEffect } from "react"
import CheckoutForm from "./CheckoutForm"
import { useDispatch, useSelector } from "react-redux"
import { updateStripeClientSecret } from "applicant-components/Store/salesforceSlice"
import { Alert } from "@mui/material"
import { STAGE_QUALIFICATION } from "applicant-components/services/StageNameOpportunity"

const stripePromise = loadStripe("pk_test_51K5927KlDU5jr6DEdEZBZO7RPbggTOf3FbkM15Ig9UsyLkBJb8Ca4hhM3yhxA1mJF6L7H22XXi4N0JUNH7YHAYyF00cNgsrHcZ")

const StripeContainer = () => {

  // const [clientSecret, setClientSecret] = useState('')
  // const stripe
  // const elements =
  const dispatch = useDispatch()
  const stageName = useSelector(state => state.salesforce.opportunity.StageName)
  const clientSecret = useSelector(stageName === STAGE_QUALIFICATION ? state => state.salesforce.paymentApplicationStripe.clientSecret : state => state.salesforce.paymentRegistrationStripe.clientSecret)
  const { Id: accountId, FirstName, LastName, PersonEmail } = useSelector(state => state.salesforce.account)
  const { Id: opportunityId, ProgramFullName__c, ProgramYear__c } = useSelector(state => state.salesforce.opportunity)
  const campaignMemberId = useSelector(state => state.salesforce.campaignMember.Id)
  const paymentStripe = useSelector(stageName === STAGE_QUALIFICATION ? state => state.salesforce.paymentApplicationStripe.payment : state => state.salesforce.paymentRegistrationStripe.payment)
  const [error, setError] = useState(false)

  useEffect(() => {
    // const createPayment = async () => {
    //   const { error, paymentMethod } = await stripe.createPaymentMethod({
    //     type: 'card',
    //     card: elements
    //   })

    //   return paymentMethod
    // }

    if (clientSecret === '' && !paymentStripe) {

      // createPayment().then((val) => {
      //   console.log(val)
      // })
      fetch('http://localhost:8083/stripe/isg/create-payment-intent', {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          description: `Paiement ${stageName === STAGE_QUALIFICATION ? 'candidature': 'inscription'} ${FirstName} ${LastName} ${ProgramFullName__c} ${ProgramYear__c}`,
          metadata: {
            application_type: stageName === STAGE_QUALIFICATION ? 'candidature': 'inscription',
            salesforce_account_id: accountId,
            salesforce_opportunity_id: opportunityId,
            // salesforce_campaign_member_id: campaignMemberId,
            salesforce_account_url: `https://ionis--devrun.sandbox.lightning.force.com/${accountId}`,
            salesforce_opportunity_url: `https://ionis--devrun.sandbox.lightning.force.com/${opportunityId}`
          },
          receipt_email: PersonEmail
        })
      })
      .then((res) => res.json())
      .then((data) => dispatch(updateStripeClientSecret({
        clientSecret: data.clientSecret,
        type: stageName === STAGE_QUALIFICATION ? 'candidature': 'inscription'
      })))
      .catch(() => setError(true))
    }
  }, [])

  const appearance = {
    theme: 'stripe'
  }

  const options = {
    clientSecret,
    appearance
  }

  return (
    <>
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      )}
      {error && <Alert severity="error">
        Une erreur est survenue lors de l'affichage du paiement. Veuillez ré-essayer plus tard.
      </Alert>}
    </>
  )
}

export default StripeContainer
