import { Typography } from "@mui/material"
import ForgotPasswordCmp from "applicant-components/components/IonisConnect/Forms/ForgotPassword"
import { useTranslation } from "react-i18next"

const ForgotPassword = () => {

  const { t } = useTranslation()

  return (
    <>
      <Typography gutterBottom variant="h5">{t('ionisConnect.password_forgotten')}</Typography>

      <Typography gutterBottom variant="h6">{t('ionisConnect.password_forgotten2')}.</Typography>
      <ForgotPasswordCmp />
    </>
  )
}

export default ForgotPassword
