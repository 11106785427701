import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Snackbar } from "@mui/material";
import { ConfirmationEmail, Login, Registration } from "./applicant-components/components/IonisConnect"
import MaterialLayout from "./components/Layout/MaterialLayout";
import { Loading } from "./applicant-components/components/Loading"
import { SalesforceGetData, isLogged } from "./applicant-components/Store/salesforceSlice"
import Portal from "./components/Portal";
import { IdleTimeoutManager } from "idle-timer-manager";
import ApplicationPortal from "applicant-components/components/ApplicationPortal";
import "./applicant-components/services/i18n"
import IsOnline from "./applicant-components/components/IsOnline"
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { hide, show } from "./applicant-components/Store/buttonsSlice"
import persistStore from "redux-persist/es/persistStore";
import store from "applicant-components/Store/Store";
import LoginLayout from "./components/Layout/LoginLayout";
import { CandidaturePaiementKO, CandidaturePaiementOK, ConfirmationCandidature, ForgotPassword, InscriptionEnLigne, ModifyPassword, NotFound, ReglementFraisJPC } from "./pages"
import JPC from "./components/Buttons/JPC"
import LogicYear from "./services/LogicYear"
import NewApplication from "./components/Buttons/NewApplication"
import NewSupportingDocument from "./components/Buttons/NewSupportingDocument"
import Summary from "./components/Summary"
import { RegistrationForm } from "applicant-components/components/Registration"
import ConfirmationInscription from "pages/confirmation-inscription"
import { Registered, RegistrationPortal } from "applicant-components/components/RegistrationPortal"
import { MultipleAccounts } from "applicant-components/components/MultipleAccounts"
import PDF from "applicant-components/components/PDF"
import RegistrationLayout from "components/Layout/RegistrationLayout"

function App() {
  const dispatch = useDispatch();
  const isSalesforceDataRetrieve = useSelector(
    (state) => state.salesforce.isSalesforceDataRetrieve
  );
  const loading = useSelector((state) => state.salesforce.status);
  const userLogged = useSelector((state) => state.salesforce.isLogged);
  const contentDocuments = useSelector(state => state.salesforce.opportunity.RegistrationConsentDocuments__c)
  const documents = useSelector(state => state.salesforce.documents)
  const hasLoginOnce = useSelector(state => state.salesforce.hasLoginOnce)
  const hasAccounts = useSelector(state => state.salesforce.hasAccounts)
  const hasOneOppyCompleted = useSelector((state) => state.salesforce.hasOneOppyCompleted);
  const hasOneOppyToBeRegistered = useSelector(state => state.salesforce.hasOneOppyToBeRegistered)
  const hasOneOppyRegisteredFinished = useSelector(state => state.salesforce.hasOneOppyRegisteredFinished)
  const isRegistered = useSelector(state => state.salesforce.isRegistered)
  const currentStage = useSelector(
    (state) => state.salesforce.account.StageStop__c
  );
  const paymentType = useSelector(state => state.salesforce.paymentType)
  const paymentStripe = useSelector(state => state.salesforce.paymentRegistrationStripe.payment)
  let persistor = persistStore(store);
  const [open, setOpen] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false)


  useEffect(() => {
    if (isSalesforceDataRetrieve !== true && loading !== "success") {
      dispatch(SalesforceGetData());
    }

    if (!navigator.cookieEnabled) {
      setSnackOpen(true)
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      (((paymentType === 'Candidature' && currentStage === 4) ||
      (paymentType === 'Candidature' && currentStage === "4") ||
      (paymentType === 'Candidature' && currentStage === 3) ||
      (paymentType === 'Candidature' && currentStage === "3") ||
      (paymentType === 'JPC' && currentStage === 4) ||
      (paymentType === 'JPC' && currentStage === "4") ||
      (paymentType === 'JPC' && currentStage === 5) ||
      (paymentType === 'JPC' && currentStage === "5")) && !hasOneOppyCompleted) ||
      (hasOneOppyToBeRegistered && !paymentStripe && (currentStage === 6 || currentStage === "6"))
    ) {
      dispatch(hide());
    } else {
      dispatch(show());
    }
  }, [currentStage, paymentType, hasOneOppyToBeRegistered]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setOpen(false)
    if (userLogged && navigator.cookieEnabled) {
      const manager = new IdleTimeoutManager({
        timeout: 30 * 3600,
        onExpired: (time) => {
          persistor.purge().then(() => {
            dispatch(isLogged(false));
            setOpen(true);
          });
        },
      });

      return () => {
        manager.clear();
      };
    }
  }, [userLogged]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setSnackOpen(false)
  }


  return (
    <div>
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        autoHideDuration={5000}
        open={open}
      >
        <Alert
          elevation={6}
          severity="info"
          sx={{ width: "100%" }}
          variant="filled"
        >
          Vous avez été déconnecté après une inactivité de 30 minutes.
        </Alert>
      </Snackbar>

      <Snackbar anchorOrigin={{ horizontal: 'center', vertical: 'top'}} onClose={handleCloseSnack} open={snackOpen}>
        <Alert elevation={6} onClose={handleCloseSnack} severity="info" sx={{ width: '100%' }} variant="filled">
          Il semblerait que vous ayez désactivé les cookies sur votre navigateur. Pour une expérience optimale, merci d'autoriser les cookies pour le domaine <strong>join.isg.fr</strong>.
        </Alert>
      </Snackbar>

      <IsOnline />
      <Router>
        <MaterialLayout>
          { loading === 'loading' && <Loading logo="/logo-isg.jpg" school="ISG" /> }
          { loading === 'failed' && <Alert elevation={3} severity="error">Une erreur est survenue lors du chargement du formulaire. Veuillez rafraichir la page ou revenir dans quelques instants.<br /><br />Si le problème persiste, veuillez vérifier si le domaine <strong>ionis.my.salesforce.com</strong> est bloqué par une des extensions de votre navigateur car celui-ci doit être autorisé pour accéder à cette page.</Alert> }
          <Switch>
            <Route exact path="/">
              {loading === "success" && !userLogged && !hasAccounts && (
                <LoginLayout>
                  <Login />
                </LoginLayout>
              )}
              {/* {loading === "success" && hasAccounts && (
                <MultipleAccounts />
              )} */}
              {loading === "success" && userLogged && !hasOneOppyCompleted && !hasLoginOnce && (
                <Portal />
              )}
              {loading === "success" && userLogged && (hasOneOppyCompleted || hasLoginOnce) && !hasOneOppyToBeRegistered && !isRegistered && (
                <ApplicationPortal JPC={JPC} LogicYear={LogicYear} NewApplication={NewApplication} NewSupportingDocument={NewSupportingDocument} Summary={Summary} />
              )}
              {/* {loading === "success" && userLogged && hasOneOppyToBeRegistered && !hasOneOppyRegisteredFinished && !contentDocuments && (
                <PDF documents={documents} />
              )} */}
              {/* {loading === "success" && userLogged && hasOneOppyToBeRegistered && !hasOneOppyRegisteredFinished && (
                <RegistrationForm />
              )} */}
              {/* {loading === "success" && userLogged && hasOneOppyRegisteredFinished && (
                <RegistrationPortal />
              )} */}
              {/* {loading === "success" && userLogged && isRegistered && (
                <Registered />
              )} */}
            </Route>
            <Route exact path="/inscription">
              {loading === "success" && !userLogged && (
                <RegistrationLayout>
                  <Registration />
                </RegistrationLayout>
              )}
            </Route>
            <Route exact path="/connexion">
              {loading === "success" && !userLogged && (
                <LoginLayout>
                  <Login />
                </LoginLayout>
              )}
            </Route>
            <Route exact path="/confirmation-email">
              {loading === "success" && <ConfirmationEmail />}
            </Route>
            <Route exact path="/mot-passe-oublie">
              {loading === "success" && <ForgotPassword />}
            </Route>
            <Route exact path="/modification-mot-passe">
              {loading === "success" && <ModifyPassword />}
            </Route>
            <Route exact path="/confirmation-candidature">
              {loading === "success" && <ConfirmationCandidature />}
            </Route>
            <Route exact path="/inscription-en-ligne">
              { loading === "success" && <InscriptionEnLigne />}
            </Route>
            <Route exact path="/confirmation-inscription">
              {loading === "success" && <ConfirmationInscription />}
            </Route>
            <Route exact path="/reglement-frais-jpc">
              {loading === "success" && userLogged && <ReglementFraisJPC />}
            </Route>
            <Route exact path="/candidature-paiement-ok">
              {loading === "success" && <CandidaturePaiementOK />}
            </Route>
            <Route exact path="/candidature-paiement-ko">
              {loading === "success" && <CandidaturePaiementKO />}
            </Route>
            <Route>{loading === "success" && <NotFound />}</Route>
          </Switch>
        </MaterialLayout>
      </Router>
    </div>
  );
}

export default App;
