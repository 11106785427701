import { FastField, FieldArray, useField } from "formik"
import { Alert, Button, Grid, Paper } from "@mui/material"
import { InputExample } from "../FormFields/InputExample"
import { SelectField } from "../FormFields"
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import React from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

const DiplomaItem = ({ index }) => {

  const [EstablishmentNameField] = useField({ name: `AcademicInformation__c[${index}].EstablishmentName__c`})
  const [LocationField] = useField({ name: `AcademicInformation__c[${index}].Location__c`})
  const [YearOfGraduationField] = useField({ name: `AcademicInformation__c[${index}].YearOfGraduation__c`})
  const optionsCountries = useSelector(state => state.salesforce.countries)
  const currentYear = new Date().getFullYear()
  const { t } = useTranslation()

  return (
    <>
      <Grid item sm={4} xs={12}>
        <FastField component={InputExample} label={t('AcademicInformation__c.EstablishmentName__c')} name={EstablishmentNameField.name} required />
      </Grid>
      <Grid item sm={4} xs={12}>
        <SelectField data={optionsCountries} fullWidth label={t('AcademicInformation__c.Location__c')} name={LocationField.name} />
      </Grid>
      <Grid item sm={3} xs={12}>
        <FastField component={InputExample} InputProps={{ inputProps: { min: 1970, max: currentYear } }} label={t('AcademicInformation__c.YearOfGraduation__c')} name={YearOfGraduationField.name} type="number" />
      </Grid>
    </>
  )
}

const Diplomas = (props) => {

  const accountId = useSelector(state => state.salesforce.account.Id)
  const { t } = useTranslation()

  return (
    <FieldArray name="AcademicInformation__c">
      {({ form, ...fieldArrayHelpers }) => {
        const onAddClick = (e) => {
          e.preventDefault()
          fieldArrayHelpers.push({
            // Id: form.values.AcademicInformation__c.length,
            Applicant__c: accountId,
            EstablishmentName__c: '',
            Location__c: '',
            YearOfGraduation__c: ''
          })
        }
        const onRemoveClick = (e, index) => {
          e.preventDefault()
          fieldArrayHelpers.remove(index)
        }
        return (
          <>
            {form.values.AcademicInformation__c.length === 0 && <Alert elevation={6} severity="info">{t('diplomas.info')} <strong>{t('diplomas.button', { other: '' })}</strong>.</Alert>}
            <Paper sx={{ display: form.values.AcademicInformation__c.length === 0 ? 'none' : 'block' }}>
              <Grid alignItems="center" container spacing={3} sx={{ m:0, pl: 0, pr: 12, pt: 3, pb: 3 }}>
                {form.values.AcademicInformation__c.map((diploma, index) => (
                  <React.Fragment key={index}>
                    <DiplomaItem
                    index={index}
                    />
                    <Grid item sm={1}>
                      <Button color="error" onClick={(e) => { onRemoveClick(e, index)}} startIcon={<DeleteForeverIcon />} variant="text">{t('diplomas.delete')}</Button>
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>
            </Paper>
            <Button onClick={onAddClick} variant="outlined" sx={{ mt: 2 }}>{t('diplomas.button', { other: form.values.AcademicInformation__c.length > 0 ? t('diplomas.other') : '' })}</Button>
          </>
        )
      }}
    </FieldArray>
  )
}

export default Diplomas
