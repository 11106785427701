import { AppBar, Box, Button, Toolbar } from "@mui/material"
import { useSelector } from "react-redux"
// import { updateIonisConnect } from "../../Store/salesforceSlice"
import useStyles from "./styles"
import store from '../../Store/Store'
import { persistStore } from "redux-persist"
import { Language } from "../Buttons"
import Parameters from "../Buttons/Parameters"
import { useHistory } from "react-router"
import { useTranslation } from "react-i18next"
import Help from "../Buttons/Help"

const Header = ({ logo, school }) => {
  const classes = useStyles()
  const history = useHistory()
  // const isLogged = useSelector(state => state.salesforce.isLogged)
  // const isLogin = useSelector(state => state.salesforce.isLogin)
  let persistor = persistStore(store)
  const { t } = useTranslation()

  // const registration = () => {
  //   history.push('/inscription')
  // }

  // const login = ()  => {
  //   history.push('/connexion')
  // }

  const logout = () => {
    persistor.purge().then(() => {
      document.location.href = '/'
    })
  }

  return (
    <AppBar position="sticky" color="default" className={classes.appBar}>
      <Toolbar>
        <Box
          alt={school}
          component="img"
          src={logo}
          sx={{ display: 'flex', marginTop: 2, marginBottom: 2, height: 64, width: 64 }}
        >
        </Box>
        <Box sx={{ display: 'flex', width: '100%', flexWrap: 'wrap', justifyContent: 'flex-end'}}>
          {/* <Language /> */}
          <Help />
          <Parameters />
          <Button color="secondary" onClick={logout} variant="outlined">{t('logout')}</Button>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default Header
