import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Divider, Grid } from "@mui/material"
import ExpandMoreIcon  from "@mui/icons-material/ExpandMore"
import { useDispatch, useSelector } from "react-redux"
import ContactCommercial from "./ContactCommercial"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { STAGE_ADMIS, STAGE_COMPLETE, STAGE_COURS, STAGE_ETUDE, STAGE_INSCRIPTION, STAGE_QUALIFICATION, STAGE_WAIT_ATTACHMENTS } from "applicant-components/services/StageNameOpportunity"
import Salesforce from "applicant-components/services/Salesforce"
import { hasOneOppyToBeRegistered, SalesforceUpdateStageStop, updateOpportunities, updateOpportunity } from "../../Store/salesforceSlice"
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
// import NewSupportingDocument from "../Buttons/NewSupportingDocument"

const Opportunities = ({ opportunities, opportunitiesAddmited, LogicYear, NewSupportingDocument }) => {

  const { t } = useTranslation()

  const renderContentOpportunity = (opportunity) => {
    const data = LogicYear.find(el => el.division === opportunity.Division__c)
    const year = data.years.find(el => el.year.value === opportunity.ProgramYear__c)
    const rentree = moment(opportunity.SessionStartDate__c).format('MMMM yy')
    return (
        <ul style={{ marginLeft: '20px', marginBottom: '10px' }}>
          <li>{t('applicationPortal.program')} : {data.name}</li>
          <li>{t('applicationPortal.year')} : {year.year.label}</li>
          <li>{t('applicationPortal.campus')} : {opportunity.SessionCampus__c}</li>
          <li>{t('applicationPortal.rentree')} : {rentree}</li>
        </ul>
    )
  }

  return (
    <>
      {opportunitiesAddmited.length === 0 && opportunities.map((opportunity, index) => (
        <Accordion key={index}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}a-content`}
            id={`panel${index}a-header`}
          >
            <Grid container>
              <Grid item sm={9} xs={12}>
                {opportunity.ProgramFullName__c} { opportunity.SessionCampus__c}
              </Grid>
              <Grid item sm={3} xs={12}>
                {Salesforce.getChip(opportunity.StageName)}
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            {renderContentOpportunity(opportunity)}
            <Divider sx={{ mt: 2, mb: 2 }} />
            <NewSupportingDocument opportunity={opportunity} />
            <Divider sx={{ mt: 2, mb: 2 }} />
            <ContactCommercial opportunityId={opportunity.Id} />
          </AccordionDetails>
        </Accordion>
      ))}
      <Divider variant="middle" sx={{ my: 3 }} />
    </>
  )
}

export default Opportunities
