import { at } from 'lodash';
import { useField, useFormikContext } from 'formik';
import { TextField } from '@mui/material';
import { MuiTelInput } from 'mui-tel-input'

export default function PhoneField(props) {
  const { errorText, ...rest } = props;
  const [field, meta] = useField(props);

  function _renderHelperText() {
    const [touched, error] = at(meta, 'touched', 'error');
    if (touched && error) {
      return error;
    }
  }

  const formik = useFormikContext()

  const handleTelChange = (newValue) => {
    formik.setFieldValue(props.name, newValue)
  }

  return (
    <MuiTelInput
      aria-label={field.name}
      autoComplete="off"
      defaultCountry="FR"
      error={meta.touched && meta.error && true}
      fullWidth
      helperText={_renderHelperText()}
      label={props.label}
      langOfCountryName="FR"
      name={props.name}
      onChange={handleTelChange}
      preferredCountries={['FR', 'BE', 'CH']}
      value={props.name === 'account.Phone' ? formik.values.account.Phone : formik.values.account.PersonMobilePhone}
      // {...field}
      // {...rest}
    />
    // <TextField
    //   autoComplete="off"
    //   type="text"
    //   error={meta.touched && meta.error && true}
    //   helperText={_renderHelperText()}
    //   {...field}
    //   {...rest}
    // />
  );
}
