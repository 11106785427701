export const STAGE_QUALIFICATION = 'Qualification'
export const STAGE_COURS = 'En cours'
export const STAGE_WAIT_ATTACHMENTS = 'En attente de pièces complémentaires'
export const STAGE_COMPLETE = 'Candidature Complète'
export const STAGE_ETUDE = 'En étude'
export const STAGE_ADMIS = 'Admis'
export const STAGE_INSCRIPTION = 'En cours d\'inscription'
export const STAGE_INSCRIT = 'Inscrit'
export const STAGE_QUALIFICATION_POURCENT = 10
export const STAGE_COURS_POURCENT = 30
export const STAGE_WAIT_ATTACHMENTS_POURCENT = 35
export const STAGE_COMPLETE_POURCENT = 50
export const STAGE_ETUDE_POURCENT = 60
export const STAGE_ADMIS_POURCENT = 70
export const STAGE_INSCRIPTION_POURCENT = 80
export const STAGE_INSCRIT_POURCENT = 100

export const APPLICATION_STAGES = [STAGE_COMPLETE, STAGE_COURS, STAGE_ETUDE, STAGE_QUALIFICATION, STAGE_WAIT_ATTACHMENTS]
export const isApplicant = (stage) => APPLICATION_STAGES.includes(stage)

export const REGISTERED_STAGES = [STAGE_INSCRIPTION, STAGE_INSCRIT]
export const isRegistered = (stage) => REGISTERED_STAGES.includes(stage)