import { Box, Button, CircularProgress } from "@mui/material";
import { Alert, AlertTitle } from "@mui/material";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { SalesforceLoadAccountData, updateIonisConnect, updateStatus } from "../../Store/salesforceSlice";
import queryString from "query-string";
import { useEffect, useRef, useState } from "react";
import Salesforce from "../../services/Salesforce";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next"
import { setUser } from "applicant-components/services/Errors";
// import useStyles from "./styles";

const ConfirmationEmail = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const campaigns = useSelector((state) => state.salesforce.campaigns);
  // const classes = useStyles();
  const [error, setError] = useState("erreur");
  const [validate, setValidate] = useState(false);
  const [account, setAccount] = useState({});
  // const token = useSelector(state => state.salesforce.token)
  const token_validation = queryString.parse(window.location.search).token;
  const mountedRef = useRef(true);
  const { t } = useTranslation()

  useEffect(() => {
    async function verifyConfirmationToken() {
      console.log("token_validation : ", token_validation);
      const response = await Salesforce.verifyConfirmationToken(
        token_validation
      ).catch((e) => {
        if (!mountedRef.current) return null;
        setError(e);
        setValidate(true)
      });
      if (response && Object.keys(response).length > 0) {
        setAccount(response);
        setError('');
        setValidate(true);
      }

      dispatch(updateIonisConnect(false));
    }
    console.log("tokens : ", token_validation);
    if (token_validation !== "") {
      verifyConfirmationToken();
    }

    if (token_validation === "") {
      setError("erreur");
    }

    return () => { mountedRef.current = false }

  }, [token_validation]) // eslint-disable-line react-hooks/exhaustive-deps


  const handleClick = () => {
    if (error === '' && validate) {
      console.log('Loading user account')
      setUser(account.Id, account.PersonEmail);
      dispatch(SalesforceLoadAccountData({account, campaigns}));
    }
    history.push('/')
  }


  return (
    <>
      <Box mb={2}>
        { !validate && <Alert action={
          <CircularProgress color="info" size={20} />
        } severity="info" variant="outlined">
          <AlertTitle>{t('ionisConnect.email_confirmation')}</AlertTitle>
          {t('ionisConnect.email_confirmation2')}.
        </Alert>
        }
        { error === '' && validate &&
        <Alert severity="success" variant="outlined" aria-label="email-confirmation-succeeded-alert">
          <AlertTitle>{t('ionisConnect.email_validated')}</AlertTitle>
          {t('ionisConnect.email_validated2')}.
        </Alert>
        }
        { error !== '' && validate &&
        <Alert severity="error" variant="outlined" aria-label="email-confirmation-failed-alert">
          <AlertTitle>{t('ionisConnect.email_invalidated')}</AlertTitle>
          {t('ionisConnect.email_invalidated2')}.
        </Alert>
        }
      </Box>

      {validate && (
        <Button
          component={Link}
          color="primary"
          variant="contained"
          onClick={handleClick}
          aria-label="back-to-form-button"
        >
          {t('ionisConnect.back_form')}
        </Button>
      )}
    </>
  );
};

export default ConfirmationEmail;
