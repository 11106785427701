import * as Yup from "yup"
import ionisConnectFormModel from "./ionisConnectFormModel"

const {
  formField: {
    cgu,
    email,
    password,
    passwordConfirm
  }
} = ionisConnectFormModel

const validationSchema =
  Yup.object().shape({
    [cgu.name]: Yup.bool().oneOf([true], cgu.requiredErrorMsg),
    [email.name]: Yup.string().email(`${email.invalidEmail}`).max(80, `${email.maxLengthErrorMsg}`).required(`${email.requiredErrorMsg}`),
    [password.name]: Yup.string().trim().min(8, password.invalidErrorMsg).required(`${password.requiredErrorMsg}`),
    [passwordConfirm.name]: Yup.string().trim().min(8, passwordConfirm.invalidErrorMsg).required(`${passwordConfirm.requiredErrorMsg}`).oneOf([Yup.ref('password'), null], `${passwordConfirm.passwordDontMatch}`),
  })

export default validationSchema
