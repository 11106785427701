import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { Alert, AlertTitle } from "@mui/material";
import { Form, Formik } from "formik";
import { CheckboxField, DatePickerField, InputField, SelectField } from "../../FormFields";
import validationSchema from "../FormModel/validationSchema";
import ionisConnectFormModel from "../FormModel/ionisConnectFormModel";
import formInitialValues from "../FormModel/formInitialValues";
import useStyles from "../styles";
import { useSelector, useDispatch } from "react-redux";
import Salesforce from "../../../services/Salesforce";
import { updateIonisConnect } from "../../../Store/salesforceSlice";
import { useEffect, useState } from "react";
// import Cookie from "js-cookie"
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next"
import { Language } from "../../Buttons"

const Registration = () => {
  const [criticalErrors, setCriticalErrors] = useState(false);
  const classes = useStyles();
  const { formId, formField } = ionisConnectFormModel;
  const {
    academicLevel,
    birthdate,
    cgu,
    email,
    firstName,
    lastName,
    nationality,
    optIn,
    password,
    passwordConfirm,
  } = formField;

  const history = useHistory();
  const recordTypeIdAccount = useSelector(
    (state) => state.salesforce.recordTypeAccount
  );
  const division = useSelector((state) => state.salesforce.division);
  const optionsAcademicLevels = useSelector(
    (state) => state.salesforce.academicLevels
  );
  const optionsNationalities = useSelector(
    (state) => state.salesforce.nationalities
  );
  const dispatch = useDispatch();
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(updateIonisConnect(false));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const _handleSubmit = async (values, actions) => {
    const user = await Salesforce.checkUser(values.email, division).catch(
      (e) => {
        setCriticalErrors(true);
        actions.setSubmitting(false);
      }
    );
    if (!user.exist) {
      // Création du compte
      const createUser = await Salesforce.createUser(
        values,
        recordTypeIdAccount
      ).catch((e) => {
        setCriticalErrors(true);
        actions.setSubmitting(false);
      });
      actions.setStatus({ userCreated: createUser });
    } else {
      actions.setStatus({ userExist: true });
    }
    actions.setSubmitting(false);
  };

  const switchToLogin = () => {
    history.push("/connexion");
  };

  return (
    <div className={classes.registration}>
      {/* <Box className={classes.registration_language}>
        <Language />
      </Box> */}
      {/* <Typography align="center" className={classes.registration_h2} gutterBottom variant="h4">
        {t('ionisConnect.profile_creation')}
      </Typography> */}
      <Formik
        initialValues={formInitialValues}
        validationSchema={validationSchema}
        onSubmit={_handleSubmit}
      >
        {({ isSubmitting, status, values }) => (
        <Form id={formId} aria-label={formId}>
          <Grid container spacing={3}>
            <Grid item md={3} xs={12}>
              <InputField fullWidth label={t(firstName.name)} name={firstName.name}/>
            </Grid>
            <Grid item md={3} xs={12}>
              <InputField fullWidth label={t(lastName.name)} name={lastName.name} />
            </Grid>
            <Grid item md={3} xs={12}>
              <SelectField data={optionsAcademicLevels} fullWidth label={t(academicLevel.name)} name={academicLevel.name} />
            </Grid>
            <Grid item md={3} xs={12}>
              <DatePickerField fullWidth label={t(birthdate.name)} datemax={new Date()} name={birthdate.name} />
              {/* <SelectField data={optionsNationalities} fullWidth label={t(nationality.name)} name={nationality.name} /> */}
            </Grid>
            <Grid item xs={12}>
              <InputField fullWidth label={t(email.name)} name={email.name} type="email" />
            </Grid>
            <Grid item xs={12}>
              <InputField fullWidth label={t(password.name)} name={password.name} type="password" />
            </Grid>
            <Grid item xs={12}>
              <InputField fullWidth label={t(passwordConfirm.name)} name={passwordConfirm.name} type="password" />
            </Grid>
            <Grid item xs={12}>
              <CheckboxField label={<div>{t('ionisConnect.cgu')} <Link className={classes.link} to={{ pathname: 'https://www.isg.fr/mentions-legales' }} tabIndex={-1} target="_blank">{t('ionisConnect.cgu2')}</Link> {t('ionisConnect.cgu3')}.</div>} name={cgu.name} />
              <CheckboxField label={<div>{t('ionisConnect.optin')}.</div>} name={optIn.name} />
            </Grid>
          </Grid>
          <div className={classes.buttons}>
            <Button className={classes.button} color="primary" onClick={switchToLogin} tabIndex={-1} variant="outlined">{t('login')}</Button>
            <div className={classes.wrapper}>
            <Button className={classes.button} color="primary" disabled={isSubmitting || (status && status.userCreated)} type="submit" variant="contained" aria-label="register">
              {t('register')}
            </Button>
            {isSubmitting && (
              <CircularProgress className={classes.buttonProgress} size={24} />
            )}
            </div>
          </div>
          { status && status.userCreated && 
            <div aria-label="confirmation-email-sent">
              <Box mt={2} mb={2}>
                <Alert severity="warning" variant="standard">
                {t('ionisConnect.confirmation_email')} <strong>{values.email}</strong>.<br />{t('ionisConnect.confirmation_email2')}.<br /><br />
                Vous n'avez pas reçu le mail sur l'adresse <strong>{values.email}</strong> ?
                <ul>
                    <li>Vérifiez que vous avez saisi la bonne adresse.</li>
                    <li>Vérifiez dans votre boite SPAM ou Courrier Indésirables, il arrive parfois que votre fournisseur d’accès le dépose dans ce dossier. Si c’est le cas cliquez sur « Ne pas considérer comme Spam ».</li>
                    <li><a href="https://www.isg.fr/contacts-ecole/" rel="noreferrer" style={{ 'color' : 'inherit' }} target="_blank">Contactez le campus</a> si vous n’avez pas reçu le mail après ces vérifications.</li>
                </ul>
                </Alert>
              </Box>
              <Button className={classes.button} color="primary" onClick={switchToLogin} variant="contained">{t('ionisConnect.validate_email')}</Button>
            </div>
          }
          { status && status.userExist && <div><Box mt={2} mb={2}><Alert severity="warning" variant="outlined">{t('ionisConnect.account_exist')}.</Alert></Box>

              <Button className={classes.button} color="primary" onClick={switchToLogin} variant="contained">{t('login')}</Button>

            </div>
          }
          {criticalErrors && (
            <Alert severity="error" sx={{ mt: 2 }} variant="outlined" aria-label="email-already-associated-alert">
              <AlertTitle>{t('error_form')}</AlertTitle>
              {t('error_form2')}
            </Alert>
          )}
        </Form>
        )}
      </Formik>
    </div>
  )
}

export default Registration

