import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import { CheckboxField } from "applicant-components/components/FormFields"
import { useFormikContext } from "formik"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import useStyles from "../Portal/styles"

const MscGrantStudent = ({ closePopupMsc, Grant_Student__c, openPopup }) => {

  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { t } = useTranslation()

  const handleClose = () => {
    setOpen(false)
    // formikProps.setValues('account.Grant_Student__c', true)
    formikProps.setFieldValue(Grant_Student__c.name, true)
    closePopupMsc(false)
  }

  const formikProps = useFormikContext()

  const handleNoGrantStudent = () => {
    setIsSubmitting(true)
    closePopupMsc(false)
  }

  useEffect(() => {
    if (openPopup !== undefined) {
      setOpen(openPopup)
    }
  }, [openPopup])

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth="md"
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">
          Le programme Masters of Science & MBA ne permet pas d’accueillir des étudiants boursiers de l’enseignement supérieur
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Si vous souhaitez poursuivre, décochez la case boursier et cliquez sur <strong>Suivant</strong> ou bien cliquez sur <strong>Annuler</strong>.

          <CheckboxField label={t(Grant_Student__c.name)} name={Grant_Student__c.name} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className={classes.button} onClick={handleClose}>Annuler</Button>
          <div className={classes.buttons}>
            <div className={classes.wrapper}>
              <Button className={classes.button} disabled={isSubmitting || formikProps.values.account.Grant_Student__c} onClick={handleNoGrantStudent} variant="contained">
                Suivant
              </Button>
              {isSubmitting && (
                <CircularProgress className={classes.buttonProgress} size={24} />
              )}
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default MscGrantStudent
