import { Alert, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper } from "@mui/material"
import { Box } from "@mui/system"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"
import Salesforce from "applicant-components/services/Salesforce"
import useStyles from "../Portal/styles"
import { Link } from "react-router-dom"
import { updateCampaignId, updateCampaignMember } from "applicant-components/Store/salesforceSlice"
import { useTranslation } from "react-i18next"

const JPC = () => {

  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [jpcAvailable, setJPCAvailable] = useState(true)
  const [campusJPC, setCampusJPC] = useState('')
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const { SessionCampus__c: campus } = useSelector(state => state.salesforce.opportunity)
  const accountId = useSelector(state => state.salesforce.account.PersonContactId)
  const campaignId = useSelector(state => state.salesforce.campaignId)
  const campaignMember = useSelector(state => state.salesforce.campaignMember)
  const statusPayment = useSelector(state => state.salesforce.statusPayment)
  const campaigns = useSelector(state => state.salesforce.campaigns)
  const { t } = useTranslation()

  useEffect(() => {
    const campusHasJpc = campaigns.find(el => el.Campus__c === campus && el.Status === 'In Progress')
    const CampusJPC = campaigns.filter(el => el.Status === 'In Progress').map(el => el.Campus__c).sort().join(', ')
    setJPCAvailable(campusHasJpc !== undefined)
    setCampusJPC(CampusJPC)
    if (campusHasJpc !== undefined) {
      dispatch(updateCampaignId(campusHasJpc.Id))
    }
  }, [])

  const redirectToPayement = async () => {
    setIsSubmitting(true)
    const campaignMemberCreated = await Salesforce.createCampaignMember(accountId, campaignId)
    console.log(campaignMemberCreated)
    if (campaignMemberCreated) {
      dispatch(updateCampaignMember(campaignMemberCreated))
      setOpen(false)
      history.push('/reglement-frais-jpc')
    }
  }

  return (
    <>
      {campaignMember.id === undefined && campaignMember.Id === undefined && statusPayment !== 'OK' && <Paper sx={{ p : 2 }}>
        <p>{t('jpc.text', { campus: campus })}.<br /> <br />

        {t('jpc.text2')} :</p>

        <ul>
          <li>{t('jpc.list')}</li>
          <li>{t('jpc.list2')}</li>
          <li>{t('jpc.list3')}</li>
        </ul>

        {jpcAvailable === false && <Alert elevation={6} severity="warning">
          {campusJPC !== '' && <span>{t('application.jpc_closed')} {t('application.jpc_closed2')} <strong>{campus}</strong>. {t('application.jpc_closed3')} : <strong>{campusJPC}</strong>.</span>}
          {campusJPC === '' && <span>{t('application.jpc_closed')}.</span>}{' '}

          <a href="https://www.isg.fr/contacts-ecole" rel="noreferrer" style={{ 'color' : 'inherit' }} target="_blank">Cliquez ici</a> pour les coordonnées des campus.
        </Alert>}
        {jpcAvailable && <Button onClick={handleOpen} variant="contained">{t('jpc.button_participation')}</Button>}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {t('jpc.dialog_title')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {t('jpc.dialog_text')}.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button className={classes.button} onClick={handleClose}>{t('jpc.dialog_button_cancel')}</Button>
            <div className={classes.buttons}>
              <div className={classes.wrapper}>
                <Button className={classes.button} disabled={isSubmitting} onClick={redirectToPayement} variant="contained">{t('jpc.dialog_button_ok')}</Button>
                {isSubmitting && (
                  <CircularProgress className={classes.buttonProgress} size={24} />
                )}
              </div>
            </div>
          </DialogActions>
        </Dialog>
      </Paper>}

      {(campaignMember.id !== undefined || campaignMember.Id !== undefined) && statusPayment !== 'OK' &&

        <Box>
          <Alert elevation={6} severity="error">{t('jpc.error_jpc_not_paid')}.</Alert>

          <Button component={Link} to="/reglement-frais-jpc" sx={{ mt: 2 }} variant="contained">{t('jpc.button_pay_jpc')}</Button>
        </Box>
      }
    </>
  )
}

export default JPC
