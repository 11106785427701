import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import useStyles from "../Portal/styles"

const ConfirmationAttachments = ({ openPopup, closePopupAttachments }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
    closePopupAttachments(false)
  }

  const handleClosePopup = () => {
    closePopupAttachments(true)
  }

  useEffect(() => {
    if (openPopup !== undefined) {
      setOpen(openPopup)
    }
  }, [openPopup])

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t('popinApplicationAttachments.title')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('popinApplicationAttachments.text')} <strong>{t('popinApplicationAttachments.button_confirm')}</strong>, {t('popinApplicationAttachments.text2')}.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className={classes.button} onClick={handleClose}>{t('popinApplicationAttachments.button_cancel')}</Button>
          <div className={classes.buttons}>
            <div className={classes.wrapper}>
              <Button className={classes.button} onClick={handleClosePopup} variant="contained">
                {t('popinApplicationAttachments.button_confirm')}
              </Button>
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ConfirmationAttachments
