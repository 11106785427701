import {
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { Alert } from "@mui/material";
import { Form, Formik } from "formik";
import { InputField } from "../../FormFields";
import loginValidationSchema from "../FormModel/loginValidationSchema";
import ionisConnectFormModel from "../FormModel/ionisConnectFormModel";
import formInitialValues from "../FormModel/formInitialValues";
import useStyles from "../styles";
import Salesforce from "../../../services/Salesforce";
import { useDispatch, useSelector } from "react-redux";
import {
  getStatusPayment,
  hasAccounts,
  hasLoginOnce,
  hasOneOppyCompleted,
  hasOneOppyRegisteredFinished,
  hasOneOppyToBeRegistered,
  isLogged,
  isRegistered,
  SalesforceLoadAccountData,
  SalesforceUpdateStageStop,
  updateAcademicInformation,
  updateAccount,
  updateAccounts,
  updateCampaignMember,
  updateDocusignStatus,
  updateIonisConnect,
  updateIonisConnectId,
  updateOpportunities,
  updateOpportunity,
  updatePaymentType,
  updateStripePayment,
  updateSupportingDocument,
  updateWorkExperience,
} from "../../../Store/salesforceSlice";
import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { sendError, setUser } from "../../../services/Errors";
import { STAGE_ADMIS, STAGE_COMPLETE, STAGE_COURS, STAGE_ETUDE, STAGE_INSCRIPTION, STAGE_INSCRIT, STAGE_QUALIFICATION, STAGE_WAIT_ATTACHMENTS } from "../../../services/StageNameOpportunity"



const Login = () => {
  const classes = useStyles();
  const { formId, formField } = ionisConnectFormModel;
  const { email, password } = formField;

  const dispatch = useDispatch();
  const history = useHistory();
  const division = useSelector((state) => state.salesforce.division);
  const campaigns = useSelector((state) => state.salesforce.campaigns);
  const [criticalErrors, setCriticalErrors] = useState(false);
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(updateIonisConnect(true));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const _handleSubmit = async (values, actions) => {
    setCriticalErrors(false);
    actions.setStatus({ wrongPassword: false });

    const verifyUser = await Salesforce.verifyUser(
      values.email,
      values.password,
      division
    ).catch((e) => {
      sendError(e);
      setCriticalErrors(true);
    });

    if (verifyUser.passwordMatches) {
      const ionisConnectId = verifyUser.ionisConnectId;
      const accounts = await Salesforce.getAccount(
        ionisConnectId,
        values.email,
        division
      ).catch((e) => {
        sendError(e);
        setCriticalErrors(true);
      });

      let account = null

      if (accounts.accounts.length === 1) {
        account = accounts.accounts[0]
      } else {
        dispatch(updateAccounts(accounts.accounts))
        dispatch(hasAccounts())
        return false
      }

      setUser(account.Id, account.PersonEmail)
      dispatch(SalesforceLoadAccountData({account, campaigns}))
      history.push('/')

    } else {

      actions.setStatus({ wrongPassword: true });

    }
  }

  const _handleSubmit_DEPRECATED = async (values, actions) => {
    setCriticalErrors(false);
    actions.setStatus({ wrongPassword: false });
    const verifyUser = await Salesforce.verifyUser(
      values.email,
      values.password,
      division
    ).catch((e) => {
      console.log("ERROR", e);
      sendError(e);
      setCriticalErrors(true);
    });
    if (verifyUser.passwordMatches) {
      const ionisConnectId = verifyUser.ionisConnectId;
      const accounts = await Salesforce.getAccount(
        ionisConnectId,
        values.email,
        division
      ).catch((e) => {
        sendError(e);
        setCriticalErrors(true);
      });
      // if (account.ionisConnectDeactivated) { // @todo check si ça existe sur Salesforce.js
      //   actions.setStatus({ wrongPassword: true })
      //   return false
      // }
      let account = null
      if (accounts.accounts.length === 1) {
        account = accounts.accounts[0]
        console.log('Setting this account:', account)
      } else {
        dispatch(updateAccounts(accounts.accounts))
        dispatch(hasAccounts())
        return false
      }
      const campaignMember = await Salesforce.getCampaignMember(
        account.PersonContactId,
        campaigns.map(el => el.Id)
      ).catch((e) => {
        sendError(e);
        setCriticalErrors(true);
      });
      const opportunities = await Salesforce.getOpportunities(account.Id).catch(
        (e) => {
          sendError(e);
          setCriticalErrors(true);
        }
      );
      const academicInformation = await Salesforce.getAcademicInformation(
        account.Id
      ).catch((e) => {
        sendError(e);
        setCriticalErrors(true);
      });
      const workExperience = await Salesforce.getWorkExperience(
        account.Id
      ).catch((e) => {
        sendError(e);
        setCriticalErrors(true);
      });
      const supportingDocument = await Salesforce.getAttachmentFromSobject(
        account,
        opportunities[0]
      ).catch((e) => {
        sendError(e);
        setCriticalErrors(true);
      });
      // On initialise sentry en ajoutant des infos sur l'utilisateur
      setUser(account.Id, account.PersonEmail);
      if (account !== null) {
        // On met à jour la date de dernière connexion
        const webLast = account.WebLastCnx__c
        if (opportunities.length === 0 || opportunities.find(el => el.StageName === STAGE_COMPLETE || el.StageName === STAGE_COURS || el.StageName === STAGE_ETUDE || el.StageName === STAGE_QUALIFICATION || el.StageName === STAGE_WAIT_ATTACHMENTS)) {
          const date = (await Salesforce.updateLastConnection(account.Id, 'candidature')).data.WebLastCnx__c
          account.WebLastCnx__c = date
        }
        if (opportunities.find(el => el.StageName === STAGE_INSCRIPTION || el.StageName === STAGE_INSCRIT)) {
          const date = (await Salesforce.updateLastConnection(account.Id, 'inscription')).data.LastRegistrationCnx__c
          account.LastRegistrationCnx__c = date
        }
        dispatch(updateAccount(account));
        dispatch(updateCampaignMember(campaignMember));
        dispatch(updateAcademicInformation(academicInformation));
        dispatch(updateWorkExperience(workExperience));
        dispatch(hasLoginOnce(webLast))
        dispatch(updateIonisConnectId(ionisConnectId))
        console.log('User has accounts:', accounts.accounts)
        if (opportunities.length > 0) {
          console.log('User has created application')
          dispatch(getStatusPayment(opportunities));
          dispatch(updateOpportunities(opportunities));
          // Si le candidat a une candidature en cours d'inscription, on récupère celle-ci
          const oppyRegistration = opportunities.find(el => el.StageName === STAGE_INSCRIPTION)
          if (oppyRegistration !== undefined) {
            console.log('Application is in registration stage:', oppyRegistration)
            dispatch(updateOpportunity(oppyRegistration))
            const paymentRegistration = await Salesforce.getPaymentInfos(oppyRegistration.Id, 'inscription')
            if (paymentRegistration.success && paymentRegistration.data !== null && paymentRegistration.data.Payment__c) {
              console.log('User has paid registration fee', paymentRegistration.data.Payment__c)
              dispatch(updateStripePayment({
                payment: true,
                type: 'inscription'
              }))
            }
            // On récupère les status Docusign
            const statusDocusign = await Salesforce.getStatusDocusign(oppyRegistration.Id).catch((e) => {
            console.log('User signed Docusign documents:', statusDocusign)
            sendError(e);
            setCriticalErrors(true);
            })
            dispatch(updateDocusignStatus(statusDocusign))
          } else {
            console.log('Application is in application stage:', opportunities[0])
            dispatch(updateOpportunity(opportunities[0]));
          }
          dispatch(
            updateSupportingDocument(supportingDocument.supportingDocument)
          );
          dispatch(hasOneOppyCompleted(opportunities));
          dispatch(hasOneOppyRegisteredFinished(opportunities))
          dispatch(hasOneOppyToBeRegistered(opportunities))
          dispatch(isRegistered(opportunities))
          if (
            (opportunities[0].ProgramYear__c === "4" &&
            opportunities[0].Division__c === "ISG BS France") ||
            opportunities[0].Division__c === "ISG MSC et MBA"
          ) {
            console.log('User is applying for a master program')
            dispatch(updatePaymentType("Candidature"));
          } else {
            console.log('User is applying for a bachelor program')
            dispatch(updatePaymentType("JPC"));
          }
        } else {
          if (account.StageStop__c > '1') {
            console.log('User has stopped application')
            const accountId = account.Id
            const newStep = '0'
            await dispatch(SalesforceUpdateStageStop({ accountId, newStep }))
          }
        }
        console.log('User is logged in')
        dispatch(isLogged());
        history.push("/");
      }
    } else {
      actions.setStatus({ wrongPassword: true });
    }
  };

  const goToRegistration = () => {
    history.push("/inscription");
  };

  return (
    <>
      <Button
        className={classes.button_login}
        color="primary"
        onClick={goToRegistration}
        sx={{ mb: 2 }}
        variant="contained"
      >
        {t('create_account')}
      </Button>
      <Divider />
      <Typography className={classes.alreayRegistered}>
        {t('already_registered')}
      </Typography>
      <Formik
        initialValues={formInitialValues}
        validationSchema={loginValidationSchema}
        onSubmit={_handleSubmit}
      >
        {({ isSubmitting, status }) => (
          <Form id={formId}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <InputField
                  fullWidth
                  label={t(email.name)}
                  name={email.name}
                  type="email"
                />
              </Grid>
              <Grid item xs={12}>
                <InputField
                  fullWidth
                  label={t(password.name)}
                  name={password.name}
                  type="password"
                />
              </Grid>
            </Grid>
            <div className={classes.buttons_login}>
              <div className={classes.wrapper}>
                <Button
                  color="primary"
                  className={classes.button_login}
                  disabled={isSubmitting || (status && status.userCreated)}
                  type="submit"
                  variant="contained"
                  aria-label="login-btn"
                >
                  {t('login')}
                </Button>
                {isSubmitting && (
                  <CircularProgress
                    className={classes.buttonProgress}
                    size={24}
                  />
                )}
              </div>
            </div>
            <Button
              className={classes.buttonForgotPassword}
              component={Link}
              to="/mot-passe-oublie"
            >
              {t('forgot_password')}
            </Button>
            {status && status.wrongPassword && (
              <Box mt={2}>
                <Alert severity="error" variant="outlined">
                  {t('ionisConnect.wrong_email')}.
                </Alert>
              </Box>
            )}
            {criticalErrors && (
              <Alert severity="error" variant="outlined">
                <AlertTitle>{t('error_form')}</AlertTitle>
                {t('error_form2')}
              </Alert>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Login;
