import { Box } from "@mui/material"
import { Registration } from "applicant-components/components/IonisConnect"
import RegistrationParcoursup from "applicant-components/components/IonisConnect/Forms/RegistrationParcoursup"

const { Typography } = require("@mui/material")

const InscriptionEnLigne = () => {
  return (
    <>
      <Box
        alt="ISG"
        component="img"
        src="/logo-isg.jpg"
        sx={{ display: 'block', marginTop: 2, marginBottom: 2, mx: 'auto', height: 112, width: 112 }}
      >
      </Box>
      <Typography gutterBottom variant="h5">
        Bienvenue aux Admis(es) Parcoursup !
      </Typography>
      <Typography gutterBottom variant="h6">
        Vous allez maintenant procéder à votre inscription en ligne.
      </Typography>
      <Typography paragraph>
        <strong>Conditions d'entrée dans le portail d'inscription :</strong>
      </Typography>
      <ul>
        <li>Vous devez utiliser l'email que vous avez utilisé lors de votre inscription sur le portail Parcoursup.</li>
        <li>Pour que votre inscription soit valide, vous devez avoir accepté la proposition d'admission communiquée par l'école sur le portail Parcousup.</li>
      </ul>
      <Typography paragraph>
        <strong>L'inscription en ligne se déroule en 3 étapes :</strong>
      </Typography>
      <ul>
        <li>Etape 1 : vous remplissez le formulaire d'inscription en ligne.</li>
        <li>Etape 2 : vous choisissez les modalités de règlement de votre scolarité, votre moyen de paiement et vous réglez éventuellement le premier versement de frais à l'inscription.</li>
        <li>Etape 3 : vous recevez par email un document reprenant votre dossier d'inscription à signer par voie électronique.</li>
      </ul>
      <Typography paragraph>
        <strong>A l'issue de ces 3 étapes, votre inscription est vérifiée par nos équipes et confirmée par email dès le dossier validé !</strong>
      </Typography>
      <Typography paragraph>
        S'il vous manque des informations, il vous sera possible d'interrompre la saisie des formulaires puis de revenir à la dernière étape en cours, par la suite.
      </Typography>
      <Typography paragraph>
        Des notices explicatives sont disponibles au sein du portail afin de vous accompagner dans ces démarches.
      </Typography>
      <Typography paragraph>
        Si celles-ci se révèlent insuffisantes, vous pouvez joindre le service admissions en cliquant <a href="https://www.isg.fr/contacts-ecole" target="_blank">ici</a>
      </Typography>
      <Typography paragraph>
        <strong>Prêt.e.s ? Pour démarrer, saisissez votre adresse email Parcoursup ci-dessous !</strong>
      </Typography>
      <RegistrationParcoursup />
    </>
  )
}

export default InscriptionEnLigne
