import { Typography } from "@mui/material"
import FormModifyPassword from "applicant-components/components/IonisConnect/Forms/ModifyPassword"
import queryString from "query-string"
import { useDispatch } from "react-redux"
import { useEffect } from "react"
import { updateIonisConnect } from "applicant-components/Store/salesforceSlice"
import { useTranslation } from "react-i18next"

const ModifyPassword = () => {

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const token = queryString.parse(window.location.search).token
  // const isLogged = useSelector(state => state.salesforce.isLogged)
  useEffect(() => {
    dispatch(updateIonisConnect(true))
  })

  return (
    <>
      <Typography gutterBottom variant="h5">{t('page_modify_password.title')}</Typography>
      <Typography gutterBottom variant="h6">{t('page_modify_password.text')}</Typography>
      <FormModifyPassword token={token} />
    </>
  )
}

export default ModifyPassword
