import { at } from "lodash"
import { FieldArray, useField } from "formik"
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid } from "@mui/material"

const CheckboxListField = ({options, ...props}) => {
  const { grid, label, ...rest} = props
  const [field, meta] = useField(props)
  const { value: formikValue } = field

  const renderOptions = (options, arrayHelpers) => {
    return options.map((option) => {
      let checked = field.value.includes(option)
      return (
        <Grid item key={option} sm={grid}>
          <FormControlLabel
            aria-label={field.name}
            checked={checked}
            control={<Checkbox />}
            label={option}
            name={field.name}
            onChange={e => {
              const values = formikValue || []
              if (e.target.checked) {
                arrayHelpers.push(option)
              } else {
                const idx = values.indexOf(option)
                arrayHelpers.remove(idx)
              }
            }}
            value={option}
          >
          </FormControlLabel>
        </Grid>
      )
    })
  };

  const [touched, error] = at(meta, 'touched', 'error')

  return (
    <FormControl error={error && touched && true} {...rest}>
      <FormLabel>{label}</FormLabel>
      <FormGroup row={true}>
        <FieldArray
          {...field}
          render={arrayHelpers => (renderOptions(options, arrayHelpers))}
        >
          {renderOptions(options)}
        </FieldArray>
      </FormGroup>
      <FormHelperText>{touched && error ? error : ''}</FormHelperText>
    </FormControl>
  )
}

export default CheckboxListField
