import { Grid, Typography } from "@mui/material"
import { useFormikContext } from "formik"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { SelectField } from "../FormFields"

const Languages = (props) => {

  const {
    formField: {
      account: {
        Langue_1__c,
        Langue_2__c,
        Langue_3__c,
        Niveau_langue_1__c,
        Niveau_langue_2__c,
        Niveau_Langue_3__c
      }
    }
  } = props

  const {
    values: {
      account: {
        Langue_1__c : Langue1,
        Langue_2__c: Langue2,
        Langue_3__c: Langue3,
        Native_Language__pc,
        Niveau_langue_1__c: NiveauLangue1,
        Niveau_langue_2__c: NiveauLangue2
      }
    }
  } = useFormikContext()

  const formikProps = useFormikContext()
  const { t } = useTranslation()

  const [optionsLanguagesOther, setOptionsLanguagesOther] = useState([])
  const [optionsLanguageLevelsOther, setOptionsLanguageLevelsOther] = useState([])
  const optionsLanguages = useSelector(state => state.salesforce.languages)
  const optionsLanguageLevels = useSelector(state => state.salesforce.languageLevels)

  useEffect(() => {
    const languages = [...optionsLanguages]
    languages.unshift({ label: `--- ${t('languages.language')} ---`, value: undefined })
    setOptionsLanguagesOther(languages)
  }, [optionsLanguages])

  useEffect(() => {
    const languageLevels = [...optionsLanguageLevels]
    languageLevels.unshift({ label: `--- ${t('languages.level')} ---`, value: undefined })
    setOptionsLanguageLevelsOther(languageLevels)
  }, [optionsLanguageLevels])

  useEffect(() => {
    if (Langue2 === undefined) {
      formikProps.setFieldValue('account.Langue_2__c', '', false)
      formikProps.setFieldValue('account.Niveau_langue_2__c', '', false)
      formikProps.setFieldValue('account.Langue_3__c', '', false)
      formikProps.setFieldValue('account.Niveau_Langue_3__c', '', false)
    }
    if (Langue3 === undefined) {
      formikProps.setFieldValue('account.Langue_3__c', '', false)
      formikProps.setFieldValue('account.Niveau_Langue_3__c', '', false)
    }
  }, [Langue1, Langue2, Langue3]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Typography gutterBottom variant="h6" sx={{ mt: 2}}>{t('languages.title')}</Typography>
      <Grid container spacing={3}>
        <Grid item sm={4} xs={12}>
          <Grid container spacing={3}>
            <Grid item sm={12} xs={6}>
              <SelectField data={optionsLanguages} disableValue={[Native_Language__pc]} fullWidth label={t(Langue_1__c.name)} name={Langue_1__c.name} required />
            </Grid>
            <Grid item sm={12} xs={6}>
              <SelectField data={optionsLanguageLevels} fullWidth label={t(Niveau_langue_1__c.name)} name={Niveau_langue_1__c.name} required />
            </Grid>
          </Grid>
        </Grid>
        {Langue1 && NiveauLangue1 && <Grid item sm={4} xs={12}>
          <Grid container spacing={3}>
            <Grid item sm={12} xs={6}>
              <SelectField data={optionsLanguagesOther} disableValue={[Native_Language__pc, Langue1]} displayEmpty={true} fullWidth label={t(Langue_2__c.name)} name={Langue_2__c.name} />
            </Grid>
            <Grid item sm={12} xs={6}>
              <SelectField data={optionsLanguageLevelsOther} displayEmpty={true} fullWidth label={t(Niveau_langue_2__c.name)} name={Niveau_langue_2__c.name} />
            </Grid>
          </Grid>
        </Grid>}
        {Langue2 && NiveauLangue2 && <Grid item sm={4} xs={12}>
          <Grid container spacing={3}>
            <Grid item sm={12} xs={6}>
              <SelectField data={optionsLanguagesOther} disableValue={[Native_Language__pc, Langue1, Langue2, Langue3]} displayEmpty={true} fullWidth label={t(Langue_3__c.name)} name={Langue_3__c.name} />
            </Grid>
            <Grid item sm={12} xs={6}>
              <SelectField data={optionsLanguageLevelsOther} displayEmpty={true} fullWidth label={t(Niveau_Langue_3__c.name)} name={Niveau_Langue_3__c.name} />
            </Grid>
          </Grid>
        </Grid>}
      </Grid>
    </>
  )
}

export default Languages
