import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  MobileStepper,
  Snackbar,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { Alert, AlertTitle } from "@mui/material";
import { Formik, Form } from "formik";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Salesforce from "applicant-components/services/Salesforce";
// import formInitialValues from "../Portal/FormModel/formInitialValues"
import validationSchema from "./FormModel/validationSchema";
import portalFormModel from "./FormModel/portalFormModel";
import Application from "./Forms/Application";
import PersonnalInformations from "./Forms/PersonnalInformations";
import { ButtonBack, ButtonNext } from "applicant-components/components/Buttons";
import {
  isLogged,
  removeOpportunities,
  SalesforceUpdateStageStop,
  updateAcademicInformation,
  updateAccount,
  updateCampaignMember,
  updateOpportunities,
  updateOpportunity,
  updateSupportingDocument,
  updateWorkExperience,
} from "applicant-components/Store/salesforceSlice";
import useStyles from "./styles";
import Payment from "./Forms/Payment";
import Course from "./Forms/Course";
import {
  STAGE_COMPLETE,
  STAGE_QUALIFICATION,
} from "applicant-components/services/StageNameOpportunity";
import Validation from "./Forms/Validation";
import { ConfirmationApplication } from "applicant-components/components/Popin";
import { persistStore } from "redux-persist";
import Store from "applicant-components/Store/Store";
import SupportingDocument from "./Forms/SupportingDocument";
import { useHistory } from "react-router";
import { sendError, sendMessage, setUser } from "applicant-components/services/Errors";
import { useTranslation } from "react-i18next"
import MscGrantStudent from "components/Popin/MscGrantStudent"
import JPC from "./Forms/JPC"
import ConfirmationAttachments from "applicant-components/components/Popin/ConfirmationAttachments"

const { formId, formField } = portalFormModel;

function _renderStepContent(step) {
  const paymentType = useSelector((state) => state.salesforce.paymentType)
  switch (step) {
    case 0:
      return <PersonnalInformations formField={formField} />;
    case 1:
      return <Application formField={formField} />;
    case 2:
      return <Course formField={formField} />;
    case 3:
      if (paymentType === 'JPC') {
        return <JPC formField={formField} />;
      } else {
        return <Payment formField={formField} />
      }
    case 4:
      if (paymentType === 'JPC') {
        return <Payment formField={formField} />;
      } else {
        return <SupportingDocument formField={formField} />
      }
    case 5:
      if (paymentType === 'JPC') {
        return <SupportingDocument formField={formField} />;
      } else {
        return <Validation formField={formField} />
      }
    case 6:
      return <Validation formField={formField} />;
    default:
      return <div>Not found</div>;
  }
}

const Portal = () => {
  const paymentType = useSelector((state) => state.salesforce.paymentType);
  // const steps = [
  //   "Mes informations personnelles",
  //   "Ma candidature",
  //   paymentType === "Candidature"
  //     ? "Paiement des frais de candidature"
  //     : "Paiement de l'atelier de préparation au concours",
  //   "Mon parcours",
  //   "Justificatifs",
  //   "Validation",
  // ];
  const { t } = useTranslation()
  // const steps = t('breadcrumb')
  // if (paymentType === 'Candidature') {
  //   steps[5] = t('breadcrumb_app')
  // } else {
  //   steps[5] = t('breadcrumb_jpc')
  // }

  const getCurrentValidationSchema = () => {
    if (paymentType === 'JPC' && activeStep === 5) {
      return validationSchema[4]
    } else if (paymentType === 'JPC' && activeStep === 4) {
      return validationSchema[3]
    } else {
      return validationSchema[activeStep]
    }
  }

  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [criticalErrors, setCriticalErrors] = useState(false);
  const [SpecialisedEducation, setSpecialisedEducation] = useState([]);
  const [opportunityValidationFailed, setOpportunityValidationFailed] =
    useState(false);
  const [isSubmittingBack, setIsSubmittingBack] = useState(false);
  const [steps, setSteps] = useState(t('breadcrumb'))
  const currentValidationSchema = getCurrentValidationSchema();
  const [opportunity, setOpportunity] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [openPopupMsc, setOpenPopupMsc] = useState(false);
  const [openPopupAttachments, setOpenPopupAttachments] = useState(false)
  const isLastStep = activeStep === steps.length - 1;
  const step = useSelector((state) => state.salesforce.account.StageStop__c);
  const accountId = useSelector((state) => state.salesforce.account.Id);
  const PersonEmail = useSelector(
    (state) => state.salesforce.account.PersonEmail
  );
  const buttonsDisabled = useSelector((state) => state.buttons.enabled);
  const buttonsVisible = useSelector((state) => state.buttons.visible);
  const SupportingDocument__c = useSelector(
    (state) => state.salesforce.supportingDocument
  );
  const account = useSelector((state) => state.salesforce.account);
  const opportunities = useSelector((state) => state.salesforce.opportunities);
  const uniqueOpportunity = useSelector(
    (state) => state.salesforce.opportunity
  );
  const AcademicInformation__c = useSelector(
    (state) => state.salesforce.academicInformation
  );
  const WorkExperience__c = useSelector(
    (state) => state.salesforce.workExperience
  );
  const returnPageConfirmation = useSelector(
    (state) => state.salesforce.returnPages.confirmation
  );
  const recordTypeId = useSelector(
    (state) => state.salesforce.recordTypeOpportunity
  );
  const campaignId = useSelector((state) => state.salesforce.campaignId);
  const campaignMember = useSelector(
    (state) => state.salesforce.campaignMember
  );
  let persistor = persistStore(Store);
  const formRef = useRef();

  // @todo a reactiver si on souhaite passer à une étape en particulier
  // const handleStep = (newStep) => () => {
  //   if (activeStep > newStep) {
  //     // setActiveStep(step)
  //     dispatch(SalesforceUpdateStageStop({ payload, accountId, newStep }))
  //   } else {
  //     const stepDiff = 0
  //     // @todo il faudrait au final un json pour stocker le step max qu'il peut atteindre. Sinon il pourrait sauter des étapes
  //     if (stepDiff === 1 && formRef.current) {
  //       formRef.current.submitForm()
  //     } else {
  //       dispatch(SalesforceUpdateStageStop({ payload, accountId, newStep }))
  //     }
  //   }
  // }

  useEffect(() => {
    const opportunity = {
      AccountId: accountId,
      CloseDate: Date.now(),
      OnlineApplication__c: true,
      // OwnerId: '',
      RecordTypeId: recordTypeId,
      Session__c: "",
      StageName: STAGE_QUALIFICATION,
    };
    if (
      uniqueOpportunity &&
      Object.keys(uniqueOpportunity).length === 0 &&
      Object.getPrototypeOf(uniqueOpportunity) === Object.prototype
    ) {
      setOpportunity(opportunity);
    } else {
      setOpportunity(uniqueOpportunity);
    }
  }, [accountId, uniqueOpportunity, recordTypeId]);

  useEffect(() => {
    setUser(accountId, PersonEmail);
  }, [accountId, PersonEmail]);

  useEffect(() => {
    const currentStep = parseInt(step);
    setActiveStep(currentStep);
  }, [step]);

  // Construction du fil d'ariane
  useEffect(() => {
    if (paymentType === 'Candidature' || (paymentType === 'JPC' && campaignMember.id === undefined)) {
      if (paymentType === 'Candidature') {
        setSteps([...t('breadcrumb'), t('breadcrumb_app'), t('breadcrumb_justificatif'), t('breadcrumb_validation')])
      } else {
        setSteps([...t('breadcrumb'), t('breadcrumb_choice_jpc'), t('breadcrumb_jpc'), t('breadcrumb_justificatif'), t('breadcrumb_validation')])
      }
    } else if (paymentType === 'JPC' && campaignMember.id !== undefined) {
      console.log('oui ok')
      // const stepsCopy = [...]
      setSteps([...t('breadcrumb'), t('breadcrumb_choice_jpc'), t('breadcrumb_jpc'), t('breadcrumb_justificatif'), t('breadcrumb_validation') ])
    } else {
      // if (steps.length >= 5) {
      //   const stepsCopy = [...steps]
      //   stepsCopy.push(t('breadcrumb_justificatif'))
      //   stepsCopy.push(t('breadcrumb_validation'))
      //   setSteps(stepsCopy)
      // }
    }
  }, [campaignMember, paymentType])

  useEffect(() => {
    async function getSpecialisedEducation() {
      setSpecialisedEducation(
        await Salesforce.getSpecialisedEducation(account)
      );
    }
    getSpecialisedEducation();
  }, [account]);

  async function _submitForm(values, actions) {
    actions.setSubmitting(false);
    // setActiveStep(activeStep + 1)
    if (uniqueOpportunity.Tous_documents_valides__c) {
      setOpenPopup(true);
    } else {
      setOpenPopupAttachments(true)
    }
  }

  async function _handleSubmit(values, actions) {
    // Pour ISG Msc / MBA, on doit check si il a coché la case boursier
    if (values.program === 'ISG MSC et MBA' && values.account.Grant_Student__c) {
      setOpenPopupMsc(true)
      return false
    }
    setCriticalErrors(false);
    const account = values.account;
    const opportunity = values.opportunity;
    const JPCWished = values.JPCWished;
    const academicInformation = values.AcademicInformation__c;
    const workExperience = values.WorkExperience__c;
    sendMessage('debug update', {
      account,
      opportunity,
      JPCWished
    })
    const accountUpdated = await Salesforce.updateAccount(
      account,
      activeStep,
      JPCWished,
      paymentType,
      isLastStep
    ).catch((e) => {
      sendError(e);
      setCriticalErrors(true);
      actions.setSubmitting(false);
    });
    let opportunityUpdated = undefined;
    if (opportunity.Session__c !== undefined && opportunity.Session__c !== "") {
      opportunityUpdated = await Salesforce.updateOpportunity(
        opportunity,
        JPCWished,
        paymentType
      ).catch((e) => {
        sendError(e);
        setCriticalErrors(true);
        actions.setSubmitting(false);
      });
      dispatch(updateOpportunity(opportunityUpdated.opportunity));
      dispatch(updateOpportunities(opportunityUpdated.opportunities));
    }
    if (values.application.trackMajor !== '') {
      let specialities = []
      specialities.push({
        Opportunity__c: opportunityUpdated.opportunity.Id,
        Speciality__c: values.application.trackMajor,
        Principale__c: true
      })
      for (let i = 0; i < values.application.trackMinorId.length; i++) {
        specialities.push({
          Opportunity__c: opportunityUpdated.opportunity.Id,
          Speciality__c: values.application.trackMinorId[i],
        })
      }

      console.log('ON met à jour les spécialités')
      console.log(specialities)
      await Salesforce.createSpecialities(specialities).then(() => {

      }).catch((e) => {
        setCriticalErrors(true);
        actions.setSubmitting(false);
      })
    }
    const academicInformationUpdated =
      await Salesforce.updateAcademicInformation(
        academicInformation,
        accountId
      ).catch((e) => {
        setCriticalErrors(true);
        actions.setSubmitting(false);
      });
    const workExperienceUpdated = await Salesforce.updateWorkExperience(
      workExperience,
      accountId
    ).catch((e) => {
      setCriticalErrors(true);
      actions.setSubmitting(false);
    });
    // MAJ du store
    if (academicInformationUpdated.success) {
      dispatch(
        updateAcademicInformation(
          academicInformationUpdated.data
        )
      );
    }
    if (workExperienceUpdated.success) {
      dispatch(updateWorkExperience(workExperienceUpdated.data));
    }
    // if (supportingDocumentUpdated.success) {
    //   console.log(supportingDocumentUpdated.supportingDocument);
    //   dispatch(updateSupportingDocument(supportingDocumentUpdated.supportingDocument))
    // }
    if (JPCWished) {
      console.log("campaignMemberCreated");
      sendMessage("debug création campagne member", {
        campaignId: campaignId,
        personContactId: account.PersonContactId
      })
      const campaignMemberCreated = await Salesforce.createCampaignMember(
        account.PersonContactId,
        campaignId
      ).catch((e) => {
        setCriticalErrors(true);
        actions.setSubmitting(false);
      });
      console.log(campaignMemberCreated);
      if (campaignMemberCreated.success) {
        dispatch(updateCampaignMember(campaignMemberCreated));
      } else {
        sendMessage("erreur création membre de campagne", {
          erreurCampaignMember: campaignMemberCreated
        })
      }
    }
    if (accountUpdated !== undefined && accountUpdated.success !== undefined) {
      dispatch(updateAccount(accountUpdated.account));
      if (isLastStep) {
        _submitForm(values, actions);
      } else {
        setActiveStep(activeStep + 1);
        actions.setTouched({});
        actions.setSubmitting(false);
      }
    }
  }

  async function _handleBack() {
    setCriticalErrors(false);
    setOpportunityValidationFailed(false);
    setIsSubmittingBack(true);
    let stepGap = 1;
    if (
      activeStep === 5 &&
      Object.keys(campaignMember).length === 0 &&
      paymentType === "JPC"
    ) {
      stepGap = 2;
    }
    const newStep = activeStep - stepGap;
    await dispatch(SalesforceUpdateStageStop({ accountId, newStep }));
    setActiveStep(parseInt(newStep));
    setIsSubmittingBack(false);
  }

  async function closeOpportunity(close) {
    // alert('oppy close')
    if (close) {
      const updateStageName = await Salesforce.updateStageName(
        opportunity.Id,
        STAGE_COMPLETE
      ).catch((e) => {
        setOpenPopup(false);
        const error = e.response.data.error[0].errorCode
        if (error.indexOf("FIELD_CUSTOM_VALIDATION_EXCEPTION") > -1) {
          setOpportunityValidationFailed(true);
        } else {
          setCriticalErrors(true);
        }
      });

      if (updateStageName) {
        dispatch(updateAcademicInformation([]));
        dispatch(updateAccount({}));
        dispatch(updateCampaignMember({}));
        dispatch(updateOpportunity({}));
        dispatch(removeOpportunities());
        dispatch(updateSupportingDocument([]));
        dispatch(updateWorkExperience([]));
        persistor.purge().then(() => {
          // document.location.href = '/'
          dispatch(isLogged());
          history.push(returnPageConfirmation);
        });
      }
    } else {
      setOpenPopup(false);
    }
  }

  const closePopupMsc = (close) => setOpenPopupMsc(close)

  const closePopupAttachments = (close) => {
    if (close) {
      dispatch(updateAcademicInformation([]));
      dispatch(updateAccount({}));
      dispatch(updateCampaignMember({}));
      dispatch(updateOpportunity({}));
      dispatch(removeOpportunities());
      dispatch(updateSupportingDocument([]));
      dispatch(updateWorkExperience([]));
      persistor.purge().then(() => {
        dispatch(isLogged());
        history.push(returnPageConfirmation);
      });
    } else {
      setOpenPopupAttachments(false)
    }
  }

  // const SpecialisedEducation = Salesforce.getSpecialisedEducation(account)
  const application = {
    campusDisabled: true,
    optionsCampus: [],
    // optionsPrograms: [],
    optionsSessions: [],
    optionsYears: [],
    sessionsDisabled: true,
    trackMajor: '',
    trackMinor: [],
    trackMinorId: [],
    trackMajorDisabled: true,
    trackMinorDisabled: true,
    yearDisabled: true,
  };
  // const opportunity = {
  //   AccountId: account.Id,
  //   CloseDate: Date.now(),
  //   OnlineApplication__c: true,
  //   RecordTypeId: '0129E000000qTq0QAE', // @todo ne pas mettre en dur
  //   Session__c: '',
  //   StageName: STAGE_QUALIFICATION
  // })

  return (
    <>
      {!isNaN(activeStep) && (
        <Box sx={{ display: { xs: "none", sm: "block" } }}>
          <Stepper
            activeStep={activeStep}
            className={classes.stepper}
            nonLinear
            orientation="horizontal"
          >
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
                {/* <StepButton color="inherit" onClick={handleStep(index)}>{label}</StepButton> */}
              </Step>
            ))}
          </Stepper>
        </Box>
      )}
      <>
        {activeStep === steps.length ? (
          <div></div>
        ) : (
          <Formik
            enableReinitialize
            initialValues={{
              AcademicInformation__c,
              account,
              application,
              opportunities,
              opportunity,
              SpecialisedEducation,
              SupportingDocument__c,
              WorkExperience__c,
              JPCWished: undefined
            }}
            innerRef={formRef}
            onSubmit={_handleSubmit}
            validationSchema={currentValidationSchema}
          >
            {({ errors, isSubmitting, submitCount }) => (
              <Form id={formId} noValidate>
                {_renderStepContent(activeStep)}
                {Object.keys(errors).length !== 0 && submitCount > 0 && (
                  <Snackbar
                    anchorOrigin={{ horizontal: "center", vertical: "top" }}
                    open={true}
                  >
                    <Alert elevation={3} severity="error">
                      {t('fields.alert')}.
                    </Alert>
                  </Snackbar>
                )}
                {criticalErrors && (
                  <Box mt={2}>
                    <Alert severity="error" variant="outlined">
                      <AlertTitle>{t('error_form')}</AlertTitle>
                      {t('error_form2')}.
                    </Alert>
                  </Box>
                )}
                {opportunityValidationFailed && (
                  <Box mt={2}>
                    <Alert severity="error" variant="outlined">
                      <AlertTitle>
                        {t('application_error')}
                      </AlertTitle>
                      <Typography gutterBottom variant="body2">
                        {t('application_error_reason')}.
                      </Typography>
                      <Button
                        onClick={_handleBack}
                        sx={{ mt: 2 }}
                        variant="outlined"
                      >
                        {t('application_error_add')}
                      </Button>
                    </Alert>
                  </Box>
                )}
                <Box sx={{ display: { xs: "none", sm: "block" } }}>
                  {buttonsVisible && (
                    <div className={classes.buttons}>
                      {activeStep !== 0 && (
                        <div className={classes.wrapper}>
                          <Button
                            className={classes.button}
                            disabled={isSubmittingBack || isSubmitting}
                            onClick={_handleBack}
                          >
                            {t('previous')}
                          </Button>
                          {isSubmittingBack && (
                            <CircularProgress
                              className={classes.buttonProgress}
                              size={24}
                            />
                          )}
                        </div>
                      )}
                      <div className={classes.wrapper}>
                        <Button
                          color="primary"
                          className={classes.button}
                          disabled={
                            isSubmitting || isSubmittingBack || buttonsDisabled
                          }
                          type="submit"
                          variant="contained"
                        >
                          {isLastStep ? opportunity.Tous_documents_valides__c ? t('confirm_application') : t('confirm_application2') : t('next')}
                        </Button>
                        {isSubmitting && (
                          <CircularProgress
                            className={classes.buttonProgress}
                            size={24}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </Box>
                <Box sx={{ display: { xs: "block", sm: "none" } }}>
                  <Backdrop
                    sx={{
                      color: "#fff",
                      zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={isSubmitting || isSubmittingBack}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>
                </Box>
                {!isNaN(activeStep) && (
                  <MobileStepper
                    activeStep={activeStep}
                    backButton={
                      <ButtonBack
                        activeStep={activeStep}
                        handleBack={_handleBack}
                        isSubmitting={isSubmitting || !buttonsVisible}
                        isSubmittingBack={isSubmittingBack}
                      />
                    }
                    nextButton={
                      <ButtonNext
                        activeStep={activeStep}
                        handleNext={_handleSubmit}
                        isLastStep={isLastStep}
                        isSubmitting={isSubmitting || !buttonsVisible}
                        maxSteps={steps.length}
                      />
                    }
                    position="bottom"
                    steps={steps.length}
                    sx={{ display: { sm: "none" } }}
                    variant="dots"
                  ></MobileStepper>
                )}
                <MscGrantStudent
                  closePopupMsc={closePopupMsc}
                  Grant_Student__c={formField.account.Grant_Student__c}
                  openPopup={openPopupMsc}
                />
              </Form>
            )}
          </Formik>
        )}
        <ConfirmationApplication
          openPopup={openPopup}
          closeOpportunity={closeOpportunity}
          hasErrors={opportunityValidationFailed}
        />
        <ConfirmationAttachments
          closePopupAttachments={closePopupAttachments}
          openPopup={openPopupAttachments}
        />
      </>
    </>
  );
};

export default Portal;
