import { Alert, Box, Button, Link, Typography } from "@mui/material"
import { useHistory } from "react-router"

const ConfirmationInscription = () => {

  const history = useHistory()

  const goToForm = () => {
    history.push('/')
  }

  return (
    <>
      <Box
        alt="ISG"
        // className={classes.title}
        component="img"
        src="/logo-isg.jpg"
        sx={{ display: 'block', marginTop: 2, marginBottom: 2, mx: 'auto', height: 112, width: 112 }}
      >
      </Box>

      <Alert elevation={3} severity="success" sx={{ mb: 2 }}>Votre inscription a bien été enregistrée.</Alert>

      <Typography paragraph variant="subtitle1">Pour qu’elle soit définitivement validée :</Typography>

      <Typography variant="h5">Étape 1 : 1er règlement de frais à l’inscription</Typography>

      <ul>
        <li>Dans le cas où vous avez choisi l’option <strong>« Valider et payer plus tard »</strong>, vous devez <Link component="button" onClick={goToForm}>vous reconnecter</Link> ultérieurement sur votre dossier pour procéder au paiement.</li>
        <li>Dans le cas où vous avez choisi l’option <strong>« Paiement par prélevement »</strong>, ce règlement sera automatiquement ajouté à votre dossier après réception de celui-ci.</li>
      </ul>

      <Typography paragraph variant="body2"><strong>Votre dossier ne sera pas complété tant que le paiement n’est pas effectué.</strong></Typography>

      <Typography mb={2} variant="h5">Étape 2 : signature électronique</Typography>

      <Typography paragraph variant="body2">Vous allez recevoir un document électronique reprenant votre inscription par email dans quelques instants. Il vous sera proposé de le signer électroniquement. Merci de le signer et le cas échéant, de le faire signer par votre responsable financier.<br /><br />

      <strong>Votre inscription ne sera définitive et confirmée qu’après ces deux étapes résolues.</strong></Typography>

      <Button onClick={goToForm} sx={{ mt: 2 }} variant="contained">Revenir à l'accueil</Button>
    </>
  )
}

export default ConfirmationInscription
