import IconMoreVert from "@mui/icons-material/MoreVert"
import { Button, Drawer, IconButton, Tooltip, Typography } from "@mui/material"
import { useState } from "react"
import { Link } from "react-router-dom"
import { Box } from "@mui/system"
import { useTranslation } from "react-i18next"
// import { useSelector } from "react-redux"
// import { useDispatch } from "react-redux"
// import { modifyTheme } from "../../Store/themeSlice"

const Parameters = () => {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const { t } = useTranslation()
  // const dispatch = useDispatch()
  // const [defaultTheme, setDefaultTheme] = useState('')
  // const theme = useSelector(state => state.theme)

  // useEffect(() => {
  //   setDefaultTheme(theme.theme)
  // }, []) // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <>
      <Tooltip title="Voir les paramètres">
        <IconButton onClick={handleOpen}>
          <IconMoreVert />
        </IconButton>
      </Tooltip>
      <Box
        onKeyDown={handleClose}
      >
        <Drawer
          anchor="right"
          open={open}
          onClose={handleClose}
        >
          <Box sx={{ padding: 3 }}>
            <Typography variant="h5">{t('parameters.parameters')}</Typography>
            <Button component={Link} onClick={handleClose} to="/modification-mot-passe" sx={{ margin: '30px 0' }} variant="contained">{t('parameters.modify_password')}</Button>
            {/* <Box>
              <FormControl component="fieldset">
                <FormLabel component="legend">Thème</FormLabel>
                <RadioGroup
                  aria-label="gender"
                  defaultValue={defaultTheme}
                  name="radio-buttons-group"
                  onClick={e => {
                    dispatch(modifyTheme(e.target.value))
                    setDefaultTheme(e.target.value)
                  }}
                >
                  <FormControlLabel value="system" control={<Radio />} label="Thème du système" />
                  <FormControlLabel value="light" control={<Radio />} label="Thème clair" />
                  <FormControlLabel value="dark" control={<Radio />} label="Thème sombre" />
                </RadioGroup>
              </FormControl>
            </Box> */}
          </Box>
        </Drawer>
      </Box>
    </>
  )
}

export default Parameters
